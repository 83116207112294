import { Component, OnInit } from '@angular/core';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { NgForm } from '@angular/forms';
import { Enquiry } from 'src/app/models/enquiry.model';
declare var jQuery: any;
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
@Component({
    selector: 'app-header-style-one',
    templateUrl: './header-style-one.component.html',
    styleUrls: ['./header-style-one.component.scss']
})
export class HeaderStyleOneComponent implements OnInit {
    topic1On: boolean = true;
    topic2On: boolean = false;
    openCourseMenu = true;
    contactForm: FormGroup;
    enquiry: Enquiry = new Enquiry();

    courseCategoryTabs = [
        {
            id: 1,
            active: true,
            icon: '<i class="fa-solid fa-graduation-cap"></i>',
            couseTabsName: 'All Courses'
        },
        {
            id: 2,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Data Science and AI'
        },
        {
            id: 3,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Software Development'
        },
        {
            id: 4,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Web  Development'
        }

    ]

    allCourseArr = [
        {
            courseName: 'Mastering Advance Data Science & Generative AI',
            courseDuration: '9 Months',
            navigationUrl: '/advanced-data-science-and-generative-AI',
        },
        {
            courseName: 'Mastering DSA & System Design',
            courseDuration: '6 Months',
            navigationUrl: '/mastering-dsa-system-design',
        },
        {
            courseName: 'Mastering DSA & System Design with Full Stack Specialization',
            courseDuration: '9 Months',
            navigationUrl: '/mastering-dsa-system-design-with-fullstack',
        },

        {
            courseName: 'Complete Placement Package for College Students',
            courseDuration: '6 Months',
            courseProject: '15+ Industry Projects',
            navigationUrl: '/complete-placement-package-college-students',
        },
        {

            courseName: 'Full Stack Web Development - MERN',
            navigationUrl: '/fullStack-web-development-mern',
            courseDuration: '3 Months',

        },
        {
            courseName: 'Tech Interview Preparation - Mock Interviews',
            courseDuration: '3 Months',
            navigationUrl: '/tech-interview-preparation',
        }
    ]

    allCourse = [
        {
            courseName: 'Mastering Advance Data Science & Generative AI',
            courseDuration: '9 Months',
            navigationUrl: '/advanced-data-science-and-generative-AI',
        },
        {
            courseName: 'Mastering DSA & System Design',
            courseDuration: '6 Months',
            navigationUrl: '/mastering-dsa-system-design',
        },
        {
            courseName: 'Mastering DSA & System Design with Full Stack Specialization',
            courseDuration: '9 Months',
            navigationUrl: '/mastering-dsa-system-design-with-fullstack',
        },

        {
            courseName: 'Complete Placement Package for College Students',
            courseDuration: '6 Months',
            courseProject: '15+ Industry Projects',
            navigationUrl: '/complete-placement-package-college-students',
        },
        {

            courseName: 'Full Stack Web Development - MERN',
            navigationUrl: '/fullStack-web-development-mern',
            courseDuration: '3 Months',

        },
        {
            courseName: 'Tech Interview Preparation - Mock Interviews',
            courseDuration: '3 Months',
            navigationUrl: '/tech-interview-preparation',
        }

    ]
    softwareDevelopment = [
        {
            courseName: 'Mastering DSA & System Design',
            courseDuration: '6 Months',
            navigationUrl: '/mastering-dsa-system-design',
        },
        {
            courseName: 'Mastering DSA & System Design with Full Stack Specialization',
            courseDuration: '9 Months',
            navigationUrl: '/mastering-dsa-system-design-with-fullstack',
        },
        {
            courseName: 'Complete Placement Package for College Students',
            courseDuration: '6 Months',
            navigationUrl: '/complete-placement-package-college-students',
        }
    ]

    dataScienceArr = [
        {
            courseName: '  Mastering Advance Data Science & Generative AI',
            courseDuration: '9 Months',
            navigationUrl: '/advanced-data-science-and-generative-AI'
        }
    ];

    webDevArr = [
        {
            courseName: 'Full Stack Web Development - MERN',
            navigationUrl: '/fullStack-web-development-mern',
            courseDuration: '3 Months',
        }
    ];

    techInterviewPrepArr = [
        {
            courseName: 'Tech Interview Preparation - Mock Interviews',
            courseDuration: '3 Months',
            navigationUrl: '/tech-interview-preparation',
        }
    ];
    selectedCourseId: number = 1;
    utmSource: string; 
    timer: number = 0;
    isButtonDisable: boolean = false;
    constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
        private notifyService: NotificationService, private fb: FormBuilder) { }

    ngOnInit(): void {
        
        this.contactForm = this.fb.group({
            name: [''],
            email: ['', [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
            number: [''],
            linkleurl: ['']
        });
        // Accessing route parameters
        this.route.queryParams.subscribe(params => {
            this.utmSource = params['utmsource'];
        });
    }

    classApplied = false;
    topic1() {
        this.topic1On = true;
        this.topic2On = false;
    }
    topic2() {
        this.topic2On = true;
        this.topic1On = false;
    }
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }
    toggleCourseMenu() {
        this.openCourseMenu = !this.openCourseMenu;
    }
    selectCourseId(courseId) {
        this.selectedCourseId = parseInt(courseId);
    }
    requestCallBack() {
        if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
            this.notifyService.showError("Name is required");
            return;
        } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
            this.notifyService.showError("Email is required");
            return;
        } else if (this.contactForm.controls.email.status == 'INVALID') {
            this.notifyService.showError("Please provide valid email address");
            return;
        } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
            this.notifyService.showError("Number is required");
            return;
        }
        else if (this.selectedCourseId === undefined || this.selectedCourseId === null || isNaN(this.selectedCourseId)) {
            this.notifyService.showError("Please select course");
            return;
        }
        this.isButtonDisable = true;
        this.enquiry.EnquiryId = 0;
        this.enquiry.Name = this.contactForm.value.name;
        this.enquiry.Email = this.contactForm.value.email;
        this.enquiry.Mobile = this.contactForm.value.number;
        this.enquiry.CourseId = this.selectedCourseId;
        this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
        this.enquiry.StatusId = 1;
        this.enquiry.IsReferal = 0;
        this.enquiry.EnquirySourceId = 1;
        this.enquiry.ReferalUserId = 0;
        this.enquiry.Remarks = '';
        this.enquiry.IsActive = 1;
        this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Common' : this.utmSource;
        this.timer = 10;
        const timerInterval = setInterval(() => {
            if (this.timer > 0) {
                this.timer--;
            } else {
                this.isButtonDisable = false;
                clearInterval(timerInterval); // Stop the timer when it reaches 0
            }
        }, 1000);
        this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
            res => {
                jQuery('#ApplyNowModal').modal('hide');
            },
            err => {
                console.log(err);
                this.isButtonDisable = false;
                this.notifyService.showError("Something wrong! Please try after sometime.");
            }
        );
        this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
        //this.toClearFormValue(this.contactForm.value);
    }

    toClearFormValue(formValue) {
        formValue.name = '';
    }

    getCourseByCategory(id: number) {
        this.courseCategoryTabs.forEach((data) => {
            if (data.id == id) {
                data.active = true;
            } else {
                data.active = false;
            }
        })
        switch (id) {
            case 1:
                this.allCourseArr = this.allCourse;
                break;
            case 2:
                this.allCourseArr = this.dataScienceArr;
                break;
            case 3:
                this.allCourseArr = this.softwareDevelopment;
                break;
            case 4:
                this.allCourseArr = this.webDevArr;
                break;
            case 5:
                this.allCourseArr = this.techInterviewPrepArr;
                break;
        }

    }
}
