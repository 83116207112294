import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlumniCallPopupComponent } from '../components/common/alumni-call-popup/alumni-call-popup.component';
@Injectable({
  providedIn: 'root'
})
export class AlumniPopupService {
  constructor(private alumniPopupService:NgbModal) { }

  openModal() {
    const options: NgbModalOptions = {
      centered: true,
      size: 'lg', // Make the modal large
      backdrop: 'static', // Prevent closing on click outside
      keyboard: false // Prevent closing on escape key
    };
    this.alumniPopupService.open(AlumniCallPopupComponent, options);
  }
}