import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { Enquiry } from 'src/app/models/enquiry.model';
import { NotificationService } from 'src/app/services/notification.service';
declare var jQuery: any;
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Renderer2 } from '@angular/core';
@Component({
  selector: 'app-homefive-main-banner',
  templateUrl: './homefive-main-banner.component.html',
  styleUrls: ['./homefive-main-banner.component.scss'],
})
export class HomefiveMainBannerComponent implements OnInit {
  @ViewChild('demoPopup') demoPopup: ElementRef;
  courseArry: any[] = [];
  number = '';
  contactFormValidatorName: string;
  contactFormValidatorEmail: string;
  OTP: any = '';
  enquiry: Enquiry = new Enquiry();
  responseOtp: any = '';
  selectedCourseId: number;
  enableButton: boolean = false;
  recievedOTP: number;
  displayNotificationModel: boolean;
  studentCourseArray = [{ courseName: 'Mastering DSA & Computer Fundamentals', id: 1 },
  { courseName: 'Mastering Data Structure & Algorithms [Beginner]', id: 2 },
  { courseName: 'Mastering DSA & Computer Fundamentals with Internship', id: 3 }]
  professionalCourseArray = [{ courseName: 'Mastering DSA & System Design', id: 4 },
  { courseName: 'Mastering Advance DSA & System Design', id: 5 },
  { courseName: 'Mastering Advance System Design (LLD + HLD)', id: 6 }]
  contactFormData: string;
  otpValidationNumber: any;
  contactForm: FormGroup;
  notificationContactForm: FormGroup;
  demoClassForm: FormGroup;
  submitted: boolean = false;
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  hideNotificationCard: boolean = false;
  modal_width: string = 'lg';
  popupSubmitted = false;
  demoClassSubmitted = false;
  notificationDetails: { courseName: string; courseTime: string; day: string; };
  selectedCourseIdPopup: number;
  domainValidationMessage: string;
  selectedDemoClassCourseId: number;
  DemoClassValidationMessage: string;
  isButtonDisabled: boolean = true;
  constructor(private commonservice: CommonServiesService, private router: Router,
    private route: ActivatedRoute, private renderer: Renderer2,
    private notifyService: NotificationService, private fb: FormBuilder) {
    this.openNotificationModel();

  }

  ngOnInit(): void {
    this.courseArry = this.studentCourseArray;
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });

    this.notificationContactForm = this.fb.group({
      notifyname: ['', Validators.required],
      notifyemail: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")
      ]],
      notifynumber: ['', [
        Validators.required,
        Validators.pattern(".*")
      ]],
    });

    this.demoClassForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: ['', [
        Validators.required,
        Validators.pattern("^[0-9]{10}$")
      ]],
    });

    setTimeout(() => {
      jQuery('#diwali_wishes').modal('show');
      this.isButtonDisabled = false;
    }, 3000);

    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });

    this.notificationDetails = environment.notificationCardValue;

    const isVisitd = sessionStorage.getItem('isVisited');
    if (this.utmSource && isVisitd === null) {
      this.InsertUpdateVisitTracker();
    }

  }

  InsertUpdateVisitTracker() {
    this.commonservice.insertUpdateVisitTracker(this.utmSource).subscribe(
      (result: any) => {
        sessionStorage.setItem('isVisited', 'true');
      }, (error: any) => {
        console.log(error);
      }
    )
  }
  get registerFormControl() {
    return this.contactForm.controls;
  }

  get popupFormControl() {
    return this.notificationContactForm.controls;
  }
  get getdemoClassControl() {
    return this.demoClassForm.controls;
  }
  hideFlootingNotificatinfBanner() {
    this.hideNotificationCard = true;
  }

  selectUserType(type) {
    this.courseArry = [];
    if (type === "student") {
      this.courseArry = this.studentCourseArray;
    } else {
      this.courseArry = this.professionalCourseArray;
    }
  }
  enableDisableSendOTP() {
    return this.OTP === this.responseOtp;
  }
  selectCourseId(courseId) {
    this.selectedCourseId = parseInt(courseId);
  }
  selectCourseIdPopup(courseId) {
    this.selectedCourseIdPopup = parseInt(courseId);
    this.domainValidationMessage = '';
  }

  selectDemoClassCourseId(courseId) {
    this.selectedDemoClassCourseId = parseInt(courseId);
    this.DemoClassValidationMessage = '';
  }
  requestCallBack() {
    this.submitted = true;

    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    else if (this.selectedCourseId === undefined || this.selectedCourseId === null || isNaN(this.selectedCourseId)) {
      this.notifyService.showError("Please select course");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = this.selectedCourseId;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Home Page' : this.utmSource;
    this.timer = 10;
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.contactForm.reset();
    jQuery('#diwali_wishes').modal('hide');
    jQuery('#ApplyNowModal_notification').modal('hide');
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {

      },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );

  }
  resetForm() {
    this.contactForm.reset();
  }

  popupRequestCallBack() {
    this.popupSubmitted = true;
    if (this.selectedCourseIdPopup === undefined || this.selectedCourseIdPopup === null || this.selectedCourseIdPopup == 0) {
      this.domainValidationMessage = 'Domain is required';
      return;
    } else {
      this.domainValidationMessage = '';
    }
    if (this.notificationContactForm.valid) {
      this.enquiry.EnquiryId = 0;
      this.enquiry.Name = this.notificationContactForm.value.notifyname;
      this.enquiry.Email = this.notificationContactForm.value.notifyemail;
      this.enquiry.Mobile = this.notificationContactForm.value.notifynumber;
      this.enquiry.CourseId = this.selectedCourseIdPopup;
      this.enquiry.LinkedInProfile = '';
      this.enquiry.StatusId = 1;
      this.enquiry.IsReferal = 0;
      this.enquiry.EnquirySourceId = 1;
      this.enquiry.ReferalUserId = 0;
      this.enquiry.Remarks = '';
      this.enquiry.IsActive = 1;

      this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Home Page' : this.utmSource;
      this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
      this.closePopu();
      this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
        res => { },
        err => {
          console.log(err);
          this.notifyService.showError("Something wrong! Please try after sometime.");
        }
      );

    }
  }

  otpValidation(number) {
    if (parseInt(number.viewModel) === this.responseOtp) {
      this.enableButton = true;
    } else {
      this.enableButton = false;
    }

  }

  openNotificationModel() {
    setTimeout(() => {
      this.displayNotificationModel = true;
    }, 1000)
  }

  closeNotificationModal() {
    this.displayNotificationModel = false;
  }
  bookDemo() {
    jQuery('#demoPopup').modal('show');
  }

  demoClassCallBack() {
    this.demoClassSubmitted = true;
    // Use Renderer2 to add a class that will close the modal
    if (this.selectedDemoClassCourseId === undefined || this.selectedDemoClassCourseId === null || isNaN(this.selectedDemoClassCourseId)) {
      this.DemoClassValidationMessage = 'Course is required';
      return;
    } else {
      this.DemoClassValidationMessage = '';
    }
    if (this.demoClassForm.valid) {

      // this.isButtonDisable = true;
      this.enquiry.EnquiryId = 0;
      this.enquiry.Name = this.demoClassForm.value.name;
      this.enquiry.Email = this.demoClassForm.value.email;
      this.enquiry.Mobile = this.demoClassForm.value.number;
      this.enquiry.CourseId = this.selectedDemoClassCourseId;
      this.enquiry.LinkedInProfile = '';
      this.enquiry.StatusId = 1;
      this.enquiry.IsReferal = 0;
      this.enquiry.EnquirySourceId = 1;
      this.enquiry.ReferalUserId = 0;
      this.enquiry.Remarks = '';
      this.enquiry.IsActive = 1;

      this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Demo Class' : this.utmSource;
      this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
      this.renderer.removeClass(this.demoPopup.nativeElement, 'show');
      this.renderer.setStyle(this.demoPopup.nativeElement, 'display', 'none');
      this.renderer.setAttribute(this.demoPopup.nativeElement, 'aria-hidden', 'true');
      this.renderer.setStyle(document.body, 'overflow', 'auto');  // Reset the body's overflow
      this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
        res => { },
        err => {
          console.log(err);
          // this.isButtonDisable = false;
          this.notifyService.showError("Something wrong! Please try after sometime.");
        }
      );

    }
  }


  closePopu() {

    jQuery('#diwali_wishes').modal('hide');
    jQuery('#demoPopup').modal('hide');
  }

}
