<app-header-style-one></app-header-style-one>

<div class="contact-area pb-100">
    <div class="map-area">
        <img src="../../../../assets/img/instructor-profile-banner.jpg" class="view-on-laptop" alt="">
        <img src="../../../../assets/img/instructor-profile-banner-mobile.jpg" class="view-on-mobile" alt="">
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="section-title">
            <h2>Trainer Profile Form</h2>
        </div>
        <div class="contact-form">
            <form [formGroup]="contactForm">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input required minlength="3" id="name" type="text" class="form-control" placeholder="Name"
                        formControlName="name">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input required type="email" class="form-control" placeholder="Email" formControlName="email"
                        id="email">
                </div>
                <div class="form-group">
                    <label for="number">Number</label>
                    <input required type="text" class="form-control" placeholder="Phone" formControlName="number"
                        id="number">
                </div>
                <div class="form-group">
                    <label for="message">Linkedin Profile (If Any)</label>
                    <input required type="text" class="form-control" placeholder="Linkedin Profile"
                        formControlName="linkleurl" id="message">
                </div>
                <div class="form-group">
                    <label for="youtube">YouTube Channel Link (If Any)</label>
                    <input required type="text" class="form-control" placeholder="YouTube Channel Link"
                        formControlName="youTubeChannelLink" id="youtube">
                </div>
                <div class="form-group">
                    <label for="current-profile">Current Profile (Student/Professional)</label>
                    <input required type="text" class="form-control"
                        placeholder="Current Profile (Student/Professional)" formControlName="currentProfile"
                        id="current-profile">
                </div>
                <div class="form-group">
                    <label for="WorkingProfessional">In Case of Working Professional (Mention Current Company)</label>
                    <input required type="text" class="form-control" placeholder="Mention Current Company"
                        formControlName="mentionCurrentCompany" id="WorkingProfessional">
                </div>
                <div class="form-group">
                    <label for="TeachingVideoLink">Teaching Video Link (If Any)</label>
                    <input required type="text" class="form-control" placeholder="Teaching Video Link"
                        formControlName="teachingVideoLink" id="TeachingVideoLink">
                </div>
                <div class="form-group">
                    <label for="YearsOfExpinTraining">Years of Exp in Training</label>
                    <input required type="text" class="form-control" placeholder="Years of Exp in Training"
                        formControlName="yearOfExpInTraining" id="YearsOfExpinTraining">
                </div>
                <div class="form-group">
                    <label for="PreviousExperience">Previous Experience (if any) as a Trainer</label>
                    <input required type="text" class="form-control" placeholder="Previous Experience"
                        formControlName="previousExperience" id="PreviousExperience">
                </div>
                <div class="form-group">
                    <label for="message">Preferred Teaching Mode</label>
                    <div class="radio-check-box">
                        <div class="Online" *ngFor="let item of PreferredTeachingMode">
                            <span>{{item.name}}</span><input required type="checkbox" name="checkbox"
                                (change)="selectPreferredTeachingMode(item)" [checked]="item.selected">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="Availability">Availability (On-Campus/Offline Mode)</label>
                    <input required type="text" class="form-control" placeholder="Availability (On-Campus/Offline Mode)"
                        formControlName="onCampusOfflineAvailability" id="Availability">
                </div>

                <div class="form-group">
                    <label for="message"> Availability (Online Mode)</label>
                    <div class="radio-check-box">
                        <div class="Online" *ngFor="let item of AvailabilityOnlineMode">
                            <span>{{item.name}}</span><input required type="checkbox" name="checkbox"
                                (change)="selectAvailability(item)">
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="AnyExperiencewithOffline">Any Experience with Offline (On-Campus) Training</label>
                    <input required type="text" class="form-control"
                        placeholder="Any Experience with Offline (On-Campus) Training"
                        formControlName="anyExperienceWithOfflineTraining" id="AnyExperiencewithOffline">
                </div>
                <div class="form-group">
                    <label for="message">Area of Expertise</label>
                    <div class="radio-check-box">

                        <div class="Online" *ngFor="let item of AreaOfExpertise">
                            <span>{{item.name}}</span><input required type="checkbox" name="checkbox"
                                (change)="selectAreaOfExperrties(item)" [checked]="item.selected">
                        </div>


                    </div>

                </div>

                <div class="form-group">
                    <label for="highlight"> Anything else if you want to highlight</label>
                    <textarea name="highlight" id="highlight" cols="30" rows="10" class="form-control"
                        placeholder="Profile details.." formControlName="anyHighlight"></textarea>
                </div>


            </form>
            <div class="try-again-message" *ngIf="timer !== 0">
                Please wait for <span>{{timer}}</span> sec to make another request!
            </div>
            <div class="button" style="text-align: right;">
                <button class="btn commButtonCss" [disabled]="isButtonDisable === true" (click)="submitTrainerProfile()"><span>Submit</span></button>
            </div>

        </div>

    </div>
</div>
<div class="contact-info-area">
    <div class="container">
        <div class="contact-info-heading">
            <label for="">NEED HELP?</label>
            <h2>If you require any assistance or have questions, feel free to contact us at your convenience.</h2>
        </div>

        <div class="info-box pb-100">
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-md-12 col-xl-6 col-xxl-6 hover-common-card">

                    <div class="contact-info-box ">

                        <div class="icon">
                            <img src="../../../../assets/img/contact-us/headphone-icon.png" alt="">
                        </div>
                        <h3>Call Us On</h3>
                        <p><a href="tel:+91-7260058093">+91-7260058093</a></p>
                    </div>

                </div>
                <div class="col-lg-6 col-sm-12 col-md-12 col-xl-6 col-xxl-6 hover-common-card">
                    <div class="contact-info-box">
                        <div class="icon">
                            <img src="../../../../assets/img/contact-us/invalop-icon.png" alt="">
                        </div>
                        <h3>Email Us</h3>
                        <p><a href="mailto:info@algotutor.io">info@algotutor.io</a></p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
