import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Enquiry } from 'src/app/models/enquiry.model';
@Component({
  selector: 'app-instructor-profile',
  templateUrl: './instructor-profile.component.html',
  styleUrls: ['./instructor-profile.component.scss']
})
export class InstructorProfileComponent implements OnInit {
  enquiry: Enquiry = new Enquiry();
  contactForm: FormGroup;
  preferredTeachingMode: string = '';
  availabilityOnlineMode: string;
  choosesAreaOfExperrties: string;
  PreferredTeachingMode = [
    { id: 1, name: 'Online', selected: false },
    { id: 2, name: 'Offline', selected: false },
    { id: 3, name: 'Both', selected: false }
  ];
  AvailabilityOnlineMode = [
    { id: 1, name: 'MWF', selected: false },
    { id: 2, name: 'TTS', selected: false },
    { id: 3, name: 'Daily', selected: false },
    { id: 4, name: 'Weekend Only', selected: false }
  ]
  AreaOfExpertise = [
    { id: 1, name: 'DSA', selected: false },
    { id: 2, name: 'System Design', selected: false },
    { id: 3, name: 'Python', selected: false },
    { id: 4, name: 'Computer Fundamentals', selected: false },
    { id: 5, name: 'MERN Stack', selected: false },
    { id: 6, name: 'Data Science', selected: false }
  ]
  AreaOfExpertise1 = [
  
  ]

  timer: number = 0;
  isButtonDisable:boolean = false;
  constructor(private commonservice: CommonServiesService, private notifyService: NotificationService, private router: Router, private fb: FormBuilder) {

  }
  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: [''],
      youTubeChannelLink: [''],
      currentProfile: [''],
      mentionCurrentCompany: [''],
      teachingVideoLink: [''],
      yearOfExpInTraining: [''],
      previousExperience: [''],
      onCampusOfflineAvailability: [''],
      anyExperienceWithOfflineTraining: [''],
      anyHighlight: [''],
    });
  }

  selectPreferredTeachingMode(item: any) {
    item.selected = !item.selected;
    this.preferredTeachingMode = this.PreferredTeachingMode
      .filter(item => item.selected)
      .map(item => item.name)
      .join(', ');
  }
  selectAvailability(item: any) {
    item.selected = !item.selected;
    this.availabilityOnlineMode = this.AvailabilityOnlineMode
      .filter(item => item.selected)
      .map(item => item.name)
      .join(', ');
      console.log(this.availabilityOnlineMode)
  }
  selectAreaOfExperrties(item: any) {
    item.selected = !item.selected;
    this.choosesAreaOfExperrties = this.AreaOfExpertise
      .filter(item => item.selected)
      .map(item => item.name)
      .join(', ');
      console.log(this.choosesAreaOfExperrties)
  }

  submitTrainerProfile() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    } else if (this.contactForm.value.currentProfile === undefined || this.contactForm.value.currentProfile === null || this.contactForm.value.currentProfile === '') {
      this.notifyService.showError("Current profile is required");
      return;
    } else if (this.contactForm.value.yearOfExpInTraining === undefined || this.contactForm.value.yearOfExpInTraining === null || this.contactForm.value.yearOfExpInTraining === '') {
      this.notifyService.showError("Year of experience is required");
      return;
    } else if (this.preferredTeachingMode === undefined || this.preferredTeachingMode === '') {
      this.notifyService.showError("Please choose preferred teaching mode");
      return;
    } else if (this.contactForm.value.onCampusOfflineAvailability === undefined || this.contactForm.value.onCampusOfflineAvailability === null || this.contactForm.value.onCampusOfflineAvailability === '') {
      this.notifyService.showError("Availability (On-Campus/Offline Mode)");
      return;
    } else if (this.availabilityOnlineMode === undefined || this.availabilityOnlineMode === null || this.availabilityOnlineMode === '') {
      this.notifyService.showError("Availability (Online Mode) is required");
      return;
    } else if (this.contactForm.value.anyExperienceWithOfflineTraining === undefined || this.contactForm.value.anyExperienceWithOfflineTraining === null || this.contactForm.value.anyExperienceWithOfflineTraining === '') {
      this.notifyService.showError("Any experience with offline (On-Campus) training is required");
      return;
    } else if (this.choosesAreaOfExperrties === undefined || this.choosesAreaOfExperrties === null || this.choosesAreaOfExperrties === '') {
      this.notifyService.showError("Area of expertise is required");
      return;
    }
    this.isButtonDisable = true;
    const data = {
      "instructorProfileId": 0,
      "name": this.contactForm.value.name,
      "email": this.contactForm.value.email,
      "mobile": this.contactForm.value.number,
      "linkedinProfile": this.contactForm.value.linkleurl,
      "youTubeLink": this.contactForm.value.youTubeChannelLink,
      "currentProfile": this.contactForm.value.currentProfile,
      "currentCompany": this.contactForm.value.mentionCurrentCompany,
      "teachingVideoLink": this.contactForm.value.teachingVideoLink,
      "yearsofExp": this.contactForm.value.yearOfExpInTraining,
      "previousExp": this.contactForm.value.previousExperience,
      "teachingMode": this.preferredTeachingMode,
      "onCampusOfflineAvailability": this.contactForm.value.onCampusOfflineAvailability,
      "onlineAvailability": this.availabilityOnlineMode,
      "onCampusTeachingExp": this.contactForm.value.anyExperienceWithOfflineTraining,
      "areaofExpertise": this.choosesAreaOfExperrties,
      "anythingElseDetails": this.contactForm.value.anyHighlight,
      "isActive": 0
    }
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getInstructorEnqueryForm(data).subscribe(
      response => { this.notifyService.showSuccess("Application submitted successfully");
      }, error => {
        this.isButtonDisable = false;
        console.log(error)
      }
    )
  }
}