import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Enquiry } from 'src/app/models/enquiry.model';

@Component({
  selector: 'app-college-ambassador-program',
  templateUrl: './college-ambassador-program.component.html',
  styleUrls: ['./college-ambassador-program.component.scss']
})
export class CollegeAmbassadorProgramComponent implements OnInit {

  enquiry: Enquiry = new Enquiry();
  contactForm: FormGroup;
  preferredTeachingMode: string = '';
  availabilityOnlineMode: string;
  choosesAreaOfExperrties: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private notifyService: NotificationService, private router: Router, private fb: FormBuilder) {

  }
  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: [''],
      collegeName: [''],
      mentionDetails: [''],
      highlight: [''],
    });
  }


  submitTrainerProfile() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    } 
    this.isButtonDisable = true;
  
    const data = {
      "instructorProfileId": 0,
      "name": this.contactForm.value.name,
      "email": this.contactForm.value.email,
      "mobile": this.contactForm.value.number,
      "linkedinProfile": this.contactForm.value.linkleurl,
      "collegeName": this.contactForm.value.collegeName,
      "mentionDetails": this.contactForm.value.mentionDetails,
      "highlight": this.contactForm.value.highlight,
      "isActive": 0
    }
    this.timer = 10;  
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getInstructorEnqueryForm(data).subscribe(
      response => {
        this.notifyService.showSuccess("Application submitted successfully");
      }, error => {
        this.isButtonDisable = false;
        console.log(error)
      }
    )
  }
}