import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-alumni-call-popup',
  templateUrl: './alumni-call-popup.component.html',
  styleUrls: ['./alumni-call-popup.component.scss']
})
export class AlumniCallPopupComponent implements OnInit {
  popupForm: FormGroup;
  submitted: boolean = false;
  nextBatchTime: string = '';
  nextBatchSuffix: string = '';
  nextBatchDay: string = '';
  selectedCandidateProfile: string;
  candidateProfileErrorMessqage: string = '';
  pageURL: string;
  utmSource: any;
  constructor(public activeModal: NgbActiveModal, private route: ActivatedRoute,
    private fb: FormBuilder, private router: Router,
    private notifyService: NotificationService,
    private commonservice: CommonServiesService) {
    this.pageURL = this.router.url;

  }


  ngOnInit(): void {
    this.popupForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: ['', [
        Validators.required,
        Validators.pattern("^[0-9]{10}$")
      ]],
      experience: ['', Validators.required],
    });

    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
  }

  get registerFormControl() {
    return this.popupForm.controls;
  }

  onRadioButtonChange(value: string) {
    this.selectedCandidateProfile = value;
    this.candidateProfileErrorMessqage = ''
  }

  sendRequest() {
    this.submitted = true;
    if (this.selectedCandidateProfile === '' || this.selectedCandidateProfile === undefined) {
      this.candidateProfileErrorMessqage = 'Please select any one profile';
      return;
    } else {
      this.candidateProfileErrorMessqage = ''
    }
    if (this.popupForm.valid) {
      const json = {
        "enquiryId": 0,
        "name": this.popupForm.value.name,
        "email": this.popupForm.value.email,
        "mobile": this.popupForm.value.number,
        "enquiryCategory": "Alumni Call Request: " + sessionStorage.getItem('alumniName'),
        "courseId": 18,
        "linkedInProfile": "",
        "statusId": 1,
        "isReferal": 0,
        "isPotential": 0,
        "enquirySourceId": 1,
        "referalUserId": 0,
        "remarks": "",
        "isActive": 1,
        "ipAddress": "",
        "registeredOn": new Date().toISOString(),
        "lastModifiedOn": new Date().toISOString(),
        "lastModifiedBy": 0,
        "assignedTo": "",
        "errorIfAny": "",
        "sourceGuid": "",
        "yearofExp": this.popupForm.value.experience,
        "candidateProfile": this.selectedCandidateProfile
      }
        this.notifyService.showSuccess("Your request has been received! Expect a call within the next 24 hours.");
      this.activeModal.dismiss('Cross click');
      this.commonservice.getCommonEnqueryForm(json).subscribe(
        res => { },
        err => {
          console.log(err);
          // this.isButtonDisable = false;
          this.notifyService.showError("Something wrong! Please try after sometime.");
        }
      );
    }

  }

}
