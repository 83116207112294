<div class="hero-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12 col-xl-8">
                <div class="hero-banner-content">
                    <h1>Transform Learning into Success with AlgoTutor</h1>
                    <h4>Accelerate your professional growth under the guidance of top industry veterans</h4>
                    <div class="student-image">
                        <img src="../../../../../assets/img/home-banner-img/Algo_studentsbatch.png" alt=""
                            class="img-fluid">
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 col-xl-4">
                <div class="contact-over-image-form">
                    <h4>Request for <span class="common-header-color">Free!</span> Demo Class</h4>
                    <p>Someone from our <span class="common-header-color">Mentors</span> will connect with you shortly.
                    </p>
                    <form [formGroup]="contactForm" class="contact-form">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required minlength="3" type="text" class="form-control" placeholder="Name"
                                        formControlName="name">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required type="text" class="form-control" placeholder="Email"
                                        formControlName="email">
                                    <small class="text-danger"
                                        *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                                        Email is required
                                    </small>
                                    <small *ngIf="registerFormControl.email.errors?.pattern" class="text-danger">Please
                                        provide a valid
                                        email address</small>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required type="text" class="form-control" placeholder="Phone"
                                        formControlName="number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="" id="" class="form-control" style="color: rgba(0, 0, 0, 0.671);"
                                        (change)="selectCourseId($event.target.value)">
                                        <option value="undefined" selected>Select Program</option>
                                        <option value="1">Advanced Data Science & AI</option>
                                        <option value="2">Mastering DSA & System Design</option>
                                        <option value="3">Mastering DSA & System Design with Full Stack Specialization
                                        </option>
                                        <option value="4">Complete Placement Package for College Students</option>
                                        <option value="5">Full Stack Web Development - MERN</option>
                                        <option value="6">Tech Interview Preparation & Mock Interviews</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required type="text" class="form-control"
                                        placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <div class="enquery-form-button">
                                    <button class="btn" [disabled]="isButtonDisable === true"
                                        (click)="requestCallBack()">Request Callback</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="feature-banner">
        <app-features-banner-tiles></app-features-banner-tiles>
    </div>
</div>
<div class="floating-notification-tiles" *ngIf="hideNotificationCard === false">
    <div class="content">
        <div class="button-and-course-name">

            <span style="background-color: #FFA41B; padding: 7px 10px; border-radius: 5px; color: #fff;">Demo
                Class</span>
            <strong>{{notificationDetails.courseName}}</strong>
        </div>
        <div class="timing">
            <div class="time">
                <span><i class="fa-regular fa-clock"></i> {{notificationDetails.courseTime}} <i
                        class="fa-regular fa-calendar"></i>
                    {{notificationDetails.day}}</span>
            </div>
        </div>
        <div class="book-demo-section">
            <button [disabled]="isButtonDisabled" class="btn btn-sm commButtonCss" data-toggle="modal"
                data-target="#demoPopup"><span>Book Now</span></button>
            <i class="fa-solid fa-xmark" (click)="hideFlootingNotificatinfBanner()"></i>
        </div>
    </div>
</div>

<div class="floating-notification-tiles-mobile" *ngIf="hideNotificationCard === false">

    <div class="row" style="padding:10px; padding-bottom: 15px;">
        <div class="col-8">
            <div class="button-and-course-name-mobile">
                <span style="background-color: #FFA41B; padding: 7px 10px; border-radius: 5px; color: #fff;">Demo
                    Class</span>
                <br>
                <strong>{{notificationDetails.courseName}}</strong>

            </div>
        </div>
        <div class="col-4" style="text-align: right;">
            <i class="fa-solid fa-xmark" style="color: #fff; font-size: 20px; padding-bottom: 10px;"
                (click)="hideFlootingNotificatinfBanner()"></i>
            <br>
            <button class="btn btn-sm commButtonCss" (click)="bookDemo()"><span>Book Now</span></button>
        </div>
    </div>

</div>
<!--  <div class="modalCssForNotification" *ngIf="displayNotificationModel == true">
    <div class="card">
        <i class="fa-solid fa-circle-xmark close" (click)="closeNotificationModal()"></i>
        <img src="../../../../../assets/img/notify.jpg" alt="">
    </div>
</div>-->
<div class="modal fade" id="demoPopup" #demoPopup data-backdrop="static" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-12">
                            <i class="fa-solid fa-circle-xmark" style="color: black;" data-dismiss="modal"
                                aria-label="Close"></i>
                            <div class="form-content">
                                <h3>Request for <span style="color: var(--mainColor);">Free!</span> Demo Class</h3>
                                <p>Someone from our <span style="color: var(--mainColor);">Mentors</span> will connect
                                    with you shortly.</p>
                                <form [formGroup]="demoClassForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                        <small class="text-danger"
                                            *ngIf="(getdemoClassControl.name.touched || demoClassSubmitted) && getdemoClassControl.name.errors?.required">
                                            Name is required
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Email"
                                            formControlName="email">
                                        <small class="text-danger"
                                            *ngIf="(getdemoClassControl.email.touched || demoClassSubmitted) && getdemoClassControl.email.errors?.required">
                                            Email is required
                                        </small>
                                        <small *ngIf="getdemoClassControl.email.errors?.pattern" class="text-danger">
                                            Please provide a valid email address
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                        <small class="text-danger"
                                            *ngIf="(getdemoClassControl.number.touched || demoClassSubmitted) && getdemoClassControl.number.errors?.required">
                                            Phone number is required
                                        </small>
                                        <small *ngIf="getdemoClassControl.number.errors?.pattern" class="text-danger">
                                            Please provide a valid phone number (10 digits)
                                        </small>
                                    </div>
                                    <div class="form-group">
                                        <select name="" id="" class="text-muted form-control select_list"
                                            (change)="selectDemoClassCourseId($event.target.value)">
                                            <option value="undefined" selected>Select Program</option>
                                            <option value="1">Mastering Advance Data Science & Generative AI</option>
                                            <option value="2">Mastering DSA & System Design</option>
                                            <option value="3">Mastering DSA & System Design with Full Stack Specialization</option>
                                            <option value="4">Complete Placement Package for College Students</option>
                                            <option value="5">Full Stack Web Development - MERN</option>
                                            <option value="6">Tech Interview Preparation & Mock Interviews</option>
                                        </select>
                                        <small class="text-danger">{{DemoClassValidationMessage}}</small>
                                    </div>
                                </form>
                                <div class="button" style="text-align: center;">
                                    <button class="btn" (click)="demoClassCallBack()"><span>Apply Now</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="diwali_wishes" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-md modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body p-0" style="border-radius: 5px;">
                <i class="fa-solid fa-circle-xmark" data-dismiss="modal" aria-label="Close" (click)="closePopu()" style="position: absolute; top: 10px; right: 10px; cursor: pointer;"></i>
                <img style="border-radius: 5px; width: 100%;" src="../../../../../assets/img/pop-Up.jpg" alt="Diwali Wishes">

                <div class="form notiication-form mt-n2 p-3">
                    <form [formGroup]="notificationContactForm">
                        <div class="row">
                            <div class="col-12 col-md-6 mb-2">
                                <label for="name">Name</label>
                                <input type="text" id="name" class="form-control" placeholder="Enter Full Name" formControlName="notifyname">
                                <small class="text-danger" *ngIf="(popupFormControl.notifyname.touched || popupSubmitted) && popupFormControl.notifyname.errors?.required">
                                    Name is required
                                </small>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <label for="email">Email</label>
                                <input required type="text" id="email" class="form-control" placeholder="Enter Your Email" formControlName="notifyemail">
                                <small class="text-danger" *ngIf="(popupFormControl.notifyemail.touched || popupSubmitted) && popupFormControl.notifyemail.errors?.required">
                                    Email is required
                                </small>
                                <small *ngIf="popupFormControl.notifyemail.errors?.pattern" class="text-danger">
                                    Please provide a valid email address
                                </small>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <label for="number">Phone Number</label>
                                <input type="number" id="number" class="form-control" placeholder="Mobile Number" formControlName="notifynumber">
                                <small class="text-danger" *ngIf="(popupFormControl.notifynumber.touched || popupSubmitted) && popupFormControl.notifynumber.errors?.required">
                                    Phone number is required
                                </small>
                                <small *ngIf="popupFormControl.notifynumber.errors?.pattern" class="text-danger">
                                    Please provide a valid phone number (10 digits)
                                </small>
                            </div>
                            <div class="col-12 col-md-6 mb-2">
                                <label for="domain">Domain</label>
                                <select id="domain" class="form-control" (change)="selectCourseIdPopup($event.target.value)">
                                    <option value="0" selected>Select Domain</option>
                                    <option value="2">Software Engineering</option>
                                    <option value="1">Data Science</option>
                                </select>
                                <small class="text-danger">{{domainValidationMessage}}</small>
                            </div>
                        </div>
                    </form>
                    <div class="button text-center">
                        <button class="btn btn-sm commButtonCss mt-2" type="button" (click)="popupRequestCallBack()">
                            <span>Talk to Program Mentor</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



