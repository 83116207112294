<div class="container">
    <div class="section-title">
        <h3 class="section-header__title"><b class="common-header-color">Google Reviews</b> From our Alumni & Learners</h3>
    </div>
    <owl-carousel-o [options]="carouselOptions">
        <ng-container *ngFor="let data of googleReviewArr">
            <ng-template carouselSlide>
                <div class="card hover-common-card">
                    <div class="card-header-area">
                        <div class="row">
                            <div class="col-7">
                                <p class="reviewer-name">{{data.name}}</p>
                            </div>
                            <div class="col-5">
                                <span class="star-icon"><ngb-rating [max]="5" [(rate)]="data.starRating"  [readonly]="true" ></ngb-rating>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="detail-area">
                            <div><i class="fa-solid fa-quote-left"></i></div>
                            <p>{{data.reviewData}}</p>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>