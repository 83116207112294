<app-header-style-one></app-header-style-one>
<app-homefive-main-banner></app-homefive-main-banner>
<div class="choose-logo-card mt-1">
    <app-how-does-algo-work></app-how-does-algo-work>
</div>
<app-all-courses></app-all-courses>
<div class="advantages-of-joining-algo">
    <img src="../../../../assets/img/advantage-banner.svg" alt="">
</div>

<div class="testimonials-area">
    <div class="container">
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>
<div class="testimonials-areaq">
    <div style="margin-top: -50px;" class="container">
        <app-our-mentor></app-our-mentor>
    </div>

</div>
<div class="bacome-instructor">
    <app-become-instructor-partner></app-become-instructor-partner>
</div>

<div class="testimonials-area mt-4">
    <div class="container">
        <div class="section-title">
            <h3 class="section-header__title">Learn and get trained to be a part of top <b
                    class="common-header-color">technology companies</b> and
                <b class="common-header-color">leading startups</b>
            </h3>
            <br>
            <img class="img-fluid" src="../../../../assets/img/Company-Logo-IMGAGE.jpg">
        </div>
    </div>
</div>

<app-google-review></app-google-review>
<app-career-counselling></app-career-counselling>

<div style="padding-bottom: 30px;">
    <app-faq></app-faq>
</div>

<div class="modal" tabindex="-1" role="dialog" [ngStyle]="{'display':displayStyle}">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content ">
            <div class="modal-header">
                <h4 style="color: black;" class="modal-title">Request Callback</h4>
                <button type="button" class="close" (click)="closePopup()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="contact-form">
                    <form #contactForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text"
                                        #name="ngModel" class="form-control" id="name" placeholder="Your name">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Name is required.</div>
                                        <div *ngIf="name.errors.minlength">Name is minimum {{
                                            name.errors.minlength.requiredLength }} character.</div>
                                        <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel"
                                        class="form-control" id="email" placeholder="Your email address">
                                    <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email
                                        Address
                                        is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel"
                                        class="form-control" id="number" placeholder="Your phone number">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone
                                        number
                                        is required.</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="year" type="text" #year="ngModel" class="form-control"
                                        id="year" placeholder="Year of Graduation">
                                    <div class="alert alert-danger" *ngIf="year.touched && !year.valid">Year of
                                        Graduation</div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12">
                                <div class="form-group">
                                    <select name="" id="" class="form-control text-muted" style="opacity: 0.8; ">
                                        <option value="">Cources Name</option>
                                        <option value="">Cources Name</option>
                                        <option value="">Cources Name</option>
                                        <option value="">Cources Name</option>
                                        <option value="">Cources Name</option>
                                        <option value="">Cources Name</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30"
                                        rows="3" class="form-control"
                                        placeholder="Your question are ............."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message
                                        is
                                        required.</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid"><i
                                        class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send
                                        Message</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="closePopup()">Close</button>
            </div>
        </div>
    </div>
</div>
