<app-header-style-one></app-header-style-one>

<div class="contact-area pb-100">
    <div class="map-area">
        <img src="../../../../assets/img/instructor-profile-banner.jpg" class="view-on-laptop" alt="">
        <img src="../../../../assets/img/instructor-profile-banner-mobile.jpg" class="view-on-mobile" alt="">
    </div>
    <div class="container" style="margin-top: 50px;">
        <div class="section-title">
            <h2>College Ambassador Program</h2>
        </div>
        <div class="contact-form">
            <form [formGroup]="contactForm">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input required minlength="3" id="name" type="text" class="form-control" placeholder="Name"
                        formControlName="name">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input required type="email" class="form-control" placeholder="Email" formControlName="email"
                        id="email">
                </div>
                <div class="form-group">
                    <label for="number">Number</label>
                    <input required type="text" class="form-control" placeholder="Phone" formControlName="number"
                        id="number">
                </div>
                <div class="form-group">
                    <label for="message">Linkedin Profile (If Any)</label>
                    <input required type="text" class="form-control" placeholder="Linkedin Profile"
                        formControlName="linkleurl" id="message">
                </div>
                <div class="form-group">
                    <label for="youtube">College Name</label>
                    <input required type="text" class="form-control" placeholder="Your College Name"
                        formControlName="collegeName" id="youtube">
                </div>
                
                <div class="form-group">
                    <label for="mentionDetails">Are you contributing as an Ambassador for any other institution?(If yes, mention details)
                    </label>
                    <input required type="text" class="form-control" placeholder="Mention Details"
                        formControlName="mentionDetails" id="mentionDetails">
                </div>
                <div class="form-group">
                    <label for="highlight">Please highlight areas where you can contribute to AlgoTutor
                    </label>
                    <textarea  id="highlight" cols="30" rows="6" class="form-control" placeholder="Highlight Area" formControlName="highlight"></textarea>
                </div>
            </form>
            <div class="try-again-message" *ngIf="timer !== 0">
                Please wait for <span>{{timer}}</span> sec to make another request!
            </div>
            <div class="button" style="text-align: right;">
                <button class="btn commButtonCss" [disabled]="isButtonDisable === true" (click)="submitTrainerProfile()"><span>Submit</span></button>
            </div>

        </div>

    </div>
</div>
<div class="contact-info-area">
    <div class="container">
        <div class="contact-info-heading">
            <label for="">NEED HELP?</label>
            <h2>If you require any assistance or have questions, feel free to contact us at your convenience.</h2>
        </div>

        <div class="info-box pb-100">
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-md-12 col-xl-6 col-xxl-6 hover-common-card">

                    <div class="contact-info-box ">

                        <div class="icon">
                            <img src="../../../../assets/img/contact-us/headphone-icon.png" alt="">
                        </div>
                        <h3>Call Us On</h3>
                        <p><a href="tel:+91-7260058093">+91-7260058093</a></p>
                    </div>

                </div>
                <div class="col-lg-6 col-sm-12 col-md-12 col-xl-6 col-xxl-6 hover-common-card">
                    <div class="contact-info-box">
                        <div class="icon">
                            <img src="../../../../assets/img/contact-us/invalop-icon.png" alt="">
                        </div>
                        <h3>Email Us</h3>
                        <p><a href="mailto:info@algotutor.io">info@algotutor.io</a></p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
