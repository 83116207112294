import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/services/modal.service';
import { Title, Meta } from '@angular/platform-browser';


@Component({
    selector: 'app-mastring-dsa-system-design',
    templateUrl: './mastring-dsa-system-design.component.html',
    styleUrls: ['./mastring-dsa-system-design.component.scss'],
    animations: [
        trigger('expandCollapse', [
            state('collapsed', style({ height: '0', display: 'none' })),
            state('expanded', style({ height: '*' })),
            transition('collapsed <=> expanded', [animate('300ms ease-in-out')]),
        ]),
    ],
})
export class MastringDsaSystemDesignComponent implements OnInit {
    course_details = environment.course_date_mastring_dsa_systen_design;
    course_date_month = environment.course_date_month;
    moduleQuestion: any[] = [];
    course_class_mode_text = [
        'Live Interactive Classes',
        'Personalized Mentorship',
        'Unlimited Mock Interviews',
        'Lifetime Content Access',
        'Guaranteed Job Referrals',
        'Unlimited Doubt Support'
    ]
    contactForm: FormGroup;
    currentTextIndex: number = 0;
    classFeature: string = this.course_class_mode_text[this.currentTextIndex];
    enquiry: Enquiry = new Enquiry();
    moduleArray = [
        {
            id: 1,
            active: true,
            courseTopic: 'Foundation Of Programming',
            timeTaking: '2 weeks',
            childProperties: [
                {
                    id: 1,
                    module_description: 'Build a solid knowledge base by mastering the fundamentals of programming to ensure you have a strong coding base for technical challenges.',
                    moduleContant: [
                        'Programming basics',
                        'Conditional statements, loops, functions',
                        'Maths used in programming',
                        'Recursion simplified',
                        'Bit manipulation',
                    ],
                    outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
                }
            ]
        },
        {
            id: 2,
            active: false,
            courseTopic: 'Data Structures and Algorithms',
            timeTaking: '14 weeks',
            childProperties: [
                {
                    id: 1,
                    module_description: 'Dive deep into the world of data structures and algorithms, sharpening your problem-solving skills to tackle complex coding tasks with confidence.',
                    moduleContant: [
                        'Algorithm time and space complexity analysis',
                        'Array and it’s techniques (2 pointer, Negation method etc)',
                        'Searching and Sorting',
                        'Hashing techniques',
                        'Linkedlist',
                        'Stack and Queue',
                        'Introduction to Tree data-structure',
                        'Different types of tree data-structures (Binary tree, BST, N-array tree)',
                        'Red-black tree, Avl tree, Segment tree',
                        'Graph and graph traversal algorithms',
                        'Heap',
                        'Trie, TST',
                        'Matrix related problems',
                        'Greedy programming',
                        'Backtracking',
                        'Dynamic-programming',

                    ],
                    outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
                }
            ]
        },
        {
            id: 3,
            active: false,
            courseTopic: 'High Level Design',
            timeTaking: '6 weeks',
            childProperties: [
                {
                    id: 1,
                    module_description: 'Explore the art of designing scalable and efficient software systems at the architectural level, preparing you for large-scale projects and technical leadership roles.',
                    moduleContant: [
                        'System design basics',
                        'Types of databases and when to use which type of DB',
                        'DB sharding',
                        'Load balancing',
                        'Horizontal/Vertical Scaling',
                        'Caching algorithms, CDNs',
                        'CAP theorem',
                        'Messaging queue',
                        'Consistent hashing',
                        'Proxies',
                        'Indexes',
                        'Long-polling vs Websockets vs server-sent-events',
                        'Design a system for single user to millions of users',
                        'Microservices, Client Server architecture, Authentication & Authorization',
                        'Rest API fundamentals',
                        'Case studies'
                    ],
                    outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
                }
            ]
        },
        {
            id: 4,
            active: false,
            courseTopic: 'Low Level Design',
            timeTaking: '4 weeks',
            childProperties: [
                {
                    id: 1,

                    module_description: 'Delve into the intricacies of low-level system design, understanding how software components interact at a granular level for optimal performance.',
                    moduleContant: [
                        'Object oriented programming',
                        'Good/Bad design',
                        'Clean code architecture',
                        'SOLID principles',
                        'Design patterns',
                        'Concurrency and Multithreading',
                        'Case studies'
                    ],
                    outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
                }
            ]
        },
        {
            id: 5,
            active: false,
            courseTopic: 'Mock Interviews and Placements',
            timeTaking: 'Until Get Placed',
            childProperties: [
                {
                    id: 1,
                    module_description: 'Receive expert guidance and resources to navigate the job market, optimize your resume, and ace technical interviews, ultimately securing your dream role in the tech industry.',
                    moduleContant: [
                        'Resume and Linkedin profile building',
                        'Get referral and apply for Job opportunities',
                        'Mock interviews based on company',
                    ],
                    outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
                },

            ]
        },
        // {
        //   id: 6,
        //   active: false,
        //   courseTopic: 'Unit 5 - Work on End to End Projects in Partnership With Startups',
        //   childProperties: [
        //     {
        //       id: 1,
        //       timeTaking: '6 weeks',
        //       module_description: 'Based on your past coding experience you will be starting this module. Either you can start with this module or move to directly module 2.',
        //       moduleContant: [

        //         'Loops, Function',
        //         'Number System',
        //         'Array',
        //         'Bit Manipulation',
        //         'Searching and Sorting',
        //         'Hashing',
        //         'Strings',
        //         'Time and Space Complexity',
        //       ],
        //       outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        //     }
        //   ]
        // }
    ];

    feedbackSlides: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        animateOut: false,
        autoplayHoverPause: false,
        autoplay: false,
        mouseDrag: false,
        items: 2,
        margin: 10,
        navText: [
            "<i class='bx bx-left-arrow-alt'></i>",
            "<i class='bx bx-right-arrow-alt'></i>"
        ],
        responsive: {
            0: {
                items: 1
            },
            576: {
                items: 1
            },
            768: {
                items: 2
            },
            1200: {
                items: 2
            }
        }
    }
    courseCASEstudyHLD = [
        {
            title: 'Design URL Shortener Service',
            text: `“A URL shortener service creates a short url against a long url. Moreover, when we click on the short/tiny url, it get redirected to the actual url”`,
            Img: '/assets/img/courses/courses_custom/short-url.jpg',
        },
        {
            title: 'Design OTT Platform Like Netflix',
            text: `"Here we will learn the architecture of video streaming platform and how to create an on-demand video streaming app like Netflix”`,
            Img: '/assets/img/courses/courses_custom/netflix.jpg',
        },
        {
            title: 'Design Social Media News Feed',
            text: `"Here we will learn how to design Facebook/Insta’s news feed kind of service, which notify updates from all the users”`,
            Img: '/assets/img/courses/courses_custom/facebook-insta-news-feed.jpg',
        },
        {
            title: 'Design Cab Booking System Like UBER',
            text: `“Here we will learn the architecture of cab booking system and how to design a system like UBER ”`,
            Img: '/assets/img/courses/courses_custom/car-booking.jpg',
        },
        {
            title: 'Design Messaging System Like Whatsapp',
            text: `"Here we will learn the architecture of real time messaging/chat system like Whatsapp”`,
            Img: '/assets/img/courses/courses_custom/whatsapp.jpg',
        }
    ];
    courseCASEstudyLLD = [
        {
            title: 'Design Parking Lot',
            text: `“Here we will learn how to gather requirement for a parking lot kind of system and also complete design of low level code”`,
            Img: '/assets/img/courses/courses_custom/parking-lot.jpg',
        },
        {
            title: 'Design Movie Ticket Booking App',
            text: `"Here we will learn how to design the architecture of a movie booking kind of system and its low level code using OOD principle”`,
            Img: '/assets/img/courses/courses_custom/Moview Ticket-Booking-System.jpg',
        },
        {
            title: 'Design Tic-Tac-Toe Game',
            text: `Here we will deep dive into low level design of a gaming app like Tic-tac-toe with complete class design with implementation”`,
            Img: '/assets/img/courses/courses_custom/tic-tac-toe.jpg',
        },
        {
            title: 'Design a Logging Service',
            text: `"Logging is essential in understanding the event flow in a distributed system. Here we will learn about distributed logging with implementation.”`,
            Img: '/assets/img/courses/courses_custom/logging-service.jpg',
        },
        {
            title: 'Design a Caching Service',
            text: `"Here we will learn about importance/need of cache in a system and different caching techniques with implementation.”`,
            Img: '/assets/img/courses/courses_custom/caching-service.jpg',
        },
        {
            title: 'Design Expense Sharing app like Splitwise',
            text: `"Here we will learn the architecture of splitwise and its low level design with implementation.”`,
            Img: '/assets/img/courses/courses_custom/splitwise-app.jpg',
        }
    ];
    utmSource: string;
    timer: number = 0;
    isButtonDisable: boolean = false;
    constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
        private notifyService: NotificationService, 
        private fb: FormBuilder, 
        private titleService: Title, private modalService: ModalService, private metaService: Meta) {
        // this.toggleAccordian(EventTarget, this.moduleArray[0])
    }

    ngOnInit(): void {
        this.updateMetaTags('Mastering DSA & System Design', 'Master the concepts of Data Structures and Algorithms (DSA) along with System Design in this structured course');
        this.contactForm = this.fb.group({
            name: [''],
            email: ['', [
                Validators.required,
                Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
            number: [''],
            linkleurl: ['']
        });
        // Accessing route parameters
        this.route.queryParams.subscribe(params => {
            this.utmSource = params['utmsource'];
        });
        setInterval(() => {
            this.currentTextIndex = (this.currentTextIndex + 1) % this.course_class_mode_text.length;
            this.classFeature = this.course_class_mode_text[this.currentTextIndex];
        }, 3000);
    }

    updateMetaTags(title: string, description: string) {
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'description', content: description });
    }

    // eachModuleDataArr = [
    //   {
    //     id: 1,
    //     timeTaking: '6 weeks',
    //     module_description: 'Based on your past coding experience you will be starting this module. Either you can start with this module or move to directly module 2.',
    //     moduleContant: [
    //       'Loops, Function',
    //       'Number System',
    //       'Array',
    //       'Bit Manipulation',
    //       'Searching and Sorting',
    //       'Hashing',
    //       'Strings',
    //       'Time and Space Complexity',
    //     ],
    //     outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
    //   }
    // ]
    // getModuleQuestion(event, index, selectedData) {
    //   // const filteredDataFromModule = this.eachModuleDataArr.filter(qId => qId.id == selectedData.id);
    //   // for (let i = 0; i < this.moduleArray.length; i++) {
    //   //   if (this.moduleArray[i].id == selectedData.id) {
    //   //     this.moduleArray[i].active = !this.moduleArray[i].active;
    //   //   } else {
    //   //     this.moduleArray[i].active = false;
    //   //   }
    //   // }
    //   // this.moduleQuestion = filteredDataFromModule;
    //   const element = event.target;
    //   element.classList.toggle("active");
    //   // if (this.moduleArray[index].isActive) {
    //   //   this.moduleArray[index].isActive = false;
    //   // } else {
    //   //   this.moduleArray[index].isActive = true;
    //   // }
    //   const panel = element.nextElementSibling;
    //   if (panel.style.maxHeight) {
    //     panel.style.maxHeight = null;
    //   } else {
    //     panel.style.maxHeight = panel.scrollHeight + "px";
    //   }
    // }
    // toggleAccordian(event, selectedData) {
    //     const element = event?.target;
    //     element.classList.toggle("active");
    //     for (let i = 0; i < this.moduleArray.length; i++) {
    //         if (this.moduleArray[i].id == selectedData.id) {
    //             this.moduleArray[i].active = !this.moduleArray[i].active;
    //         } else {
    //             this.moduleArray[i].active = false;
    //         }
    //     }
    //     const panel = element.nextElementSibling;
    //     if (panel != null) {
    //         if (panel?.style.maxHeight) {
    //             panel.style.maxHeight = null;
    //         } else {
    //             panel.style.maxHeight = panel.scrollHeight + "px";
    //         }
    //     }
    // }

    toggleCourseTitle(selectedData, index) {
        for (let i = 0; i < this.moduleArray.length; i++) {
            if (this.moduleArray[i].id == selectedData.id) {
                this.moduleArray[i].active = !this.moduleArray[i].active;
            } else {
                this.moduleArray[i].active = false;
            }
        }
    }
    requestCallBack() {
        if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
            this.notifyService.showError("Name is required");
            return;
        } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
            this.notifyService.showError("Email is required");
            return;
        } else if (this.contactForm.controls.email.status == 'INVALID') {
            this.notifyService.showError("Please provide valid email address");
            return;
        } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
            this.notifyService.showError("Number is required");
            return;
        }
        this.isButtonDisable = true;
        this.enquiry.EnquiryId = 0;
        this.enquiry.Name = this.contactForm.value.name;
        this.enquiry.Email = this.contactForm.value.email;
        this.enquiry.Mobile = this.contactForm.value.number;
        this.enquiry.CourseId = 2;
        this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
        this.enquiry.StatusId = 1;
        this.enquiry.IsReferal = 0;
        this.enquiry.EnquirySourceId = 1;
        this.enquiry.ReferalUserId = 0;
        this.enquiry.Remarks = '';
        this.enquiry.IsActive = 1;
        this.enquiry.EnquiryCategory = this.utmSource == undefined ? 'Mastring DSA System - Course Page' : this.utmSource;

        this.timer = 10;
        const timerInterval = setInterval(() => {
            if (this.timer > 0) {
                this.timer--;
            } else {
                this.isButtonDisable = false;
                clearInterval(timerInterval); // Stop the timer when it reaches 0
            }
        }, 1000);
        this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
            res => {
            },
            err => {
                this.isButtonDisable = false;
                console.log(err);
                this.notifyService.showError("Something wrong! Please try after sometime.");
            }
        );
        this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
        //this.toClearFormValue(this.contactForm.value);
    }

    toClearFormValue(formValue) {
        formValue.name = '';
    }

    downloadBroucher() {
        this.modalService.openModal();
    }
}
