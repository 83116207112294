<div class="testimonials-slides" style="padding-bottom: 20px;">
    <div class="section-title">
        <span class="sub-title">Discover our Alumni</span>
        <h3 class="section-header__title">Explore Our Alumni & Connect With Them</h3>
        <h6>There are many more, these are the ones who want to share their experiences.</h6>
    </div>
    <div class="feedback-slides">
        <owl-carousel-o [options]="carouselOptions">
            <ng-container *ngFor="let data of ourAlumniArr">
                <ng-template carouselSlide>
                    <section class="courses-area hover-common-card">
                        <!-- <div class="card-area">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                        <div class="image-area">
                                            <a class="d-block image">
                                                <img class="user-image" [src]="data.userImg" alt="image">
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-6">
                                        <span class="alumni__hike"><i class="fa-solid fa-arrow-trend-up"></i>
                                            {{data.hike}}% Hike
                                        </span>
                                    </div>

                                </div>
                            </div>
                            <div class="card-body">
                                <div class="reviewer-details">
                                    <label for="">{{data.name}} <a style="margin-left: 5px;" target="_blank"
                                            href="{{data.linkDin}}"><img src="../../../../assets/img/icons/lindin.png"
                                                alt=""></a></label>
                                    <span>{{data.profession}}</span>
                                    <p>{{data.detail}}</p>

                                </div>
                                <div class="company-btn-text">
                                    <img class="afterAlgoComCss" [src]="data.beforeAlgoCompanyLogo">
                                    <i class="fa-solid fa-arrow-right afterAlgoComCss"></i>
                                    <img class="afterAlgoComCss beforeComLogo" [src]="data.afterAlgoCompanyLogo">
                                </div>
                            </div>

                        </div> -->
                        <div class="card">
                            <div class="card-header">
                                <div class="salary-hike">
                                    <div class="hike">
                                        <i class="fa-solid fa-arrow-trend-up"></i> <span style="padding-left: 5px;">{{data.hike}}% Hike</span>
                                    </div>

                                </div>
                                <div class="user-info">
                                    <div class="user-image">
                                        <img [src]="data.userImg" alt="userImage">
                                    </div>
                                    <div class="user-name">
                                        <label>{{data.name}}</label><br>
                                        <strong>{{data.profession}}</strong>
                                    </div>
                                </div>


                            </div>
                            <div class="card-body">
                                <div class="pre-and-post-company">
                                    <div class="pre-algo">
                                        <label>Pre AlgoTutor</label>
                                        <img [src]="data.beforeAlgoCompanyLogo" alt="company">
                                    </div>
                                    <div class="arrow">
                                        <img src="../../../../assets/img/Arrow2.png" alt="arrow">
                                    </div>
                                    <div class="post-algo">
                                        <label>Post AlgoTutor</label>
                                        <img [src]="data.afterAlgoCompanyLogo" alt="company">
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">

                                <button (click)="alumniCall(data.name)" [disabled]="!data.alumniCall">
                                    <i [ngStyle]="{'color': data.alumniCall ? 'green' : 'red'}"
                                       class="fa-solid fa-circle"></i>
                                    <span>Request For Alumni Call</span>
                                </button>



                                <a target="_blank" href="{{data.linkDin}}">
                                    <button>
                                        <i class="fa-brands fa-linkedin"></i>
                                        <span>View Profile</span>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </section>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
   
    <!-- <div class="col-md-12 col-xl-3 col-lg-3 col-sm-12" *ngFor="let data of OurAlumni">
            <div class="card">
                <div class="single-testimonials-item">
                    <div class="info">
                        <img [src]="data.userImg" class="shadow rounded-circle" alt="image">
                        <h3>{{data.name}}<a target="_blank" href="{{data.linkDin}}"><img class="likndinLogo"
                                    src="../../../../assets/img/lindinLogo.png"></a></h3>
                        <span><img class="companyLogo" [src]="data.beforeCAlgoompanyLogo"></span>
                        <img class="arrowDown" src="../../../../assets/img/icon_down.svg"><br>
                        <button class="alumni__hike">{{data.hike}}% Hike</button><br>
                        <img class="arrowDown" src="../../../../assets/img/icon_down.svg"><br>
                        <img class="companyLogo" [src]="data.afterAlgoCompanyLogo">
                    </div>
                </div>
            </div>
        </div> -->

</div>
