import { Injectable } from '@angular/core';
import { NgbModal,NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DownloadBroucherPopupComponent } from '../components/common/download-broucher-popup/download-broucher-popup.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(private modalService: NgbModal) { }

  openModal() {
    const options: NgbModalOptions = {
      centered: true,
      size: 'lg', // Make the modal large
      backdrop: 'static', // Prevent closing on click outside
      keyboard: false // Prevent closing on escape key
    };
    this.modalService.open(DownloadBroucherPopupComponent, options);
  }
}
