import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutesConfig } from '../app.routes.config ';

@Injectable({
  providedIn: 'root'
})

export class CommonServiesService {


  constructor(private https: HttpClient) { }
  getCommonEnqueryForm(enqueryFormData: any): Observable<any> {
    let headers = { headers: new HttpHeaders({ 'content-Type': 'application/json' }) };
    return this.https.post(`${ApiRoutesConfig.API_URL}${ApiRoutesConfig.API_POSTENQUIRY}`, JSON.stringify(enqueryFormData), headers);
  }
  getInstructorEnqueryForm(enqueryFormData: any): Observable<any> {
    let headers = { headers: new HttpHeaders({ 'content-Type': 'application/json' }) };
    return this.https.post(`${ApiRoutesConfig.API_URL}${ApiRoutesConfig.API_InstructorEnquery}`, JSON.stringify(enqueryFormData), headers);
  }
  //   validateOTP(mobileNo): Observable<any> {
  //     let headers = { headers: new HttpHeaders({ 'content-Type': 'application/json' }) };
  //     return this.https.get<any>(`${ApiRoutesConfig.API_URL}${ApiRoutesConfig.API_ValidateOTP}` + mobileNo);
  //   }
  //   userLogin(loginData): Observable<any> {
  //     let headers = { headers: new HttpHeaders({ 'content-Type': 'application/json' }) };
  //     return this.https.post(`${ApiRoutesConfig.API_URL}${ApiRoutesConfig.API_UserLogin}`, JSON.stringify(loginData), headers);
  //   }

  insertUpdateVisitTracker(GUID:string): Observable<any> {
    let headers = { headers: new HttpHeaders({ 'content-Type': 'application/json' }) };
    return this.https.get<any>(`${ApiRoutesConfig.API_URL}${ApiRoutesConfig.API_InsertUpdateVisitTrackery}?guid=${GUID}`,headers);
  }
}
