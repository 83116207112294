<app-header-style-one></app-header-style-one>

<div class="contact-area pb-100">
    <div class="container">
        <div class="section-title">
            <br>
            <br>
            <span class="sub-title">Application For Mentor/Instructor</span>
            <h2></h2>
            <h4 style="font-weight: bold;">Drop us Message, Someone from AlgoTutor will reach you shortly</h4>
        </div>
        <div class="contact-form">
            <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Your name">
                            <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                <div *ngIf="name.errors.required">Name is required.</div>
                                <div *ngIf="name.errors.minlength">Name is minimum {{ name.errors.minlength.requiredLength }} character.</div>
                                <div *ngIf="name.errors.maxlength">Name is maximum 50 character.</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="Your email address">
                            <div class="alert alert-danger" *ngIf="email.touched && !email.valid">Email Address is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="Your phone number">
                            <div class="alert alert-danger" *ngIf="number.touched && !number.valid">Phone number is required.</div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="form-group">
                            <select name="subject" id="subject"   class="form-control" required style="opacity: 0.8;" (change)="selectYourType($event.target.value)">
                                <option value="">Choose Your Side</option>
                                <option value="mentor">Mentor</option>
                                <option value="instructor">Instructor</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="Your message..."></textarea>
                            <div class="alert alert-danger" *ngIf="message.touched && !name.valid">Message is required.</div>
                        </div>
                    </div>
                    <div class="try-again-message" *ngIf="timer !== 0">
                        Please wait for <span>{{timer}}</span> sec to make another request!
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn" [disabled]="isButtonDisable === true"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Send Application</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="contact-bg-image"><img alt="image"></div>
</div>
<div class="contact-info-area pt-80 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>
                    <h3>Email Here</h3>
                    <p><a href="mailto:info@algotutor.io">info@algotutor.io</a></p>
                </div>
            </div>
           
            <div class="col-lg-6 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                <div class="contact-info-box mb-30">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>
                    <h3>Call Here</h3>
                    <p><a href="tel:1234567890">+91-7260058093</a></p>
                </div>
            </div>
        </div>
    </div>
</div>
