import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
  enquiry: Enquiry = new Enquiry();
  contactForm: FormGroup;
  utmSource:string;
  timer: number = 0;
  isButtonDisable: boolean = false;
    constructor(private commonservice: CommonServiesService, private notifyService: NotificationService, private route: ActivatedRoute, private fb: FormBuilder
        , private titleService: Title    ) { }

  ngOnInit(): void {
    this.titleService.setTitle('AlgoTutor - Contact Us');
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
   }

   requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID' ) {
      this.notifyService.showError("Please provide valid email address");
      return;
    }else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }else if (this.contactForm.value.linkleurl === undefined || this.contactForm.value.linkleurl === null || this.contactForm.value.linkleurl === '') {
      this.notifyService.showError("Subject is required");
      return;
    } 
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = String(this.contactForm.value.number);
    this.enquiry.CourseId = 0;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 2;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory =this.utmSource === undefined ? 'Contact Us Page' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {
        
      },
      err => {
        console.log(err);
        this.isButtonDisable = false;  this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    // this.router.navigate(['']);
  }

}
