<app-header-style-one></app-header-style-one>
<div class="hire-from-us-area">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                <div class="banner-highlight-text">
                    <label for="">Hire from us</label>
                </div>
                <div class="banner-header">
                    <h4>Hire industry ready engineers trained by top tech professionals</h4>
                </div>
                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Talent pool of industry ready engineers</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Expert in DSA along with latest tech stack</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Tech pool of diverse candidates</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Zero hiring expense</p>
                    </div>

                </div>
                <div class="button-aea">
                    <button class="btn commButtonCss" data-toggle="modal" data-target="#Get_a_callback_popup"><span>Get
                            a callback</span></button>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5 banner-image">

                <img src="../../../../assets/img/hire-from-us/Hire-us 1.png" alt="">

            </div>
        </div>



    </div>
</div>

<div class="telent-pool-div">
    <div class="container">
        <div class="talent-pool-header">
            <h1>Why to choose professionals from</h1>
            <h2><span class="common-header-color">AlgoTutor</span> Talent pool</h2>
        </div>
        <div class="talent-content">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <img src="../../../../assets/img/hire-from-us/why-choose-banner1.png" alt="">
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ">
                    <div class="telant-sub-text">
                        <div>
                            <h6>Expert in problem solving</h6>
                            <p>Master in problem solving using DSA & System Design. Trained by top tech professionals.
                            </p>
                            <div class="banner-sup-text">
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Indepth knowledge of Data Structures & Algorithms</p>
                                </div>
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Well-versed with core concepts of distributed system design</p>
                                </div>
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Strong foundation in computer fundamentals</p>
                                </div>
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Practical knowledge of building real-life scalable systems</p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div class="talent-content">
            <div class="row">

                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ">
                    <div class="telant-sub-text">
                        <div>
                            <h6>Mentored by top tech professionals</h6>
                            <p>Our students are guided by top industry professionals with proper roadmap.</p>
                            <div class="banner-sup-text">
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Roadmap shared by mentor to achieve the dream company</p>
                                </div>
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Consistent feedback sharing to learn and improve</p>
                                </div>
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Good exposure of product development culture</p>
                                </div>
                                <div style="display: flex;">
                                    <i class="fa-solid fa-circle-check"></i>
                                    <p>Trained through many mock interviews and feedback sharing sessions</p>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 right-img">
                    <img src="../../../../assets/img/hire-from-us/hire_developers.png" alt="">
                </div>
            </div>
        </div>
        <div class="talent-content taught-by-experts-div">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                    <img src="../../../../assets/img/hire-from-us/companies_boxes.jpg" alt="">
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 ">
                    <div class="telant-sub-text driven-tallen-content">
                        <div>
                            <h6>Trained by top Instructors</h6>
                            <p>Our trainers are from top product based company like Microsoft, Amazon, Google, JPMorgan,
                                and Goldman Sachs.
                            </p>
                            <div>
                                <div class="banner-sup-text">
                                    <div style="display: flex;">
                                        <i class="fa-solid fa-circle-check"></i>
                                        <p>Well structured curriculum designed by the trainer itself</p>
                                    </div>
                                    <div style="display: flex;">
                                        <i class="fa-solid fa-circle-check"></i>
                                        <p>Dedicated trainer for every module</p>
                                    </div>
                                    <div style="display: flex;">
                                        <i class="fa-solid fa-circle-check"></i>
                                        <p>Exposure of Industry level real life projects</p>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="talent-portal-div">
    <div class="container">
        <div class="talent-portal-header">
            <h2>You can access <span class="common-header-color">AlgoTutor</span> talent pool </h2>
            <h2>with full of diverse candidates </h2>
        </div>
        <div class="talent-portal-content-area">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 dashobard-img">
                    <img src="../../../../assets/img/hire-from-us/login-page-img.jpg" alt="">
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-7 col-xxl-7" style="padding-left: 20px;">
                    <div class="telant-portal-area">
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>Once you are <span class="common-header-color">onboarded</span> you can access our talent
                                portal</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>Filter the candidates based on <span class="common-header-color">mock interview</span>
                                performance</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>Post <span class="common-header-color">jobs</span> with no limit</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>Connect with <span class="common-header-color">learners</span> to check the best fit
                                candidate</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>Schedule <span class="common-header-color">interview</span> and update feedback from
                                portal itself</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p><strong>Interview</strong> the applicants and update status on the portal</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>Send <span class="common-header-color">offer</span> to candidates</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

</div>

<div class="bottom-form-area">
    <div class="container">
        <div class="form-area-content">
            <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5">
                    <div class="best-talent-header">
                        <h2>Hire the top talent Free!</h2>
                        <p>Quick response from our learners with high ratio of joining</p>
                    </div>
                    <div class="how-we-connet">
                        <div style="display: flex;">
                            <img src="../../../../assets/img/hire-from-us/psudo-1.png" alt="">
                            <div>
                                <h2>Fill the details</h2>
                                <p>Our team will review your requirement(s) and get back to you with best talent available
                                    with us.</p>
                            </div>
                        </div>
                        <div style="display: flex;" class="step-2">
                            <img src="../../../../assets/img/hire-from-us/p-sudo2.png" alt="">
                            <div>
                                <h2>Want to join as a Hiring partner?</h2>
                                <p>Our hiring manager will connect with your team to onboard you.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-7 col-xl-7 col-xxl-7">
                    <div class="form-area">
                        <form [formGroup]="contactForm" class="contact-form">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                    <div class="form-group">
                                        <input required minlength="3"  type="text"
                                            class="form-control"  placeholder="Full Name" formControlName="name">
                                      
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                                    <div class="form-group">
                                        <input required  type="number"
                                            class="form-control" placeholder="Phone Number" formControlName="number">
                                        
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <div class="form-group">
                                        <input required type="text"
                                            class="form-control"  placeholder="Email Address" formControlName="email">
                                    
                                    </div>
                                </div>
                              
                                <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                                    <textarea rows="8" placeholder="Requirement / Message"
                                        class="form-control"  type="text"
                                     formControlName="message"></textarea>
                                </div>
                            </div>
                        </form>
                        <div class="try-again-message" *ngIf="timer !== 0">
                            Please wait for <span>{{timer}}</span> sec to make another request!
                        </div>
                        <div class="button-area pt-3" style="float: right;">
                            <button class="btn commButtonCss" [disabled]="isButtonDisable === true" (click)="requestCallBack()"><span>Send Details</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="Get_a_callback_popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h2 style="color:white">Start Hiring the Top Tech Talent with AlgoTutor</h2>
                                <p>Empower your organization with top skilled employees and save hiring cost(s) by connecting with AlgoTutor!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <button type="button" class="close close-modal-button" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="form-content">

                                <h3>Request Callback</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" id="name" type="text" class="form-control" placeholder="Name"
                                            formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="number" class="form-control" placeholder="Phone"
                                            formControlName="number" id="number">
                                    </div>
                                    <div class="form-group">
                                        <input required type="email" class="form-control" placeholder="Email"
                                            formControlName="email" id="email">
                                    </div>
                                   
                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="Message" formControlName="message" id="message">
                                    </div>
                                </form>
                               
                                <button class="btn" (click)="requestCallBack()"><span>Submit</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
