<app-header-style-one></app-header-style-one>
<div class="event-banner-img">
    <img class="view-on-laptop" src="../../../../assets/img/events/notes-banner.jpg" alt="">
    <img class="view-on-mobile" src="../../../../assets/img/notes/banner-mobile.jpg" alt="">
</div>

<div class="container">
    <div class="event-type">
        <div class="event-type-content">
            <label *ngFor="let data of eventTypeTitle"
                [ngStyle]="data.active == true ? {'border-bottom' : '2px solid #1C9AD5'} : {}"
                (click)="getEventCategoryData(data)">{{data.name}}</label>
        </div>
    </div>
   
    <div class="event-category-section">
        <div class="row">
            <div class="col-6  col-sm-6 col-md-4 col-lg-4  col-xl-3  col-xxl-2 custom-column hover-common-card" *ngFor="let data of allEventArr">
                <div class="img-section">
                    <img [src]="data.img" alt="img" style="height: 100%; width: 100%;">
                </div>
                <div class="event-category-content">
                    <div class="course-details-button">
                        <a href="{{data.pdfURL}}" target="_blank"><label><i class="fa-solid fa-eye"></i> View Notes</label></a>
                        <a download href="{{data.pdfURL}}"><label class="dwn-btn"><i class="fa-solid fa-download"></i> Download </label></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
