import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
@Component({
  selector: 'app-apply-for-counselling',
  templateUrl: './apply-for-counselling.component.html',
  styleUrls: ['./apply-for-counselling.component.scss']
})
export class ApplyForCounsellingComponent implements OnInit {
  enquiry: Enquiry = new Enquiry();
  selectedCourseId: number;
  contactForm: FormGroup;
  allCourses = [
    {
      id: 1,
      name: 'Mastering DSA & System Design'
    },
    {
      id: 2,
      name: 'Mastering DSA & System Design'
    },
    {
      id: 3,
      name: 'Mastering DSA & System Design with Full Stack Specialization'
    },
    {
      id: 4,
      name: 'Complete Placement Package for College Students'
    },
    {
      id: 5,
      name: 'Full Stack Web Development - MERN'
    },
    {
      id: 6,
      name: 'Tech Interview Preparation & Mock Interviews'
    },
  ];
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private notifyService: NotificationService, private route: ActivatedRoute,
    private commonservice: CommonServiesService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
  }

  selectCourseName(courseId: number) {
    this.selectedCourseId = courseId;
  }
  requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    } else if (this.selectedCourseId === undefined || this.selectedCourseId === null) {
      this.notifyService.showError("Please select course");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = Number(this.selectedCourseId);
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Course' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => { },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
  }
}
