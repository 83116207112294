<app-header-style-one></app-header-style-one>

<div class="main-banner-div">
    <!-- <div class="banner-img">
        <img src="../../../../assets/img/campus-program/campus-banner-two.jpg" alt="">
    </div>
    
    <div class="container">
        <div class="banner-main-text">
            <h1>Looking for UpSkilling partner to make your students ready for Industry?</h1>
        </div>
        <div class="banner-button-div">
            <button class="btn" data-toggle="modal" data-target="#Get_a_callback_popup"><span>Contact Us</span></button>
        </div>
    </div> -->
    <div class="courses-main-div">
        <div class="container">
            <div class="courses-content-main-div">
                <div class="course-about">
                    <div class="course-title">
                        <h1>Campus Training Programs</h1>
                    </div>

                    <div class="banner-sup-text">
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>We organize on/off campus training programs for students to make them interview ready for top product based companies</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>On campus faculty development programs for adaptation to changing educational trends</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>College ambassador program to develop leadership qualities in student</p>
                        </div>
                        <div style="display: flex;">
                            <i class="fa-solid fa-circle-check"></i>
                            <p>On Campus coding hackathons and pre-placement trainings</p>
                        </div>
                    </div>
                    <div class="information-card">
                        <div class="achived-out-card-image-one">
                            <img src="../../assets/img/icons/div.achieved-outcomes__card-image.png" alt="">
                        </div>
                        <div class="achived-out-card-text-one">
                            <span>Placement Growth</span>
                            <p>On-Campus Placement</p>
                        </div>

                        <div class="achived-out-card-image-two">
                            <img src="../../assets/img/icons/icon-bag.png" alt="">
                        </div>
                        <div class="achived-out-card-text-two">
                            <span>Job Referrals</span>
                            <p>Top Product Companies</p>
                        </div>

                        <div class="achived-out-card-image-four">
                            <img src="../../assets/img/icons/user-withdashboard.png" alt="">
                        </div>
                        <div class="achived-out-card-text-four">
                            <span>Mentors</span>
                            <p>from MAANG</p>
                        </div>
                    </div>
                    <!-- <div class="course-class-mode">
                        <label for="" class="text-change-animation">{{classFeature}}</label>
                    </div> -->
                    <div class="try-again-message" *ngIf="timer !== 0">
                        Please wait for <span>{{timer}}</span> sec to make another request!
                    </div>
                    <div class="button-area">
                        <button type="button" [disabled]="isButtonDisable === true" class="btn course-banner-button" data-toggle="modal" data-target="#Get_a_callback_popup">
                            <span> Apply Now</span></button>
                          
                        <a href="../../../../assets/course-brouchre/Complete Placement Package for Students.pdf" target="_blank"
                            target="_blank"><button type="button" class="btn"><svg xmlns="http://www.w3.org/2000/svg"
                                    width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path
                                        d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                        stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                        stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                </svg><span> Download Brochure</span></button>&nbsp;</a>

                    </div>
                </div>

                <div class="banner-img">
                    <img src="../../../../assets/img/campus-program/banner-right.jpg" alt="">
                </div>

            </div>
        </div>
    </div>

</div>
<!-- <div class="features-main-div">
    <div class="container">
        <app-features-banner-tiles></app-features-banner-tiles>
    </div>
</div> -->


<div class="where-our-alumni-work">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div class="alumni-work-content-text">
                    <h6>How we can help you in upskilling</h6>
                    <h3>On-Campus training for students with top industry trainers</h3>

                </div>
                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Placement oriented structured curriculum and option to customize with college requirement</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>On-Campus live interactive trainings and coding challenges</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Live quiz and attractive AlgoTutor goodies</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Open QnA session with students and carrer guidance</p>
                    </div>

                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <img src="../../../../assets/img/campus-program/student_upskilling.jpg" alt="img">
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <img src="../../../../assets/img/campus-program/fdp_program.jpg" alt="img">
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div class="alumni-work-content-text">
                    <h3>Faculty development programs for adaptation to changing educational trends </h3>

                </div>
                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>On-Campus FDP programs designed to deliver latest technology and innovations</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Live project work with faculty and doubt support</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>AlgoTutor certification to showcase university</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>College curriculum review with our industry experts</p>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div class="alumni-work-content-text">
                    <h3>College ambassador program to develop leadership qualities in student </h3>

                </div>
                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Organize coding hackathons in college with the help of college ambassador</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Feedback session to improve their leadership skills</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Goodies distribution through ambassadors</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Certificate of participation</p>
                    </div>

                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <img src="../../../../assets/img/campus-program/campus-ambassador.jpg" alt="img">
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <img src="../../../../assets/img/campus-program/coding-hackathon.jpg" alt="img">
            </div>
            <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                <div class="alumni-work-content-text">
                    <h3>Pre-placement tests and coding hackathons</h3>

                </div>
                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>On-campus pre-placement training for interview based revision and tips</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Resume preparation and mock interview</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Goodies distribution to hackathons winners</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Internship opportunities to students at AlgoTutor</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>"Ask me anything" event for open questions about placement preparation</p>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>

<div class="container">
    <div class="fAQ-div">
        <div class="FAQ-Header">
            <p>Frequently Asked Questions</p>
        </div>
        <div class="module-name-card">
            <div *ngFor="let item of FNQArr;let i = index;">
                <button class="accordion" (click)="toggleAccordian($event, i,item)">
                    {{item.qName}}</button>

                <div class="panel">
                    <div class="module-description-main-div">
                        <div class="about-module">
                            <p>{{item.qAns}}</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="Get_a_callback_popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h2 style="color:white">Start Better Learning Future From Here</h2>
                                <p>Empower your students with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <button type="button" class="close close-modal-button" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="form-content">

                                <h3>Request Callback</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="email" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                    </div>
                                </form>

                                <button class="btn" (click)="requestCallBack()"><span>Get in
                                        Touch</span></button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
