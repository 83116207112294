export interface Enquiry {
    EnquiryId?:number;
    Name?:string;
    Email?:string;
    Mobile?:string;
    CourseId?:number;
    LinkedInProfile: string;
    StatusId?:number;
    IsReferal?:number;
    EnquirySourceId?: number;
    ReferalUserId?: number;
    Remarks?: string;
    IsActive?: number;
    EnquiryCategory?: string;
}
export class Enquiry {
    EnquiryId?:number;
    Name?:string;
    Email?:string;
    Mobile?:string;
    CourseId?:number;
    LinkedInProfile: string;
    StatusId?:number;
    IsReferal?:number;
    EnquirySourceId?: number;
    ReferalUserId?: number;
    Remarks?: string;
    IsActive?: number;
    EnquiryCategory?: string;
}