import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-advanced-data-science-ai',
  templateUrl: './advanced-data-science-ai.component.html',
  styleUrls: ['./advanced-data-science-ai.component.scss']
})
export class AdvancedDataScienceAiComponent implements OnInit {
  // course_date_day = environment.course_date_day;
  course_details = environment.course_date_advanced_data_science_and_AI;
  course_date_month = environment.course_date_month;
  enquiry: Enquiry = new Enquiry();
  moduleQuestion: any[] = [];
  filteredDataFromModule: { Id: number; actve: boolean }[];
  contactForm: FormGroup;
  course_class_mode_text = [
    ' Live Interactive Classes',
    'Personalized Mentorship',
    'Unlimited Mock Interviews',
    'Lifetime Content Access',
    'Guaranteed Job Referrals',
    'Unlimited Doubt Support'
  ]
  currentTextIndex: number = 0;
  classFeature: string = this.course_class_mode_text[this.currentTextIndex];
  feedbackSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: false,
    autoplayHoverPause: false,
    autoplay: false,
    mouseDrag: false,
    items: 2,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 2
      }
    }
  }
  courseCASEstudyHLD = [
    {
      title: 'Design URL Shortener Service',
      text: `“A URL shortener service creates a short url against a long url. Moreover, when we click on the short/tiny url, it get redirected to the actual url”`,
      Img: '/assets/img/courses/courses_custom/short-url.jpg',
    },
    {
      title: 'Design OTT Platform Like Netflix',
      text: `"Here we will learn the architecture of video streaming platform and how to create an on-demand video streaming app like Netflix”`,
      Img: '/assets/img/courses/courses_custom/netflix.jpg',
    },
    {
      title: 'Design Social Media News Feed',
      text: `"Here we will learn how to design Facebook/Insta’s news feed kind of service, which notify updates from all the users”`,
      Img: '/assets/img/courses/courses_custom/facebook-insta-news-feed.jpg',
    },
    {
      title: 'Design Cab Booking System Like UBER',
      text: `“Here we will learn the architecture of cab booking system and how to design a system like UBER ”`,
      Img: '/assets/img/courses/courses_custom/car-booking.jpg',
    },
    {
      title: 'Design Messaging System Like Whatsapp',
      text: `"Here we will learn the architecture of real time messaging/chat system like Whatsapp”`,
      Img: '/assets/img/courses/courses_custom/whatsapp.jpg',
    }
  ];
  courseCASEstudyLLD = [
    {
      title: 'Design Parking Lot',
      text: `“Here we will learn how to gather requirement for a parking lot kind of system and also complete design of low level code”`,
      Img: '/assets/img/courses/courses_custom/parking-lot.jpg',
    },
    {
      title: 'Design Movie Ticket Booking App',
      text: `"Here we will learn how to design the architecture of a movie booking kind of system and its low level code using OOD principle”`,
      Img: '/assets/img/courses/courses_custom/Moview Ticket-Booking-System.jpg',
    },
    {
      title: 'Design Tic-Tac-Toe Game',
      text: `Here we will deep dive into low level design of a gaming app like Tic-tac-toe with complete class design with implementation”`,
      Img: '/assets/img/courses/courses_custom/tic-tac-toe.jpg',
    },
    {
      title: 'Design a Logging Service',
      text: `"Logging is essential in understanding the event flow in a distributed system. Here we will learn about distributed logging with implementation.”`,
      Img: '/assets/img/courses/courses_custom/logging-service.jpg',
    },
    {
      title: 'Design a Caching Service',
      text: `"Here we will learn about importance/need of cache in a system and different caching techniques with implementation.”`,
      Img: '/assets/img/courses/courses_custom/caching-service.jpg',
    },
    {
      title: 'Design Expense Sharing app like Splitwise',
      text: `"Here we will learn the architecture of splitwise and its low level design with implementation.”`,
      Img: '/assets/img/courses/courses_custom/splitwise-app.jpg',
    }
  ];
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
    private notifyService: NotificationService, private fb: FormBuilder,
      private modalService: ModalService,
      private metaService: Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.updateMetaTags('Mastering Advance Data Science & Generative AI', 'Discover the world of Data Science and Generative AI in this transformative course. Develop crucial skills for data-driven decision-making and delve into the dynamic world of Generative Artificial Intelligence.');
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
    setInterval(() => {
      this.currentTextIndex = (this.currentTextIndex + 1) % this.course_class_mode_text.length;
      this.classFeature = this.course_class_mode_text[this.currentTextIndex];
    }, 3000);
  }

  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }

  moduleArray = [
    {
      id: 1,
      active: false,
      courseTopic: 'Foundations of Data Science',
      timeTaking: '2 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Establish a robust data-driven groundwork with the first module, Foundations of Data Science.',
          moduleContant: [
            'Programming for Data Science with Python',
            'Data types, control flow, functions, libraries (NumPy, Pandas)',
            'Version control with Git and GitHub',
            'Introduction to Jupyter Notebooks',
            'Data Acquisition and Preparation',
            'Web scraping with BeautifulSoup',
            'APIs and data access methods',
            'Introduction to databases and SQL',
            'Data cleaning and manipulation with Pandas'
          ],
          project: [
            {
              projectName: '',
              title: '',
              pType: [
                'Create a data pipeline to collect and clean data from a public API, and perform basic analysis.'
              ]
            }
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 2,
      active: false,
      courseTopic: 'Statistical Thinking and Descriptive Analysis',
      timeTaking: '3 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Navigate the realm of Statistical Thinking and Descriptive Analysis, gaining insights into data through analytical techniques and methodologies in our comprehensive course module.',
          moduleContant: [
            'Descriptive Statistics:',
            'Measures of central tendency (mean, median, mode)',
            'Measures of dispersion (range, variance, standard deviation)',
            'Data distributions (normal, binomial, Poisson)',
            'Inferential Statistics:',
            'Hypothesis testing: null and alternative hypotheses, t-tests, ANOVA, p-values',
            'Confidence intervals and prediction intervals',
            'Exploratory Data Analysis:',
            'Visualization techniques with Matplotlib and Seaborn',
            'Correlation analysis and identifying relationships',
            'Dimensionality reduction with PCA'
          ],
          project: [
            {
              projectName: '',
              title: '',
              pType: [
                'Explore a public health dataset (e.g., COVID-19 cases) to analyze infection rates, demographics, and correlations between variables. Create interactive visualizations and dashboards to track trends and inform public health strategies.'
              ]
            }

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 3,
      active: false,
      courseTopic: 'Machine Learning Fundamentals',
      timeTaking: '4 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Dive into the core principles of Machine Learning with our module, exploring fundamental algorithms and techniques to empower your understanding and application of this transformative field.',
          moduleContant: [
            'Supervised Learning',
            'Linear regression for continuous target variables',
            'Logistic regression for binary classification problems',
            'Supervised Learning (continued)',
            'Decision trees and ensemble methods (Random Forest) for classification',
            'K-Nearest Neighbors for classification and regression',
            'Unsupervised Learning',
            'K-means clustering for identifying groups within data',
            'DBSCAN for identifying clusters of arbitrary shapes',
            'Model Evaluation and Selection:',
            'Metrics for evaluating model performance (accuracy, precision, recall)',
            'Cross-validation to prevent overfitting',
            'Hyperparameter tuning to optimize model performance'
          ],
          project: [
            {
              projectName: '',
              title: '',
              pType: [
                'Build a spam classifier using email data to filter out unwanted messages. Evaluate different algorithms and optimize performance. Deploy your model as a web application for real-time spam detection.'
              ]
            }

          ],

          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 4,
      active: false,
      courseTopic: ' Advanced Data Science Techniques',
      timeTaking: '4 week',
      childProperties: [
        {
          id: 1,
          module_description: `Explore the forefront of data science with the Advanced Data Science Techniques module, encompassing advanced methods, NLP, and Deep Learning strategies to tackle complex challenges and extract profound insights from diverse datasets.`,
          moduleContant: [
            'Deep Learning',
            'Introduction to neural networks for complex problems',
            'Convolutional neural networks for image recognition',
            'Recurrent neural networks for text analysis and time series forecasting',
            'Big Data Technologies',
            'Introduction to Big Data challenges and solutions',
            'Hadoop and Spark for distributed data processing',
            'Natural Language Processing (NLP)',
            'Text pre-processing and cleaning',
            'Sentiment analysis to understand opinions and emotions',
            'Text classification for categorizing documents',
            'Time Series Analysis',
            'Forecasting future trends based on historical data',
            'ARIMA models for stationary time series',
            'Prophet for forecasting general time series',
          ],
          project: [
            {
              projectName: '',
              title: '',
              pType: [
                'Develop a sentiment analysis model to analyze customer reviews for a specific product or service. Identify key areas for improvement and recommend strategies to enhance customer satisfaction.'
              ]
            }
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
    {
      id: 5,
      active: false,
      courseTopic: 'LLMs and the Language Frontier',
      timeTaking: '4-6 weeks',
      childProperties: [
        {
          id: 1,
          module_description: `Embark on an exploration of Language Models and the Language Frontier in our fourth module, delving into the cutting-edge realms of NLP and Deep Learning to master language processing techniques and advance your data science skills.`,
          moduleContant: [
            'Explain the architecture, training process, and capabilities of LLMs like GPT-3',
            'Discuss their potential for text generation, translation, and other language-related tasks',
            'Explore real-world applications of LLMs in various fields like healthcare, finance, and creative industries',
            'Analyze case studies to understand their impact and implications',
            'Introduction to LangChain/LLamaIndex, a framework for building LLM-based applications',
            'Explore its components: Models, Prompts, Indexes, Chains, Memory, and Agents',
            'Discuss its advantages and use cases',
            'Discuss bias, fairness, transparency, and accountability in AI development and applications',
            'Raise awareness of potential risks and responsible AI practices'
          ],
          project: [

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
    {
      id: 6,
      active: false,
      courseTopic: 'Building Your Own GPT/LLM model',
      timeTaking: '7-11 weeks',
      childProperties: [
        {
          id: 1,
          module_description: `Empower yourself in the art of AI innovation with 'Building Your Own GPT/LLM Model' module, guiding you through the process of constructing and customizing your language model for a personalized and impactful data science journey.`,
          moduleContant: [
            'Hands-on practice with prompt engineering techniques',
            'Experiment with different prompt formats and strategies for various tasks',
            'Explore techniques for semantic search using generative AI models',
            'Possible project: Build a semantic search engine for a specific domain'
          ],
          project: [
            {
              projectName: 'Project 1',
              title: 'Text Generation Playground',
              pType: [
                'Train a smaller language model on diverse text datasets (e.g., poems, emails, code)',
                'Experiment with generating different text formats and creative content'
              ]
            },
            {
              projectName: 'Project 2',
              title: 'Fine-tuning OpenAI Models',
              pType: [
                'Use OpenAI API to fine-tune GPT-3 for specific tasks (e.g., code generation, dialogue bots)',
                'Explore prompts, parameters, and customization options'
              ]
            },
            {
              projectName: 'Project 3',
              title: 'Building a Mini-GPT and Creating an End-to-End App with Streamlit',
              pType: [
                'Implement a simplified version of the GPT architecture from scratch using libraries like PyTorch',
                'Gain deeper understanding of model components and training process',
                `Demonstrate how to create an interactive web app using Streamlit to showcase the model's capabilities`
              ]
            },

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
    {
      id: 7,
      active: false,
      courseTopic: 'Advanced Projects in Generative AI',
      timeTaking: '11-15 weeks',
      childProperties: [
        {
          id: 1,
          module_description: `Elevate your expertise with 'Advanced Projects in Generative AI,' immersing yourself in high-level applications and innovative projects that push the boundaries of generative artificial intelligence.`,
          moduleContant: [
            'Hands-on practice with prompt engineering techniques',
            'Experiment with different prompt formats and strategies for various tasks',
            'Explore techniques for semantic search using generative AI models',
            'Possible project: Build a semantic search engine for a specific domain'
          ],
          project: [
            {
              projectName: 'Project 4',
              title: 'AI-Powered Music Composition',
              pType: [
                'Use libraries like Magenta and MuseNet to generate melodies, harmonies, and rhythms',
                'Explore different genres and styles, and create unique musical pieces'
              ]
            },
            {
              projectName: 'Project 5',
              title: ' Image Generation with Style Transfer',
              pType: [
                'Apply neural style transfer techniques to combine content and style from different images',
                'Generate unique visual content for art, design, or marketing purposes'
              ]
            },
            {
              projectName: 'Project 6',
              title: ' Interactive Storytelling with GPT',
              pType: [
                `Build text-based adventure games or interactive fiction using GPT's text generation`,
                'Create immersive storytelling experiences with user choices and branching narratives',

              ]
            },

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    }
  ]
  eachModuleDataArr = [
    {
      Id: 1,
      actve: false,
    },
    {
      Id: 2,
      actve: false,
    },
    {
      Id: 3,
      actve: false,
    },
    {
      Id: 4,
      actve: false,
    },

    {
      Id: 5,
      actve: false,
    },
  ]
  getModuleQuestion(selectedData: any) {
    this.filteredDataFromModule = this.eachModuleDataArr.filter(qId => qId.Id == selectedData.id);
    console.log(this.filteredDataFromModule)
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
    this.moduleQuestion.push(this.eachModuleDataArr);
  }

  toggleAccordian(event, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }

    const panel = element.nextElementSibling;
    if (panel != null) {
      if (panel?.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
  toggleCourseTitle(selectedData, index) {
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
  }
  requestCallBackEnquiry() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = 1;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Advanced data science & AI - Course Page' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => { },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    //this.toClearFormValue(this.contactForm.value);
  }

  toClearFormValue(formValue) {
    formValue.name = '';
  }


  downloadBroucher() {
    this.modalService.openModal();
  }
}
