import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {
  
  page = 1;
  eventTypeTitle = [
    { id: 1, active: true, name: 'All' },
    { id: 2, active: false, name: 'Data Structures & Algorithms' },
    { id: 3, active: false, name: 'System Design' },
    /*{ id: 4, active: false, name: 'Project Idea' },*/
    { id: 5, active: false, name: 'SQL' },
    { id: 6, active: false, name: 'Java' },
    { id: 7, active: false, name: 'Python' },

    { id: 8, active: false, name: 'BI' },
    { id: 9, active: false, name: 'React' },
    { id: 10, active: false, name: 'Deep Learning' },
    { id: 11, active: false, name: 'AI' },
    { id: 12, active: false, name: 'Machine Learning' },
    { id: 13, active: false, name: 'Interview Preparation' },
    { id: 14, active: false, name: 'Computer Fundamentals' },
    { id: 15, active: false, name: 'Tableau' },
    { id: 16, active: false, name: 'Tips and Tricks' },

  ];
  allEventArr = [];
  DS_Algorithms: any[] = [];
  systemDesignArr: any[] = [];
  projectIdea: any[] = [];
  SqlArr: any[] = [];
  javaArr: any[] = [];
  pythonArr: any[] = [];
  BI: any[] = [];
  react: any[] = [];
  deepLearning: any[] = [];
  AI: any[] = [];
  machineLearning: any[] = [];
  interviewPrepration: any[] = [];
  computerFundamental: any[] = [];
  Tableau: any[] = [];
  tipsAndTricks: any[] = [];
    constructor(private metaService: Meta, private titleService: Title) { }

    ngOnInit(): void {
    this.updateMetaTags('Interview Preparation Notes', 'Well structured interview preparation notes designed by top industry experts to help you in interview preparation and the best part is its totally Free!');
    this.DS_Algorithms = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/15 Important Algorithm.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/15 Important Algorithm.pdf'
      },
      {
        id: 2,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/20 Coding Patterns.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/20 Coding Patterns to master Tech in Interviews.pdf'

      },
      {
        id: 3,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/25 Most Frequently Asked DSA Questions.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/25 Most Frequently Asked DSA Questions in MAANG.pdf'

      },
      {
        id: 4,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/50 Most Frequently Asked DSA Questions in MAANG.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/50 Most Frequently Asked DSA Questions in MAANG.pdf'

      },
      {
        id: 5,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/75 Most Important LeetCode DSA Questions.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/75 Most Important LeetCode DSA Questions.pdf'

      },
      {
        id: 6,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/Blind 75 LeetCode Questions.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/Blind 75 LeetCode Questions.pdf'

      },
      {
        id: 8,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/AlgoTutor’s-75-Days-Coding-Challenge.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/AlgoTutor’s 75 Days Coding Challenge.pdf'

      },
      {
        id: 9,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/zero-to-hero-in-dsa-just-in-75-days.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/Zero To Hero in DSA 75 Days Coding Challenge.pdf'

      },
      {
        id: 10,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/SDE Interview Sheet.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/AlgoTutor_s DSA Sheet.pdf'
      },
      {
        id: 11,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/21-day plan to prepare for Data Structures and Algorithms (DSA).jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/21-day plan to prepare for Data Structures and Algorithms (DSA).pdf'
      },
      {
        id: 13,
        isActive: true,
        img: '/assets/img/notes/dsAndAlgo/img/AlgoTutor_s DSA Sheet_removed.jpg',
        pdfURL: '/assets/img/notes/dsAndAlgo/pdf/AlgoTutor_s DSA Sheet_removed.pdf'
      },
      {
        id: 14,
        isActive: true,
        img: '/assets/img/notes/stack-and-que/Mastering stacks and queues in 5 days.jpg',
        pdfURL: '/assets/img/notes/stack-and-que/Mastering stacks and queues in 5 days.pdf'
      },
      {
        id: 15,
        isActive: true,
        img: '/assets/img/notes/dynamic-programming/Mastering Dynamic Programming (DP) in 10 days.jpg',
        pdfURL: '/assets/img/notes/dynamic-programming/Mastering Dynamic Programming (DP) in 10 days (1).pdf'
      },
      {
        id: 16,
        isActive: true,
        img: '/assets/img/notes/link-list/Mastering linked list in 5 days.jpg',
        pdfURL: '/assets/img/notes/link-list/Mastering linked list in 5 days.pdf'
      },
      {
        id: 17,
        isActive: true,
        img: '/assets/img/notes/string/img/Mastering-strings-in-5-days.jpg',
        pdfURL: '/assets/img/notes/string/notes/Mastering strings in 5 days.pdf'
      },
      {
        id: 19,
        isActive: true,
        img: '/assets/img/notes/graph/img/master-in-graph-in-10-days.jpg',
        pdfURL: '/assets/img/notes/graph/notes/Mastering graphs in 10 days.pdf'
      },
      {
        id: 20,
        isActive: true,
          img: '/assets/img/notes/arrayNotes/image/Array-InterviewPrep-Notes.jpg',
          pdfURL: '/assets/img/notes/arrayNotes/notes/Array-InterviewPrep-Notes.pdf'
      },
      {
        id: 21,
        isActive: true,
        img: '/assets/img/notes/arrayNotes/image/Mastering-arrays-in-10-days.jpg',
        pdfURL: '/assets/img/notes/arrayNotes/notes/Mastering arrays in 10 days.pdf'
      },
      {
        id: 18,
        isActive: true,
        img: '/assets/img/notes/string/img/String_Details_Notes.jpg',
        pdfURL: '/assets/img/notes/string/notes/String_Details_Notes.pdf'
      }
    ];
    this.BI = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/PowerBI/power-bi-important-interview-questions.jpg',
        pdfURL: '/assets/img/notes/PowerBI/25 important interview question of power BI.pdf'
      }
    ];
    this.react = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/react/react important question.jpg',
        pdfURL: '/assets/img/notes/react/React Important Questions.pdf'

      },
      {
        id: 2,
        isActive: true,
        img: '/assets/img/notes/react/React-50-Important-Questions.jpg',
        pdfURL: '/assets/img/notes/react/React 50 Important Questions.pdf'

      },
      {
        id: 3,
        isActive: true,
        img: '/assets/img/notes/react/Practice React in 25 Days.jpg',
        pdfURL: '/assets/img/notes/react/Practice React in 25 Days.pdf'

      }
    ];
    this.pythonArr = [
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/python/25-important-python-questions.jpg',
        pdfURL: '/assets/img/notes/python/Python Important Interview Questions.pdf'
      },
      {
        id: 2, isActive: true,
        img: '/assets/img/notes/python/Learn 10 Most Used Python Libraries in Data Science.jpg',
        pdfURL: '/assets/img/notes/python/Learn 10 Most Used Python Libraries in Data Science.pdf'
      },
      {
        id: 3, isActive: true,
        img: '/assets/img/notes/python/Mastering Python in 15 days.jpg',
        pdfURL: '/assets/img/notes/python/Mastering Python in 15 days.pdf'
      },
    ];

    this.systemDesignArr = [
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/systemDesign/img/System Design Important Concepts.jpg',
        pdfURL: '/assets/img/notes/systemDesign/pdf/System Design Important Concepts.pdf'
      },
      {
        id: 2, isActive: true,
        img: '/assets/img/notes/systemDesign/img/Mastering-system-design-in-21-days.jpg',
        pdfURL: '/assets/img/notes/systemDesign/pdf/Mastering system design in 21 days.pdf'
      },
      {
        id: 3,
        isActive: true,
        img: '/assets/img/notes/systemDesign/img/AlgoTutor_s API CheatSheet.jpg',
        pdfURL: '/assets/img/notes//systemDesign/pdf/AlgoTutor_s API CheatSheet.pdf'
      },
    ];

    this.projectIdea = [
      {
        //id: 2, isActive: true,
        //img: '/assets/img/notes/DataScience/img/25-important-data-science-question.jpg',
        //pdfURL: '/assets/img/notes/DataScience/pdf/25 Important Data Science Interview Questions.pdf'
      },
    ];

    this.SqlArr = [
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/SQl/img/25-days-SQL-Challenge.jpg',
        pdfURL: '/assets/img/notes/SQl/pdf/25 days SQL Challenge.pdf'
      },
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/SQl/img/25-days-SQL-RoadMap.jpg',
        pdfURL: '/assets/img/notes/SQl/pdf/25 days SQL RoadMap.pdf'
      },
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/SQl/img/SQL-Quick-Revision-Notes-For-InterView.jpg',
        pdfURL: '/assets/img/notes/SQl/pdf/SQL Quick Revision Notes For InterView.pdf'
      },
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/SQl/img/SQL-Top-50-Important-Question-Answer.jpg',
        pdfURL: '/assets/img/notes/SQl/pdf/SQL Top 50 Important Question-Answer.pdf'
      },

    ];

    this.javaArr = [
      {
        id: 1, isActive: true,
        img: '/assets/img/notes/java/img/JavaTechnicalQuestions.jpg',
        pdfURL: '/assets/img/notes/java/pdf/Java Technical Interview Questions & Answer.pdf'
      },
    ];


    this.deepLearning = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/deep-learning/Deep Learning RoadMap For 20 Days.jpg',
        pdfURL: '/assets/img/notes/deep-learning/Deep Learning RoadMap For 20 Days.pdf'
      },

    ];

    this.AI = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/AI/Generative AI RoadMap For 30 Days.jpg',
        pdfURL: '/assets/img/notes/AI/Generative AI RoadMap For 30 Days.pdf'
      },
    ];
    this.machineLearning = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/machine-learning/Machine Learning RoadMap For 30 Days.jpg',
        pdfURL: '/assets/img/notes/machine-learning/Machine Learning RoadMap For 30 Days.pdf'
      },
    ];


    this.interviewPrepration = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/microsoft-interview-question/Microsoft Interview Prepration In Just 30 Days.jpg',
        pdfURL: '/assets/img/notes/microsoft-interview-question/Microsoft Interview Prepration In Just 30 Days.pdf'
      },
      {
        id: 2,
        isActive: true,
        img: '/assets/img/notes/frontend-roadmap/Frontend Development Roadmap.jpg',
        pdfURL: '/assets/img/notes/frontend-roadmap/Frontend Development Roadmap.pdf'
      },
    ];
    this.computerFundamental = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/oops/Mastering Object-Oriented Programming (OOP) in 15 days.jpg',
        pdfURL: '/assets/img/notes/oops/Mastering Object-Oriented Programming (OOP) in 15 days.pdf'
      },
      {
        id: 2,
        isActive: true,
        img: '/assets/img/notes/oops/OOPS Important Interview Questions.jpg',
        pdfURL: '/assets/img/notes/oops/OOPS Important Interview Questions.pdf'
      },
      {
        id: 3,
        isActive: true,
        img: '/assets/img/notes/OS/Operating System Interview Question.jpg',
        pdfURL: '/assets/img/notes/OS/Operating System Interview Question.pdf'
      },
      {
        id: 4, isActive: true,
        img: '/assets/img/notes/dbms/20-important-dbms-question.jpg',
        pdfURL: '/assets/img/notes/dbms/DBMS Questions .pdf'
      },
    ];

    this.Tableau = [
      {
        id: 1,
        isActive: true,
            img: '/assets/img/notes/tableau/Practice Tablue in 25 Days.jpg',
            pdfURL: '/assets/img/notes/tableau/Practice Tablue in 25 Days.pdf'
      }
    ];
    this.tipsAndTricks = [
      {
        id: 1,
        isActive: true,
        img: '/assets/img/notes/tips-and-tricks/Tips and Tricks for Resume Preparation_2.jpg',
        pdfURL: '/assets/img/notes/tips-and-tricks/Tips and Tricks for Resume Preparation_2.pdf'
      }
    ];
    this.allEventArr = [...this.DS_Algorithms, ...this.systemDesignArr,
    ...this.SqlArr, ...this.javaArr, ...this.pythonArr, ...this.BI,
    ...this.react,
    ...this.deepLearning, ...this.AI, ...this.machineLearning,
    ...this.interviewPrepration,
    ...this.computerFundamental, ...this.Tableau, ...this.tipsAndTricks];
    }
  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }
  getEventCategoryData(item: any) {
    this.allEventArr = [];
    this.eventTypeTitle.forEach((data) => {
      if (data.id == item.id) {
        data.active = true;
      } else {
        data.active = false;
      }
    });
    switch (item.id) {
      case 1:
        this.allEventArr = [...this.DS_Algorithms, ...this.systemDesignArr,
        ...this.SqlArr, ...this.javaArr, ...this.pythonArr,
        ...this.BI, ...this.react,
        ...this.deepLearning, ...this.AI, ...this.machineLearning,
        ...this.interviewPrepration,
        ...this.computerFundamental, ...this.Tableau, ...this.tipsAndTricks];
        break;
      case 2:
        this.allEventArr = this.DS_Algorithms;
        break;
      case 3:
        this.allEventArr = this.systemDesignArr;
        break;      
      case 5:
        this.allEventArr = this.SqlArr;
        break;
      case 6:
        this.allEventArr = this.javaArr;
        break;
      case 7:
        this.allEventArr = this.pythonArr;
        break;
      case 8:
        this.allEventArr = this.BI;
        break;
      case 9:
        this.allEventArr = this.react;
        break;
      case 10:
        this.allEventArr = this.deepLearning;
        break;

      case 11:
        this.allEventArr = this.AI;
        break;
      case 12:
        this.allEventArr = this.machineLearning;
        break;

      case 13:
        this.allEventArr = this.interviewPrepration;
        break;
      case 14:
        this.allEventArr = this.computerFundamental;
        break;

      case 15:
        this.allEventArr = this.Tableau;
        break;
      case 16:
        this.allEventArr = this.tipsAndTricks;
        break;
    }
  }
}
