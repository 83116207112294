import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-events-page',
  templateUrl: './events-page.component.html',
  styleUrls: ['./events-page.component.scss']
})
export class EventsPageComponent implements OnInit {

  page = 1;
  eventTypeTitle = [
    { id: 1, active: true, name: 'All' },


  ];
  allEventArr = [];
  app_DevelopmentgArr = [
    {
      id: 1, isActive: true,
      image: '/assets/img/events/santosh mishra.jpg',
      iseventclosed: false,
      takenBY: 'Santosh Mishra, SDE @ Microsoft',
      linkedin: 'https://www.linkedin.com/in/iamsantoshmishra/',
      start_date: '12:00PM to 02:00 PM, 25th Mar',
      eventType: 'Software Engineering Roadmap for College Students',
      Desc: 'Learn the complete roadmap to be a successful software engineer in a product based company',
      event_highlight_btn: [
        'Event One', 'Event Two', 'Event Three'
      ],
      message: '4k People Enrolled',
      registrationlink: ''
    },
    {
      id: 2, isActive: false, image: '/assets/img/events/himanshu gupta.jpg',
      iseventclosed: false,
      takenBY: 'Himanshu Gupta, SDE @ Microsoft',
      start_date: '10:00AM to 12:00 PM, 22nd Apr',
      eventType: 'KAFKA UNVEILED: A Deep Dive Into Real-Time Data Processing',
      Desc: 'Introduction to Apache Kafka as a distributed event streaming platform and the importance of real-time data processing in modern applications',
      linkedin: 'https://www.linkedin.com/in/himanshug12/',
      event_highlight_btn: [
        'Event One', 'Event Two', 'Event Three'
      ],
      message: '4k People Enrolled',
      registrationlink: ''
    },
    {
      iseventclosed: false,
      id: 3, isActive: false, image: '/assets/img/events/abhishek sharma.jpg',
      takenBY: 'Abhishek Sharma, SDE @ Google',
      linkedin: 'https://www.linkedin.com/in/a6h1sh3k/',
      start_date: '10:00AM to 12:00 PM, 13th May',
      eventType: 'How to Crack SDE Interviews at MAANG+',
      Desc: 'Get a complete idea of the roadmap to clear software engineer interviews at MAANG companies',
      event_highlight_btn: [
        'Event One', 'Event Two'
      ],

      message: '4k People Enrolled',
      registrationlink: ''
    },
    {
      iseventclosed: false,
      id: 4, isActive: false, image: '/assets/img/events/sachin sharma.jpg',
      takenBY: 'Sachin Sharma, SDE @ Microsoft',
      linkedin: 'https://www.linkedin.com/in/sachinsharma1057/',
      start_date: '11:00AM to 01:00 PM, 17th June',
      eventType: 'Master Backtracking: From Basics to Advance',
      Desc: 'Learn the beauty of Backtracking and the appropriate place to apply the technique',
      event_highlight_btn: [
        'Event One', 'Event Two'
      ],

      message: '4k People Enrolled',
      registrationlink: ''
    },
    {
      iseventclosed: false,
      id: 5, isActive: false, image: '/assets/img/events/amit kumar.jpg',
      takenBY: 'Amit Kumar, SDE @ Microsoft',
      linkedin: 'https://www.linkedin.com/in/amit-kumar-3059ba185/',
      start_date: '10:00AM to 12:00 PM, 22nd July',
      eventType: 'Master the Art of Recursion',
      Desc: 'Get the complete idea of recursion and how recursion works internally.',
      event_highlight_btn: [
        'Event One', 'Event Two'
      ],

      message: '4k People Enrolled',
      registrationlink: ''
    },
    {
      iseventclosed: false,
      id: 6, isActive: false, image: '/assets/img/events/prem.jpg',
      takenBY: 'Prem Kumar, SDE @ Google',
      linkedin: 'https://www.linkedin.com/in/prekum/',
      start_date: '10:00AM to 12:00 PM, 12th Aug',
      eventType: 'How to Prepare for Google Interview',
      Desc: 'Complete roadmap to prepare for Google interview',
      event_highlight_btn: [
        'Event One', 'Event Two'
      ],

      message: '4k People Enrolled',
      registrationlink: ''
    },
  ]


  seeAll = [];
  enquiry: Enquiry = new Enquiry();
  selectedCourseId: number;
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
    private notifyService: NotificationService) { }

  ngOnInit(): void {
    this.allEventArr = this.app_DevelopmentgArr;
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
  }
  getEventCategoryData(item: any) {
    this.allEventArr = [];
    this.eventTypeTitle.forEach((data) => {
      if (data.id == item.id) {
        data.active = true;
      } else {
        data.active = false;
      }
    })
    switch (item.id) {
      case 1:
        this.allEventArr = this.app_DevelopmentgArr;
        break;
      // case 2:
      //   this.allEventArr = this.data_science;
      //   break;
      // case 3:
      //   this.allEventArr = this.artificialIntelligenceArr;
      //   break;
      case 4:
        this.allEventArr = this.seeAll;
        break;
    }
  }
  selectCourseId(courseId) {
    this.selectedCourseId = parseInt(courseId);
  }
  requestCallBack(f: NgForm) {
    if (f.value.name === undefined || f.value.name === null || f.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (f.value.email === undefined || f.value.email === null || f.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (f.value.number === undefined || f.value.number === null || f.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = f.value.name;
    this.enquiry.Email = f.value.email;
    this.enquiry.Mobile = f.value.number;
    this.enquiry.CourseId = this.selectedCourseId;
    this.enquiry.LinkedInProfile = f.value.message;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Master Class' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {
      },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Registered successfully! You will receive the details on your registered email.");

  }
}
