import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
declare var jQuery: any;
import { ModalService } from 'src/app/services/modal.service';
import { AlumniPopupService } from 'src/app/services/alumni-popup.service';
@Component({
	selector: 'app-feedback-style-two',
	templateUrl: './feedback-style-two.component.html',
	styleUrls: ['./feedback-style-two.component.scss']
})



export class FeedbackStyleTwoComponent implements OnInit {
	carouselOptions: OwlOptions = {
		loop: true,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: true,
		dots: false,
		navSpeed: 400,
		nav: true,
		center: false,
		items: 3,
		margin: 10,
		navText: [
			"<i class='bx bx-left-arrow-alt'></i>",
			"<i class='bx bx-right-arrow-alt'></i>"
		],
		responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 2
			},

			1024: {
				items: 2
			},

			1100: {
				items: 3
			},


			2000: {
				items: 4
			}
		}
	};

	ourAlumniArr = [
		
		{
			userImg: 'assets/img/placement/pragati.jpeg',
			name: 'Pragati Mishra',
			profession: 'Senior Associate',
			linkDin: 'https://www.linkedin.com/in/pragatimishra20/',
			beforeAlgoCompanyLogo: '../../../../assets/img/tcs.png',
			hike: 350,
			alumniCall: true,
			afterAlgoCompanyLogo: '../../../../assets/img/Morgan-Stanley-Logo.jpg',
			detail: 'My mentor at AlgoTutor taught me that consistency is the most important quality. He typically encourages me to be consistent in class and complete assignments.'

		},
		{
			userImg: '../../../../assets/img/saloni-gupta.jpg',
			name: 'Saloni Gupta',
			profession: 'Software Engineer II',
			linkDin: 'https://www.linkedin.com/in/saloni-gupta-18293a231/',
			hike: 140,
			alumniCall: true,
			beforeAlgoCompanyLogo: '../../../../assets/img/conViva.png',
			afterAlgoCompanyLogo: '../../../../assets/img/jpMorgan.png',
			detail: 'Small batch size was the best thing for AlgoTutor. It was kind of one-on-one conversation with the instructor. AlgoTutor is highly recommended from all angles.'
		},
		{
			userImg: 'assets/img/placement/hirdesh.jpeg',
			name: 'Hirdesh Shriwastav',
			linkDin: 'https://www.linkedin.com/in/hirdesh-shriwastav007',
			beforeAlgoCompanyLogo: '../../../../assets/img/oracal.jpeg',
			hike: 160,
			alumniCall: false,
			profession: 'Software Engineer II',
			afterAlgoCompanyLogo: '../../../../assets/img/microSoft.jpg',
			detail: 'If you are interested in pursuing Tech-related courses and are passionate about them, AlgoTutor is the ideal platform to acquire the necessary tech skills.'
		},
		{
			userImg: 'https://media.licdn.com/dms/image/D4E03AQG870d50qIcxA/profile-displayphoto-shrink_400_400/0/1701072906450?e=1723680000&v=beta&t=_lqa9feaFWKOfk6kSwn5z_j-j0g0b6L9Qfh-cvyFfzM',
			name: 'Jay Kumar',
			profession: 'Senior Application Developer',
			linkDin: 'https://www.linkedin.com/in/jay-kumar-8bb939173/',
			beforeAlgoCompanyLogo: 'https://media.licdn.com/dms/image/D560BAQHbf7VXUN_HwQ/company-logo_200_200/0/1717314235544?e=1726099200&v=beta&t=g2aiDX5EMwEr_d2vzMhptDA6-N7rNhZdQiGu2ACXESU',
			hike: 110,
			alumniCall: true,
			afterAlgoCompanyLogo: '../../../../assets/img/oracal.jpeg',
			detail: 'My mentor at AlgoTutor taught me that consistency is the most important quality. He typically encourages me to be consistent in class and complete assignments.'
        },
        {
            userImg: 'assets/img/placement/sanjeev.jpeg',
            name: 'Sanjeev Kumar',
            profession: 'Software Engineer II',
            linkDin: 'https://www.linkedin.com/in/-sanjeevkumar/',
            beforeAlgoCompanyLogo: '../../../../assets/img/nokia.png',
            hike: 120,
            alumniCall: true,
            afterAlgoCompanyLogo: '../../../../assets/img/247ai.png',
            detail: 'DS, Algo, and System Design course I completed was exceptionally beneficial. It honed my problem-solving skills and empowered me to architect scalable systems.'
        },
		{
			userImg: 'assets/img/placement/suriyaGupta.jpg',
			name: 'Surya Gupta',
			profession: 'Senior Member of Technical Staff',
			linkDin: 'https://www.linkedin.com/in/surya-gupta-7a4b00117/?originalSubdomain=in',
			beforeAlgoCompanyLogo: '../../../../assets/img/microSoft.jpg',
			hike: 105,
			alumniCall: false,
			afterAlgoCompanyLogo: '../../../../assets/img/placement/salesforce-logo.png',
			detail: 'As a senior engineer for me specially I was looking for something where I can upskill on System Design, The inclusion of real-world case studies significantly enriched my learning experience. '
		},
		{
			userImg: 'assets/img/placement/praveeen.jpeg',
			name: 'Pavan kumar Vg',
			profession: 'Application Developer',
			linkDin: 'https://www.linkedin.com/in/pavan-kumar-vg-245769194/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app ',
			beforeAlgoCompanyLogo: '../../../../assets/img/tcs.png',
			hike: 130,
			alumniCall: true,
			afterAlgoCompanyLogo: '../../../../assets/img/ibm_logo.jpeg',
			detail: 'For those who eager to enhance their skills and want to prepare for product based companies, AlgoTutor offers an excellent platform to prepare for the same.'
		},
		{
			userImg: 'assets/img/placement/shalinPrasad.jpg',
			name: 'Shalin Prasad',
			profession: 'Product Developer',
			linkDin: 'https://www.linkedin.com/in/shalin-prasad-258523125/',
			beforeAlgoCompanyLogo: '../../../../assets/img/placement/honeywell_logo.jpg',
			hike: 130,
			alumniCall: true,
			afterAlgoCompanyLogo: '../../../../assets/img/placement/epicor_software_corp_logo.jpg',
			detail: 'With well structured curriculum, AlgoTutor mentors helped me alot in my preparation. Personally I improved alot with mock interview and feedback.'
		},

		{
			userImg: 'assets/img/placement/upendra.png',
			name: 'Upendra Yadav',
			linkDin: 'https://www.linkedin.com/in/upen24/',
			alumniCall: true,
			beforeAlgoCompanyLogo: '../../../../assets/img/Cohesity_logo.png',
			hike: 80,
			profession: 'Senior Member Of Technical Staff',
			afterAlgoCompanyLogo: '../../../../assets/img/oracal.jpeg',
			detail: `AlgoTutor's instructors and staff members are all exceptionally cooperative. They focus on each student and do their best to resolve their problems.`
		},
		// {
		// 	userImg: 'assets/img/placement/khushi_sinha.jpeg',
		// 	name: 'Khushi Sinha',
		// 	linkDin: 'https://www.linkedin.com/in/khushi-sinha-5a42a515a/',
		// 	beforeAlgoCompanyLogo: '../../../../assets/img/tcs.png',
		// 	hike: 120,
		// 	profession: 'Software Engineer',
		// 	afterAlgoCompanyLogo: '../../../../assets/img/LTI.jpeg',
		// 	detail: 'For me DSA was something that I need to learn from basics and they did it very well. AlgoTutors dedicated TA support was very helpful in doubt solving.'
		// }
	]
	notificationContactForm: FormGroup;
	popupSubmitted: boolean = false;
	selectedCandidateProfile: string;
	candidateProfileErrorMessqage: string;
	nextBatchTime: string = '';
	constructor(
		private fb: FormBuilder, private router: Router,
		private notifyService: NotificationService,
		private commonservice: CommonServiesService,
		private alumniPopupService: AlumniPopupService
	) { }

	ngOnInit(): void { }

	alumniCall(name: string) {
		sessionStorage.setItem('alumniName', name);
		this.alumniPopupService.openModal();
	}
}
