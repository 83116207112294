import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-mastring-dsa-system-design-with-full-stack',
  templateUrl: './mastring-dsa-system-design-with-full-stack.component.html',
  styleUrls: ['./mastring-dsa-system-design-with-full-stack.component.scss']
})
export class MastringDsaSystemDesignWithFullStackComponent implements OnInit {
  contactForm: FormGroup;
  moduleQuestion: any[] = [];
  course_details = environment.course_date_mastring_dsa_systen_design_fullstack;
  course_date_month = environment.course_date_month;
  course_class_mode_text = [
    'Live Interactive Classes',
    'Personalized Mentorship',
    'Unlimited Mock Interviews',
    'Lifetime Content Access',
    'Guaranteed Job Referrals',
    'Unlimited Doubt Support'
  ]
  currentTextIndex: number = 0;
  classFeature: string = this.course_class_mode_text[this.currentTextIndex];
  feedbackSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: false,
    autoplayHoverPause: false,
    autoplay: false,
    mouseDrag: false,
    items: 2,
    margin: 10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 2
      }
    }
  }
  courseCASEstudyHLD = [
    {
      title: 'Design URL Shortener Service',
      text: `“A URL shortener service creates a short url against a long url. Moreover, when we click on the short/tiny url, it get redirected to the actual url”`,
      Img: '/assets/img/courses/courses_custom/short-url.jpg',
    },
    {
      title: 'Design OTT Platform Like Netflix',
      text: `"Here we will learn the architecture of video streaming platform and how to create an on-demand video streaming app like Netflix”`,
      Img: '/assets/img/courses/courses_custom/netflix.jpg',
    },
    {
      title: 'Design Social Media News Feed',
      text: `"Here we will learn how to design Facebook/Insta’s news feed kind of service, which notify updates from all the users”`,
      Img: '/assets/img/courses/courses_custom/facebook-insta-news-feed.jpg',
    },
    {
      title: 'Design Cab Booking System Like UBER',
      text: `“Here we will learn the architecture of cab booking system and how to design a system like UBER ”`,
      Img: '/assets/img/courses/courses_custom/car-booking.jpg',
    },
    {
      title: 'Design Messaging System Like Whatsapp',
      text: `"Here we will learn the architecture of real time messaging/chat system like Whatsapp”`,
      Img: '/assets/img/courses/courses_custom/whatsapp.jpg',
    }
  ];
  courseCASEstudyLLD = [
    {
      title: 'Design Parking Lot',
      text: `“Here we will learn how to gather requirement for a parking lot kind of system and also complete design of low level code”`,
      Img: '/assets/img/courses/courses_custom/parking-lot.jpg',
    },
    {
      title: 'Design Movie Ticket Booking App',
      text: `"Here we will learn how to design the architecture of a movie booking kind of system and its low level code using OOD principle”`,
      Img: '/assets/img/courses/courses_custom/Moview Ticket-Booking-System.jpg',
    },
    {
      title: 'Design Tic-Tac-Toe Game',
      text: `Here we will deep dive into low level design of a gaming app like Tic-tac-toe with complete class design with implementation”`,
      Img: '/assets/img/courses/courses_custom/tic-tac-toe.jpg',
    },
    {
      title: 'Design a Logging Service',
      text: `"Logging is essential in understanding the event flow in a distributed system. Here we will learn about distributed logging with implementation.”`,
      Img: '/assets/img/courses/courses_custom/logging-service.jpg',
    },
    {
      title: 'Design a Caching Service',
      text: `"Here we will learn about importance/need of cache in a system and different caching techniques with implementation.”`,
      Img: '/assets/img/courses/courses_custom/caching-service.jpg',
    },
    {
      title: 'Design Expense Sharing app like Splitwise',
      text: `"Here we will learn the architecture of splitwise and its low level design with implementation.”`,
      Img: '/assets/img/courses/courses_custom/splitwise-app.jpg',
    }
  ]
  enquiry: Enquiry = new Enquiry();
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
      private notifyService: NotificationService, 
      private fb: FormBuilder, 
      private titleService: Title,
      private modalService: ModalService, private metaService: Meta) { }

  ngOnInit(): void {
    this.updateMetaTags('Mastering DSA & System Design with Full Stack Specialization', 'With hands-on projects and in-depth knowledge of Data Structures and Algorithms (DSA) along with System Design you can target your dream tech company');
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
    setInterval(() => {
      this.currentTextIndex = (this.currentTextIndex + 1) % this.course_class_mode_text.length;
      this.classFeature = this.course_class_mode_text[this.currentTextIndex];
    }, 3000);
  }
  moduleArray = [
    {
      id: 1,
      active: true,
      courseTopic: 'Foundation Of Programming',
      timeTaking: '2 weeks',
      childProperties: [
        {
          id: 1,
          module_description: 'Build a solid knowledge base by mastering the fundamentals of programming to ensure you have a strong coding base for technical challenges.',
          moduleContant: [
            'Programming basics',
            'Conditional statements, loops, functions',
            'Maths used in programming',
            'Recursion simplified',
            'Bit manipulation',
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 2,
      active: false,
      courseTopic: 'Data Structures and Algorithms',
      timeTaking: '14 weeks',
      childProperties: [
        {
          id: 1,
          module_description: 'Dive deep into the world of data structures and algorithms, sharpening your problem-solving skills to tackle complex coding tasks with confidence.',
          moduleContant: [
            'Algorithm time and space complexity analysis',
            'Array and it’s techniques (2 pointer, Negation method etc)',
            'Searching and Sorting',
            'Hashing techniques',
            'Linkedlist',
            'Stack and Queue',
            'Introduction to Tree data-structure',
            'Different types of tree data-structures (Binary tree, BST, N-array tree)',
            'Red-black tree, Avl tree, Segment tree',
            'Graph and graph traversal algorithms',
            'Heap',
            'Trie, TST',
            'Matrix related problems',
            'Greedy programming',
            'Backtracking',
            'Dynamic-programming',

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 3,
      active: false,
      courseTopic: 'High Level Design',
      timeTaking: '6 weeks',
      childProperties: [
        {
          id: 1,
          module_description: 'Explore the art of designing scalable and efficient software systems at the architectural level, preparing you for large-scale projects and technical leadership roles.',
          moduleContant: [
            'System design basics',
            'Types of databases and when to use which type of DB',
            'DB sharding',
            'Load balancing',
            'Horizontal/Vertical Scaling',
            'Caching algorithms, CDNs',
            'CAP theorem',
            'Messaging queue',
            'Consistent hashing',
            'Proxies',
            'Indexes',
            'Long-polling vs Websockets vs server-sent-events',
            'Design a system for single user to millions of users',
            'Microservices, Client Server architecture, Authentication & Authorization',
            'Rest API fundamentals',
            'Case studies'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 4,
      active: false,
      courseTopic: 'Low Level Design',
      timeTaking: '4 weeks',
      childProperties: [
        {
          id: 1,

          module_description: 'Delve into the intricacies of low-level system design, understanding how software components interact at a granular level for optimal performance.',
          moduleContant: [
            'Object oriented programming',
            'Good/Bad design',
            'Clean code architecture',
            'SOLID principles',
            'Design patterns',
            'Concurrency and Multithreading',
            'Case studies'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 5,
      active: false,
      courseTopic: 'Full Stack Specialization - MERN Stack ',
      timeTaking: '6 weeks',
      childProperties: [
        {
          id: 1,
          module_description: `In this comprehensive module, you'll dive into the world of MERN (MongoDB, Express.js, React, and Node.js), gaining the skills needed to develop robust and scalable web solutions for today's demanding digital landscape.`,
          moduleContant: [
            'HTML',
            'CSS',
            'Javascript',
            'React JS',
            'Node JS',
            'Building serve with Express JS',
            'MongoDB'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
    {
      id: 6,
      active: false,
      courseTopic: 'Land at your dream tech company',
      timeTaking: 'Until Get Placed',
      childProperties: [
        {
          id: 1,
          module_description: 'Receive expert guidance and resources to navigate the job market, optimize your resume, and ace technical interviews, ultimately securing your dream role in the tech industry.',
          moduleContant: [
            'Resume and Linkedin profile building',
            'Get referral and apply for Job opportunities',
            'Mock interviews based on company',
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
  ];
  // moduleArray = [
  //   {
  //     id: 1,
  //     active: false,
  //     courseTopic: 'Unit 0 - Foundation Of Programming',
  //   },
  //   {
  //     id: 2,
  //     active: false,
  //     courseTopic: 'Unit 1 - Data Structures and Algorithms',
  //   },
  //   {
  //     id: 3,
  //     active: false,
  //     courseTopic: 'Unit 2 - System Design in Depth',
  //   },
  //   {
  //     id: 4,
  //     active: false,
  //     courseTopic: 'Unit 3 - Advance Data Structures & Algorithms',
  //   },
  //   {
  //     id: 5,
  //     active: false,
  //     courseTopic: 'Unit 4 - Electives & Specialization',
  //   },
  //   {
  //     id: 6,
  //     active: false,
  //     courseTopic: 'Unit 5 - Work on End to End Projects in Partnership With Startups',
  //   }
  // ]

    updateMetaTags(title: string, description: string) {
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'description', content: description });
    }

  getModuleQuestion(event, index, selectedData) {
    // const filteredDataFromModule = this.eachModuleDataArr.filter(qId => qId.id == selectedData.id);
    // for (let i = 0; i < this.moduleArray.length; i++) {
    //   if (this.moduleArray[i].id == selectedData.id) {
    //     this.moduleArray[i].active = !this.moduleArray[i].active;
    //   } else {
    //     this.moduleArray[i].active = false;
    //   }
    // }
    // this.moduleQuestion = filteredDataFromModule;
    const element = event.target;
    element.classList.toggle("active");
    // if (this.moduleArray[index].isActive) {
    //   this.moduleArray[index].isActive = false;
    // } else {
    //   this.moduleArray[index].isActive = true;
    // }
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
  toggleCourseTitle(selectedData, index) {
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
  }
  toggleAccordian(event, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    // if (this.moduleArray[index].active) {
    //   this.moduleArray[index].active = false;
    // } else {
    //   this.moduleArray[index].active = true;
    // }
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
    const panel = element.nextElementSibling;
    if (panel != null) {
      if (panel?.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }

  }
  requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = 3;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Mastering DSA System Design With Fullstack Specialization - Course Page' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {
      },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    //this.toClearFormValue(this.contactForm.value);
  }

  toClearFormValue(formValue) {
    formValue.name = '';
  }

  downloadBroucher() {
    this.modalService.openModal();
  }
}
