<div class="information-card-mobile">
    <div class="row info-main-div">
        <div class="col-6 col-xl-3 contecnt-div info-one">
            <div class="achived-out-card-image-one">
                <i class="fa-solid fa-arrow-trend-up"></i>
            </div>
            <div class="achived-out-card-text-one">
                <span>147 %</span>
                <p>Avg. CTC Hike</p>
            </div>
        </div>
        <div class="col-6 col-xl-3 contecnt-div info-two">
            <div class="achived-out-card-image-two">
                <i class="fa-solid fa-graduation-cap"></i>
            </div>
            <div class="achived-out-card-text-two">
                <span>1000+</span>
                <p>Careers Transformed </p>
            </div>
    
        </div>
        <div class="col-6 col-xl-3 contecnt-div">
            <div class="achived-out-card-image-three">
                <i class="fa-solid fa-users"></i>
            </div>
            <div class="achived-out-card-text-three">
                <span>5000 +</span>
                <p>Learners Community</p>
            </div>
        </div>
        <div class="col-6 col-xl-3 contecnt-div">
            <div class="achived-out-card-image-four">
                <i class="fa-solid fa-chalkboard-user"></i>
            </div>
            <div class="achived-out-card-text-four">
                <span>50 +</span>
                <p>Experienced Mentors</p>
            </div>
        </div>
    
    </div>
    
</div>