<app-header-style-one></app-header-style-one>

<div class="contact-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="section-title">
                    <span class="sub-title">Contact Us</span>
                    <h4>Have any questions? We'd love to hear from you.</h4>
                </div>
                <div class="contact-form">
                    <form [formGroup]="contactForm">
                        <div class="form-group">
                            <label for="name">Name</label>
                            <input required minlength="3" id="name" type="text" class="form-control" placeholder="Name"
                                formControlName="name">
                        </div>
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input required type="email" class="form-control" placeholder="Email"
                                formControlName="email" id="email">
                        </div>
                        <div class="form-group">
                            <label for="number">Number</label>
                            <input required type="text" class="form-control" placeholder="Phone"
                                formControlName="number" id="number">
                        </div>
                        <div class="form-group">
                            <label for="message">Message</label>
                            <input required type="text" class="form-control"
                                placeholder="Message" formControlName="linkleurl" id="message">
                        </div>
                    </form>
                    <div class="try-again-message" *ngIf="timer !== 0">
                        Please wait for <span>{{timer}}</span> sec to make another request!
                    </div>
                    <button class="btn commButtonCss" [disabled]="isButtonDisable === true" (click)="requestCallBack()"><span>Get in
                            Touch</span></button>
                </div>
            </div>
            <div class="col-lg-7">

                <div class="map-area">
                    <img src="../../../../assets/img/contact-us/map-img.png" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<div class="contact-info-area">
    <div class="container">
        <div class="contact-info-heading">
            <label for="">NEED HELP?</label>
            <h1>Get In Touch With us</h1>
        </div>

        <div class="info-box">
            <div class="row">
                <div class="col-lg-4 col-sm-12 col-md-12 col-xl-4 col-xxl-4 hover-common-card">

                    <div class="contact-info-box">

                        <div class="icon">
                            <img src="../../../../assets/img/contact-us/headphone-icon.png" alt="">
                        </div>
                        <h3>Call Us On</h3>
                        <p><a href="tel:+91-7260058093">+91-7260058093</a></p>
                    </div>

                </div>
                <div class="col-lg-4 col-sm-12 col-md-12 col-xl-4 col-xxl-4 hover-common-card">
                    <div class="contact-info-box">
                        <div class="icon">
                            <img src="../../../../assets/img/contact-us/invalop-icon.png" alt="">
                        </div>
                        <h3>Email Us</h3>
                        <p><a href="mailto:info@algotutor.io">info@algotutor.io</a></p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-12 col-md-12 col-xl-4 col-xxl-4 hover-common-card">
                    <div class="contact-info-box">
                        <div class="icon">
                            <img src="../../../../assets/img//contact-us/location-icon.png" alt="">
                        </div>
                        <h3>Our Location</h3>
                        <p>Shilpitha Splendour, Chinappa
                            Layout, Mahadevapura, Bengaluru, 560048</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>