import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
    topic1On: boolean = true;
    constructor() { }
    ngOnInit(): void { }
    FNQArr = [
        {
            id: 1,
            isOpened: false,
            qName: 'How do I enroll in the course?',
            qAns: 'Please submit an inquiry or request a call back at a time that works for you if you are interested in any course or are unsure about your course choices. To help you find the course that is most appropriate for you, someone from AlgoTutor will get in touch with you.'
        },
        {
            id: 2,
            isOpened: false,
            qName: 'How can I access recordings of my previous lectures?',
            qAns: 'Yes! After logging into the LMS, all of your prior lecture recordings will be accessible from your dashboard.'
        }
        ,
        {
            id: 2,
            isOpened: false,
            qName: 'Can I choose my mentor?',
            qAns: 'Yes! You can choose from the variety of mentors we have from top product based companies.'
        },
        {
            id: 3,
            isOpened: false,
            qName: 'Who will be the instructor for the course?',
            qAns: 'Our instructors have experience in developing large scale systems, problem solving and come leading product based companies.'
        },
        {
            id: 4,
            isOpened: false,
            qName: 'What kind of placement assistance will be provided post completion of the course?',
            qAns: 'After completion of the DSA module, we will conduct mock interviews to check your knowledge and based on the feedback we will advance you for the available job opportunities.'
        },
        {
            id: 5,
            isOpened: false,
            qName: 'For how long can I access the recordings?',
            qAns: 'Access to the recordings is never taken away.'
        },
        {
            id: 5,
            isOpened: false,
            qName: 'What are the benefits of Alumni card?',
            qAns: 'You can come and join the live classes without paying any fee in the future whenever you wish to reskill or upgrade even after the placement.'
        },
        {
            id: 5,
            isOpened: false,
            qName: 'What mode of payment are available for paying the course fees?',
            qAns: 'We accept all modes of payment including Credit/Debit card, UPI, NetBanking, PayPal etc.'
        },

    ]

    toggleAccordian(event, index, selectedData) {
        const element = event.target;
        element.classList.toggle("active");
        for (let i = 0; i < this.FNQArr.length; i++) {
            if (this.FNQArr[i].id == selectedData.id) {
                this.FNQArr[i].isOpened = !this.FNQArr[i].isOpened;
            } else {
                this.FNQArr[i].isOpened = false;
            }
        }

        const panel = element.nextElementSibling;
        if (panel != null) {
            if (panel?.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
            }
        }
    }
}