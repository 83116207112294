<div class="courses-area mt-4">
    <div class="container-fluid">
        <div class="row">
            <div class="section-title">
                <h3 class="section-header__title">Explore Our Courses</h3>
            </div>
            <div class="col-lg-4 col-xl-3 col-xxl-3">
                <div class="course-sub-category">
                    <div class="tabs-active" *ngFor="let tabs of courseCategoryTabs"
                         [ngStyle]="{'background-color': tabs.active == true ? '#E9F6FF' : ''}"
                         (click)="getCourseByCategory(tabs.id)">
                        <label class="horizontal-line"
                               [ngStyle]="{'visibility': tabs.active == false ? 'hidden' : ''}"><span>j</span></label>
                        <span class="course-type-category"
                              [ngStyle]="{'color': tabs.active == true ? '#1D9BD6' : '','font-weight':tabs.active == true ? '600':''}">
                            {{tabs.couseTabsName}}
                            <i class="fa-solid fa-chevron-right"
                               [ngStyle]="{'color': tabs.active == true ? '#1D9BD6' : '','font-weight':tabs.active == true ? '600':''}"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 col-xl-9 col-xxl-9 course-banner-area">

                <div class="row">
                    <div class="col-md-6 col-lg-6 col-xl-4" *ngFor="let courses of allCourseArr">
                        <div class="course-details hover-common-card">
                            <img [src]="courses.courseImage" alt="">
                            <div class="ribon-text">
                                <label [ngStyle]="{'color': courses.courseFor === 'Professional' ? 'green' : 'orange'}">{{courses.courseFor}}</label>
                            </div>
                            <div class="course-title">
                                <label>{{courses.courseName}}</label>
                            </div>

                            <div class="course-details-duration">
                                <span>Learning Outcomes:</span>
                                <ul class="course-about-li-tags" *ngFor="let tags of courses.whatYouWillLearn">
                                    <li><i class="fa-solid fa-circle-check"></i>{{tags}}</li>
                                </ul>
                                <!-- <div><label><i class="fa-solid fa-check"></i>{{courses.courseDuration}}</label></div>
                                <div><label><i class="fa-solid fa-clock"></i>{{courses.noOfHour}}</label></div>
                                <div *ngIf="courses.courseName != 'Tech Interview Preparation - Mock Interviews'">
                                    <label><i class="fa-solid fa-video"></i>Live Clasess</label></div>
                                <div *ngIf="courses.courseName == 'Tech Interview Preparation - Mock Interviews'">
                                    <label><i class="fa-solid fa-video"></i>Live Mock Interview <span
                                            class="paddinf-auto">Sessions</span></label>
                                </div> -->

                            </div>
                            <!-- <div class="timing" *ngIf="courses.nextBatchDayMonth">
                                <div class="time">
                                    <span> Next Batch</span>
                                    <span>
                                        <i class="fa-regular fa-calendar"></i> {{courses.nextBatchDayMonth}}
                                        <i class="fa-regular fa-clock"></i> {{courses.time}}
                                    </span>

                                </div>
                            </div> -->
                            <!-- <hr> -->
                            <div class="course-details-button">
                                <label (click)="downloadBroucher(courses.pdfDwn)">
                                    <i class="fa-solid fa-download"></i>Brochure
                                </label>

                                <label style="background-color:var(--mainColor); padding: 10px; color: #ffffff;"
                                       routerLink="{{courses.navigationUrl}}">
                                    <i class="fa-solid fa-arrow-up-right-from-square"></i>View Details
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
