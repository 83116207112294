<div class="events-area pt-100">
    <div class="section-title">
        <span class="sub-title">Discover our Mentors</span>
        <h3 class="playfair-display">We have Industry Experts as <span class="common-header-color">Mentor</span> 
            who guide you with <span class="common-header-color">Career</span> Planning & 
            <span class="common-header-color">Interview</span>
            Preparation</h3>
    </div>
    <div class="feedback-slides">
        <owl-carousel-o [options]="carouselOptions">
            <ng-container *ngFor="let data of eventSection">
                <ng-template carouselSlide>
                    <section class="courses-area ">
                        <div class="container">
                            <div class="row justify-content-center">
    
                                <div class="single-courses-box-item hover-common-card">
                                    <div class="courses-image">
                                        <a class="d-block image">
                                            <img [src]="data.mentorImage" alt="image">
                                        </a>
                                    </div>
                                    <div class="mentor-details">
                                        <div class="mentor-name">
                                            <label for="">{{data.mentorName}}</label>
                                            
                                            <div class="lindin-profile-icon">
                                                <a target="_blank" href="{{data.linkDinUrl}}"><img
                                                        src="../../../../assets/img/icons/lindin.png" alt=""></a>
                                               
                                            </div>
                                        </div>
                                        <div class="mentor-type">
                                            <span>{{data.profession}}</span>
                                        </div>
                                        <img class="compny-logo" [ngStyle]="data.mentorName == 'Anvita Bansal' ? {} : {}" [src]="data.companyLogo" alt="image">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</div>
