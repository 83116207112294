import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-our-mentor',
  templateUrl: './our-mentor.component.html',
  styleUrls: ['./our-mentor.component.scss']
})
export class OurMentorComponent implements OnInit {
  carouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 400,
    nav: true,
    center: false,
    items: 4,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 2
      },
      768: {
				items: 3
			},
			1024: {
				items: 4
			},
      1200: {
        items: 4
      }
    }
  };
  eventSection = [
    {
      mentorImage: 'assets/img/our-mentor/userImage/prem.png',
      linkDinUrl: "https://www.linkedin.com/in/prekum/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/premCompanyLogo.png',
      mentorName: 'Prem Kumar',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/mukul.jpg',
      linkDinUrl: "https://www.linkedin.com/in/msharmaju/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/Flipkart-Logo-removebg-preview.png',
      mentorName: 'Mukul Kumar',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/halwe.jpeg',
      linkDinUrl: "https://www.linkedin.com/in/prateek-halwe-66391223/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/microSoft.jpg',
      mentorName: 'Prateek Halwe',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/ashishImage.jpg',
      linkDinUrl: "https://www.linkedin.com/in/imashishmishra/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/jpMorgan.png',
      mentorName: 'Ashish Mishra',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/sachinImage.jpg',
      linkDinUrl: "https://www.linkedin.com/in/sachinsharma1057/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/microSoft.jpg',
      mentorName: 'Sachin Sharma',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/shasahank.jpg',
      linkDinUrl: "https://www.linkedin.com/in/shashascs/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/iqvia.png',
      mentorName: 'Shashank Awasthi',
      profession:'Mentor'
      },
      {
          mentorImage: 'assets/img/our-mentor/userImage/sayan.jpeg',
          linkDinUrl: "https://www.linkedin.com/in/nascarsayan/",
          companyLogo: '../../../../assets/img/our-mentor/companyLogo/microSoft.jpg',
          mentorName: 'Sayan Naskar',
          profession: 'Mentor'
      },
    {
      mentorImage: 'assets/img/our-mentor/userImage/saloni-gupta.jpg',
      linkDinUrl: "https://www.linkedin.com/in/saloni-gupta-18293a231/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/jpMorgan.png',
      mentorName: 'Saloni Gupta',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/himanshu-gupta.jpeg',
      linkDinUrl: "https://www.linkedin.com/in/himanshug12/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/microSoft.jpg',
      mentorName: 'Himanshu Gupta',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/anvita-bansal.jpeg',
      linkDinUrl: "https://www.linkedin.com/in/anvita-bansal/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/premCompanyLogo.png',
      mentorName: 'Anvita Bansal',
      profession:'Mentor'
    },
    {
      mentorImage: 'assets/img/our-mentor/userImage/shreya_jain.jpg',
      linkDinUrl: "https://www.linkedin.com/in/shreya-jain-9a320a106/",
      companyLogo: '../../../../assets/img/our-mentor/companyLogo/microSoft.jpg',
      mentorName: 'Shreya Jain',
      profession:'Mentor'
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
