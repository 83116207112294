<header class="header-area p-relative">
    <div class="navbar-area navbar-style-three card" stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item nav-link"><a routerLink="">Home</a></li> -->
                        <li class="nav-item nav-link"><a href="javascript:void(0)" (click)="toggleCourseMenu()">Courses
                                <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu dropdown-menu2" *ngIf="openCourseMenu == true">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-12 col-lg-3 col-xl-3 col-sm-3 col-xs-3">

                                                    <div class="course-sub-category">
                                                        <h3>Browse by Domains</h3>
                                                        <div class="tabs-active" *ngFor="let tabs of courseCategoryTabs"
                                                            [ngStyle]="{'background-color': tabs.active == true ? '#E9F6FF' : ''}"
                                                            (click)="getCourseByCategory(tabs.id)">
                                                            <label class="horizontal-line"
                                                                [ngStyle]="{'visibility': tabs.active == false ? 'hidden' : ''}"><span>j</span></label>
                                                            <span class="course-type-category"
                                                                [ngStyle]="{'color': tabs.active == true ? '#1D9BD6' : '','font-weight':tabs.active == true ? '600':''}">{{tabs.couseTabsName}}
                                                                <i class="fa-solid fa-chevron-right"
                                                                    [ngStyle]="{'color': tabs.active == true ? '#1D9BD6' : '','font-weight':tabs.active == true ? '600':''}"></i></span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-md-12 col-lg-9 course-banner-area">

                                                    <div class="row">
                                                        <div class="col-lg-4" *ngFor="let courses of allCourseArr">
                                                            <div class="course-details hover-common-card"
                                                                routerLink="{{courses.navigationUrl}}">
                                                                <div class="course-title">
                                                                    <label>{{courses.courseName}}</label>

                                                                </div>

                                                                <div class="course-details-duration">
                                                                    <div><label><i
                                                                                class="fa-solid fa-check"></i>{{courses.courseDuration}}</label>
                                                                    </div>
                                                                    <div style="margin-top: -5px;"
                                                                        *ngIf="courses.courseName != 'Tech Interview Preparation - Mock Interviews'">
                                                                        <label><i class="fa-solid fa-video"></i>Live
                                                                            Classes</label>
                                                                    </div>
                                                                    <div style="margin-top: -5px;"
                                                                        *ngIf="courses.courseName == 'Tech Interview Preparation - Mock Interviews'">
                                                                        <label style="line-height: 20px;"><i
                                                                                class="fa-solid fa-video"></i>Live Mock
                                                                            Interview <span
                                                                                class="paddinf-auto">Sessions</span></label>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ul>
                        </li>
                        <li class="nav-item nav-link"><a routerLink="/campus-program" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Campus Program</a></li>
                        <li class="nav-item nav-link"><a routerLink="/notes" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Notes</a></li>
                        <!-- <li class="nav-item nav-link"><a routerLink="/contact-us" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact Us</a> 
                        </li>-->
                        <li class="nav-item nav-link mobile"><a href="https://blogs.algotutor.io/" target="_blank"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blogs</a>
                        </li>
                        <li class="nav-item nav-link last-element mobile"><a routerLink="/hire-from-us"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Hire
                                From Us</a></li>
                        <li class="nav-item nav-link"><a href="https://blogs.algotutor.io/" target="_blank"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Blogs</a>
                        </li>
                        <!--<li class="nav-item nav-link last-element mobile"><a routerLink="/events"
                                routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Events</a></li>-->
                        <li class="nav-item laptop"><a href="javascript:void(0)">More <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu dropdown-menu2 dropdown-menu3">

                                <li class="nav-item nav-link last-element"><a routerLink="/hire-from-us"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Hire
                                        From Us</a></li>
                                <!--<li class="nav-item nav-link last-element"><a routerLink="/events"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Events</a>
                                </li>-->

                            </ul>
                        </li>
                        <button class="btn  apply-now-btn" data-toggle="modal" data-target="#ApplyNowModal">Apply
                            Now</button>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</header>

<!-- Modal -->
<div class="modal fade" id="ApplyNowModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h1>Start Better Learning Future From Here</h1>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <button type="button" class="close close-modal-button " data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="form-content">

                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>
                                    <div class="form-group">
                                        <select name="" id="" class="text-muted form-control select_list"
                                            (change)="selectCourseId($event.target.value)">
                                            <option value="undefined" selected>Select Program</option>
                                            <option value="1">
                                                Mastering Advance Data Science
                                                & Generative AI
                                            </option>
                                            <option value="2">Mastering DSA & System Design</option>
                                            <option value="3">Mastering DSA & System Design with Full Stack
                                                Specialization</option>
                                            <option value="4">Complete Placement Package for College Students</option>
                                            <option value="5">Full Stack Web Development - MERN</option>
                                            <option value="6">Tech Interview Preparation & Mock Interviews</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                    </div>
                                </form>
                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <div class="apply-now-button">
                                    <button class="btn" [disabled]="isButtonDisable === true"
                                        (click)="requestCallBack()"><span>Apply
                                            Now</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>