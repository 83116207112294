<div class="container">
    <p class="small-text">What we offer</p>
    <h3 class="section-header__title">Advantage of Joining <span style="color: var(--mainColor);">AlgoTutor</span></h3>

    <div class="main-card">
        <div class="row card-banner">

            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="card first-card hover-common-card" style="border: 1px solid #6F6B80;">
                    <div class="img-item-top">
                        <img src="../../assets/img/icons/theme1.png">
                    </div>
                    <div class="item-title">
                        <span>Upskill with Top Tech Industry Professionals</span>
                        <br/>
                        <br/>
                        <div class="item-points" style="margin-top: -10px;">
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Gain knowledge from professionals working at Microsoft Goldman etc</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Doubt clearing session with dedicated teaching assistant</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>1:1 Mentorship with professionals from top tech company. Option to choose your Mentor based on your target company or experience</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Live interactive classes with small batch size and open discussion with instructor</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Lifetime access to learning content including Class recordings, notes and assignments</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           
            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="card second-card hover-common-card">
                    <div class="img-item-top">
                        <img src="../../assets/img/icons/business-job-interview-concept.jpg">
                    </div>
                    <div class="item-title">
                        <span>Guaranteed Job Assistance and Interview Preparation</span>
                        <br />
                        <br />
                        <div class="item-points" style="margin-top: -10px;">
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Dedicated placement team to track your application and provide opportunities</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Access to algoTutor's job portal where you can apply and track your applications</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Dedicated mentor to guide for company specific interview</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Regular mock interviews including feedback sharing session until get placed. No cap on total mock interview count</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>1:1 Session with mentor for resume review, interview tips and career guidance</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-lg-4 col-sm-12 col-md-12">
                <div class="card third-card hover-common-card" style="border: 1px solid #6F6B80;">
                    <div class="img-item-top">
                        <img src="../../assets/img/icons/algotutor_portal_login.jpg">
                    </div>
                    <div class="item-title">
                        <span>Platform to Practice, Support and Track Progress</span>
                        <br />
                        <br />
                        <div class="item-points" style="margin-top: -10px;">
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Practice problems on our own portal, no need to visit third party website to practice</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Raise help request in case you face any issues while solving the problem</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Book and track mentorship sessions with your mentor based on available time slot.</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Access to alumni Card, even after placement you can request to access live classes for upcoming interview preparation</label>
                            </div>
                            <div class="important-notes">
                                <i class="fa-solid fa-circle-check"></i>
                                <label>Pause/Resume course in case of any other priorities</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
