import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-full-stack-web-developmen-mern',
  templateUrl: './full-stack-web-developmen-mern.component.html',
  styleUrls: ['./full-stack-web-developmen-mern.component.scss']
})
export class FullStackWebDevelopmenMernComponent implements OnInit {
  course_details = environment.course_date_fullstack_web_development_mern;
  course_date_month = environment.course_date_month;
  moduleQuestion: any[] = [];
  course_class_mode_text = [
    'Live Interactive Classes',
    'Personalized Mentorship',
    'Unlimited Mock Interviews',
    'Lifetime Content Access',
    'Guaranteed Job Referrals',
    'Unlimited Doubt Support'
  ]
  currentTextIndex: number = 0;
  classFeature: string = this.course_class_mode_text[this.currentTextIndex];
  contactForm: FormGroup;
  enquiry: Enquiry = new Enquiry();
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
    private notifyService: NotificationService,
      private fb: FormBuilder, private modalService: ModalService, private metaService: Meta, private titleService: Title) { }

  ngOnInit(): void {
      this.updateMetaTags('Full Stack Web Development MERN', 'Experience hands-on learning of web development and build dynamic and scalable web applications using MERN stack.');
      this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
   

    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
    setInterval(() => {
      this.currentTextIndex = (this.currentTextIndex + 1) % this.course_class_mode_text.length;
      this.classFeature = this.course_class_mode_text[this.currentTextIndex];
    }, 3000);
  }
  moduleArray = [
    {
      id: 1,
      active: true,
      courseTopic: 'Introduction to web development',
      timeTaking: '1 week',
      childProperties: [
        {
          id: 1,
          module_description: 'This module offers a beginner-friendly exploration of web technologies, enabling you to create your first web pages, style them with CSS, and add interactivity using JavaScript.',
          moduleContant: [
            'Overview of web development',
            'Basic HTML, CSS and JavaScript concepts',
            'Setting up the development environment'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 2,
      active: false,
      courseTopic: 'Front-end development with React JS',
      timeTaking: '1 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Explore the power of React JS in building responsive and efficient web applications. From component-based architecture to state management',
          moduleContant: [
            'Introduction to React and its core concepts',
            'Building components and managing state',
            'Consuming APIs in React applications',
            'Using React Router for client-side routing',

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 3,
      active: false,
      courseTopic: 'Back-end Development with Node.js and Express',
      timeTaking: '2 week',
      childProperties: [
        {
          id: 1,
          module_description: `With this module you can master the art of handling data, routing, and creating APIs while gaining hands-on experience with Node.js and Express.`,
          moduleContant: [
            'Introduction to Node.js and Express',
            'Building RESTful APIs with Express',
            'Managing data with MongoDB and Mongoose',
            'Authentication and authorization with Passport.js',
            'Integrating React and Express',
            'Building a full-stack MERN application'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 4,
      active: false,
      courseTopic: 'Advanced React and Node concepts',
      timeTaking: '1 week',
      childProperties: [
        {
          id: 1,
          module_description: `In this module dive deep into advanced topics, including server-side rendering, authentication, and performance optimization, to create cutting-edge web applications`,
          moduleContant: [
            'React Hooks',
            'Context API for global state management',
            'Asynchronous programming with Promises and Async/Await',
            'Middleware and error handling in Express',
            'Performance optimization with clustering and caching',

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },

    {
      id: 5,
      active: false,
      courseTopic: 'Project building',
      timeTaking: '2 week',
      childProperties: [
        {
          id: 1,
          module_description: `In this module, you'll put theory into practice, collaborating on projects that demonstrate your proficiency in web development.`,
          moduleContant: [
            'Designing and building a complete MERN application from scratch',
            'Project documentation and best practices',
            'Project management tools and techniques',
            'Deploying the application to production',
            'Project review and feedback',
            'Best practices for maintaining and scaling MERN applications'

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
  ]
  getModuleQuestion(event, index, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
  toggleAccordian(event, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }

    const panel = element.nextElementSibling;
    if (panel != null) {
      if (panel?.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }

  toggleCourseTitle(selectedData, index) {
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
  }

  requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = 5;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'FullStack Web Development Mern - Course Page' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {
      },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    //this.toClearFormValue(this.contactForm.value);
  }

  toClearFormValue(formValue) {
    formValue.name = '';
  }

  downloadBroucher() {
    this.modalService.openModal();
  }
  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }
}
