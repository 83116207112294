import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-page-five',
  templateUrl: './home-page-five.component.html',
  styleUrls: ['./home-page-five.component.scss']
})
export class HomePageFiveComponent implements OnInit {
  closeResult: string = '';
  constructor() { }

  ngOnInit(): void {
  }
  displayStyle = "none";
  
  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }
}
