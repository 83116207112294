<div class="enquery-form-div">
    <div class="enquery-form">
        <div class="banner-img">
            <img src="../../../assets/img/courses/enqury-form-img.png" alt="">
        </div>
        <div class="form-area">
            <form [formGroup]="contactForm" class="contact-form">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" type="text"
                                class="form-control"  placeholder="Name" formControlName="name">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required  type="email"
                                class="form-control" placeholder="Email" formControlName="email">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required type="text"
                                class="form-control" placeholder="Phone" formControlName="number">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <select class="form-control" (change)="selectCourseName($event.target.value)">
                                <option value="undefined" selected>Select Program</option>
                                <option value="1">Advanced Data Science & AI</option>
                                <option value="2">Mastering DSA & System Design</option>
                                <option value="3">Mastering DSA & System Design with Full Stack Specialization</option>
                                <option value="4">Complete Placement Package for College Students</option>
                                <option value="5">Full Stack Web Development - MERN</option>
                                <option value="6">Tech Interview Preparation & Mock Interviews</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <input required  type="text"
                            class="form-control" 
                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="try-again-message" *ngIf="timer !== 0">
                            Please wait for <span>{{timer}}</span> sec to make another request!
                        </div>
                        <div class="enquery-form-button">
                            <button class="btn" [disabled]="isButtonDisable === true" (click)="requestCallBack()"><span>Apply for Counselling</span></button>
                        </div>

                    </div>
                </div>
            </form>
        </div>
    </div>
</div>