import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageFiveComponent } from './components/pages/home-page-five/home-page-five.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { ContactMentorComponent } from './components/pages/contact-mentor/contact-mentor.component';
import { AdvancedDataScienceAiComponent } from './courses/advanced-data-science-ai/advanced-data-science-ai.component';
import { MastringDsaSystemDesignComponent } from './courses/mastring-dsa-system-design/mastring-dsa-system-design.component';
import { MastringDsaSystemDesignWithFullStackComponent } from './courses/mastring-dsa-system-design-with-full-stack/mastring-dsa-system-design-with-full-stack.component';
import { CompletePlacementPakageForCollegeComponent } from './courses/complete-placement-pakage-for-college/complete-placement-pakage-for-college.component';
import { FullStackWebDevelopmenMernComponent } from './courses/full-stack-web-developmen-mern/full-stack-web-developmen-mern.component';
import { TechInterviewPreprationMockComponent } from './courses/tech-interview-prepration-mock/tech-interview-prepration-mock.component';
import { NotesComponent } from './components/pages/notes/notes.component';
import { CampusProgramComponent } from './components/pages/campus-program/campus-program.component';
import { HireFromUsComponent } from './components/pages/hire-from-us/hire-from-us.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { InstructorProfileComponent } from './components/pages/instructor-profile/instructor-profile.component';
import { MastringDsaAndSystemDesignComponent } from './courses/mastring-dsa-and-system-design/mastring-dsa-and-system-design.component';
import { CollegeAmbassadorProgramComponent } from './components/pages/college-ambassador-program/college-ambassador-program.component';



const routes: Routes = [
    { path: '', component: HomePageFiveComponent},
    { path: 'mentor-instructor', component: ContactMentorComponent },
    { path: 'all-courses', component: HomePageFiveComponent },
    { path: 'contact-us', component: ContactPageComponent },
    { path: 'advanced-data-science-and-generative-AI', component: AdvancedDataScienceAiComponent },
    { path: 'mastering-dsa-system-design', component: MastringDsaSystemDesignComponent },
    { path: 'mastering-dsa-system-design-with-fullstack', component: MastringDsaSystemDesignWithFullStackComponent },
    { path: 'complete-placement-package-college-students', component: CompletePlacementPakageForCollegeComponent },
    { path: 'fullStack-web-development-mern', component: FullStackWebDevelopmenMernComponent },
    { path: 'tech-interview-preparation', component: TechInterviewPreprationMockComponent },
    { path: 'notes', component:NotesComponent },
    { path: 'campus-program', component:CampusProgramComponent },
    { path: 'hire-from-us', component:HireFromUsComponent },
    { path: 'events', component:EventsPageComponent },
    { path: 'instructor-profile', component:InstructorProfileComponent },
    { path: 'contact-mentor', component:ContactMentorComponent },
    { path: 'mastering-dsa-and-system-design', component:MastringDsaAndSystemDesignComponent },
    { path: 'college-ambassador-program', component:CollegeAmbassadorProgramComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: false })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
