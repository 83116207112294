import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
@Component({
  selector: 'app-become-instructor-partner',
  templateUrl: './become-instructor-partner.component.html',
  styleUrls: ['./become-instructor-partner.component.scss']
})
export class BecomeInstructorPartnerComponent implements OnInit {
  feedbackSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: false,
    animateOut: false,
    autoplayHoverPause: false,
    autoplay: false,
    mouseDrag: false,
    items: 1,
    margin:10,
    navText: [
      "<i class='bx bx-left-arrow-alt'></i>",
      "<i class='bx bx-right-arrow-alt'></i>"
    ],
    responsive: {
			0: {
				items: 1
			},
			576: {
				items: 1
			},
			768: {
				items: 1
			},
			1200: {
				items: 1
			}
		}
  }
  reviewArr=[
    {
      reviewedText:`“As a senior engineer I have specially reviewed the System Design content and live
      classes of AlgoTutor and I found it more on the implementation side instead of
      theoretical concepts which helps to work in real-time environment in the organization. I
      would recommend AlgoTutor course to get expertise in System Design.”`,
      userImg:'/assets/img/our-mentor/userImage/prem.png',
      userName:'Prem Kumar',
      UserRating:'5',
      linkDin:'https://www.linkedin.com/in/prekum/'
    },
    {
      reviewedText:`"Team of AlgoTutor is building an affordable up-skill program that
      will definitively bring a change in the edtech industry and also
      perception of the people towards professional courses. Will
      definitively recommend AlgoTutor!.”`,
      userImg:'/assets/img/our-mentor/userImage/shasahank.jpg',
      userName:'Shashank Awasthi',
      UserRating:'5',
      linkDin:'https://www.linkedin.com/in/shashascs/'
    },
    {
      reviewedText:`“Courses at AlgoTutor are well designed to cover all the major areas
      of DSA and System design which is required to crack product-based
      company interviews. Based on my review, I would recommend
      AlgoTutor Advance DSA course for product-based companies
      interview preparation.”`,
      userImg:'/assets/img/our-mentor/userImage/santoshImage.jpg',
      userName:'Santosh Kumar Mishra',
      UserRating:'5',
      linkDin:'https://www.linkedin.com/in/iamsantoshmishra/'
    },
    {
      reviewedText:`“Instructors at AlgoTutor are from top companies with enough
      experience in software development. After reviewing a few live
      classes I found their classes very interactive and full of in-depth
      knowledge about the topic. I would recommend AlgoTutor for DSA
      and System Design preparation.”`,
      userImg:'/assets/img/our-mentor/userImage/sachinImage.jpg',
      userName:'Sachin Sharma',
      UserRating:'5',
      linkDin:'https://www.linkedin.com/in/sachinsharma1057/'
    },
  ]

  constructor() { }

  ngOnInit(): void {}
	
}
