<div class="modal-header">
    <h4>Download Brochure</h4>
    <h6 class="lap-screen" *ngIf="nextBatchTime"> <span style="color: #d8334f;">Our Next Batch:</span> {{nextBatchDay}} <span
            style="position: relative; bottom: 5px; right: 3px;">{{nextBatchSuffix}} </span> <span style="margin-left: -3px;">{{nextBatchTime}}</span> </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
    <h6 *ngIf="nextBatchTime" class="mobile-screen"> <span style="color: #d8334f;">Our Next Batch:</span> {{nextBatchDay}} <span
            style="position: relative; bottom: 5px; right: 3px;">{{nextBatchSuffix}} </span> <span style="margin-left: -3px;">{{nextBatchTime}}</span> </h6>
    <form>
        <div class="mb-3">
            <label class="form-label">Please select your profile
                <span style="color: red;">*</span></label>
            <div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="name" id="collegeStudent" value="collegeStudent"
                        (change)="onRadioButtonChange('College Student')">
                    <label class="form-check-label" for="collegeStudent">College Student</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="name" id="workingProfessional"
                        value="workingProfessional" (change)="onRadioButtonChange('Working Professional')">
                    <label class="form-check-label" for="workingProfessional">Working Professional</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="name" id="graduatedNotWorking"
                        value="graduatedNotWorking" (change)="onRadioButtonChange('Graduated but not working')">
                    <label class="form-check-label" for="graduatedNotWorking">Graduated but not working</label>
                </div>
            </div>

            <small class="text-danger" style="position: relative;
            bottom: 10px;" *ngIf="candidateProfileErrorMessqage">
                {{candidateProfileErrorMessqage}}
            </small>
        </div>
    </form>

    <form [formGroup]="popupForm" (submit)="sendRequest()">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-6">
                <div class="form-group">
                    <input type="text" placeholder="Full Name" class="form-control" formControlName="name">
                </div>
                <small class="text-danger"
                    *ngIf="(registerFormControl.name.touched || submitted) && registerFormControl.name.errors?.required">
                    Name is required
                </small>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 email">
                <div class="form-group">
                    <input required type="text" placeholder="Email id" class="form-control" formControlName="email">

                </div>
                <small class="text-danger"
                    *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                    Email is required
                </small>
                <small *ngIf="registerFormControl.email.errors?.pattern" class="text-danger">Please
                    provide a valid
                    email address</small>
            </div>
            
            <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                <div class="form-group">
                    <input required type="number" placeholder="Phone Number" class="form-control"
                        formControlName="number">

                </div>
                <small class="text-danger"
                    *ngIf="(registerFormControl.number.touched || submitted) && registerFormControl.number.errors?.required">
                    Phone number is required
                </small>
                <small *ngIf="registerFormControl.number.errors?.pattern" class="text-danger">
                    Please provide a valid phone number (10 digits)
                </small>
            </div>

            <div class="col-sm-12 col-md-12 col-lg-6 mt-3">
                <div class="form-group">
                    <input required type="number" placeholder="Experience (In Years)" class="form-control"
                        formControlName="experience">

                </div>
                <small class="text-danger"
                    *ngIf="(registerFormControl.experience.touched || submitted) && registerFormControl.experience.errors?.required">
                    Experience is required
                </small>
            </div>
            <div class="button-save">
                <label for="">Your dream job is just one click away!</label><br>
                <button type="submit" class="btn  btn-sm commButtonCss button"><span>Submit</span></button>
            </div>

        </div>
    </form>
</div>