import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-campus-program',
  templateUrl: './campus-program.component.html',
  styleUrls: ['./campus-program.component.scss']
})
export class CampusProgramComponent implements OnInit {
  contactForm: FormGroup;
  FNQArr = [
    {
      id: 1,
      isOpened: false,
      qName: 'How AlgoTutor can help college in upskilling?',
      qAns: 'We have set of programs designed by industry experts that will help students to get industry oriented training and make them placement ready.'
    },
    {
      id: 2,
      isOpened: false,
      qName: 'What is the Campus Program?',
      qAns: 'We organize on-campus trainings in college for interactive sessions and in person QnA with Industry experts.'
    },
    {
      id: 3,
      isOpened: false,
      qName: 'What are the different types of programs organized by AlgoTutor at college',
      qAns: 'We organize events like Upskilling trainings on latest technology, FDP programs, Coding hackathons, College ambassador program, Pre-placement preparation and other on-demand events suggested by college.'
    },
    {
      id: 4,
      isOpened: false,
      qName: 'Is there any certification provided by AlgoTutor to college students after the program?',
      qAns: 'Yes, we provide certificate to students and also goodies for best performers in hackathons and other events.'
    },
    {
      id: 5,
      isOpened: false,
      qName: 'How to connect with AlgoTutor to plan event in our college?',
      qAns: 'Please fill the form given at the top of the page, our college program manager will connect with the contact person.'
    },

  ]

  eventListArr = [
    {
      id: 1,
      isActive: true,
      name: 'On campus Events',
      eventData: 'We organize diverse range of events on campus that cater to different interests, academic pursuits, and social engagements. We have group of experts from different sector who can visit college and organise the event.'
    },
    {
      id: 2,
      isActive: false,
      name: 'Placement Oriented Trainings ',
      eventData: 'Lorem'
    },
    {
      id: 3,
      isActive: false,
      name: 'Faculty Development Programs',
      eventData: 'csfsdf'
    },
    {
      id: 4,
      isActive: false,
      name: 'Online Coding Hackathons',
      eventData: 'fsd2d'
    },
    {
      id: 5,
      isActive: false,
      name: 'Pre-Placement Tests Series',
      eventData: '3333333333333333'
    },
    {
      id: 6,
      isActive: false,
      name: 'On campus Events',
      eventData: '44444444444444444'
    },
    {
      id: 7,
      isActive: false,
      name: 'On campus Events',
      eventData: 'y77777777777777'
    },
  ]
  eventData: any[] = [];
  enquiry: Enquiry = new Enquiry();
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
      private notifyService: NotificationService, private fb: FormBuilder, private metaService: Meta, private titleService: Title) {
    // this.openNotificationModel();
  }

    ngOnInit(): void {
    this.updateMetaTags('Campus Training Program', 'Transform your students skill to make them industry ready through well structured on-campus training program designed by AlgoTutor with the help of top industry mentors.');    
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    this.eventData.push({
      eventName: this.eventListArr[0].name,
      eventDescription: this.eventListArr[0].eventData
    });
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
  }
  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }
  getQueQuerryAns(index, isOpened) {

    if (index >= 0 && isOpened == false) {
      this.FNQArr[index].isOpened = true;

    } else {
      this.FNQArr[index].isOpened = false;
    }
  }

  aciveEvent(index: number) {
    this.eventData = [];
    this.eventListArr.forEach((data, indx) => {
      if (index == indx) {
        this.eventListArr[index].isActive = true;
        this.eventData.push({
          eventName: this.eventListArr[index].name,
          eventDescription: this.eventListArr[index].eventData
        });
      } else {
        this.eventListArr[indx].isActive = false;
      }
    })
  }

  requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = 0;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Campus Program' : this.utmSource;

    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => { },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    //this.toClearFormValue(this.contactForm.value);
  }

  toClearFormValue(formValue) {
    formValue.name = '';
  }

  toggleAccordian(event, index, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    for (let i = 0; i < this.FNQArr.length; i++) {
      if (this.FNQArr[i].id == selectedData.id) {
        this.FNQArr[i].isOpened = !this.FNQArr[i].isOpened;
      } else {
        this.FNQArr[i].isOpened = false;
      }
    }

    const panel = element.nextElementSibling;
    if (panel != null) {
      if (panel?.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }

}
