<app-header-style-one></app-header-style-one>
<div class="container">
    <div class="event-banner-img">
        <div class="right-form">
            <h4>Register for free <span class="common-header-color">Master</span> Class!</h4>
            <p>Someone from our <span class="common-header-color">Mentors</span> will connect with you shortly.
            </p>
            <form #contactForm="ngForm" class="contact-form">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required minlength="3" ngModel name="name" type="text" #name="ngModel"
                                class="form-control" id="name" placeholder="Name">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="email" type="text" #email="ngModel" class="form-control"
                                id="email" placeholder="Email">
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="number" #number="ngModel" type="text" class="form-control"
                                id="number" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <input required ngModel name="message" #message="ngModel" type="text" class="form-control"
                                id="message" placeholder="LinkedIn Profile Url (optional)">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="enquery-form-button">
                            <button class="btn" (click)="requestCallBack(contactForm)">Request Now</button>
                        </div>
                    </div>
                </div>
            </form>

        </div>
    </div>
    <div class="all-post">
        <div class="browse-all-post">
            <label for="">Master Class</label>
            <p></p>
        </div>
    
        <div class="event-type">
            <div class="event-type-content">
                <label *ngFor="let data of eventTypeTitle"
                    [ngStyle]="data.active == true ? {'border-bottom' : '2px solid #1C9AD5'} : {}"
                    (click)="getEventCategoryData(data)">{{data.name}}</label>
    
            </div>
        </div>
    
        <div class="event-category-section">
            <div class="row">
                <div class="col-sm-12  col-md-6  col-lg-6 col-xl-4 col-xxl-4" *ngFor="let data of allEventArr" style="margin-bottom: 20px;">
                    <div class="img-section">
                        <img [src]="data.image" alt="img">
                    </div>
                    <div class="event-category-content">
                        <div class="event-title">
                            <p>{{data.eventType}}</p>
                        </div>
                        <div class="event-taken-by">
                            <p><Strong>By: </Strong><span>{{data.takenBY}}</span></p>
                            <a target="_blank" href="{{data.linkedin}}"><img src="../../../../assets/img/icons/lindin.png"
                                    alt=""></a>
                        </div>
                        <hr>
    
                        <div class="event-start-date-vanue">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-6 col-xxl-6">
                                    <div class="start-date">
                                        <b>Event Start Date:</b>
                                        <p>{{data.start_date}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                                    <div class="event-fee">
                                        <b>Event Fee:</b>
                                        <p>Free</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                                    <div class="event-venue">
                                        <b>Venue:</b>
                                        <p>Online</p>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="event-short-description">
                            <p>{{data.Desc}}</p>
                        </div>
                        <!-- <div class="event-highlight-button">
                            <span *ngFor="let btn of data.event_highlight_btn">{{btn}} </span>
                        </div> -->
                        <!-- <hr> -->
                        <div class="button-div">
                            <div class="enroll-btn">
                                <span class="time-remaning">{{data.message}}</span>
                                <!-- <button class="btn commButtonCss" type="button" *ngIf="data.iseventclosed == false"
                                    data-toggle="modal" data-target="#enroll_now_modal_popup"><span>Enroll
                                        Now</span></button> -->
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="enroll_now_modal_popup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h1>Start Better Learning Future From Here</h1>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <button type="button" class="close close-modal-button " data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="form-content">

                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form action="" #contactForm="ngForm">
                                    <div class="form-group">
                                        <input type="text" placeholder="Your name" class="form-control" ngModel
                                            name="name" #name="ngModel" id="name">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" placeholder="Your email address" class="form-control" ngModel
                                            name="email" #email="ngModel" id="email">
                                    </div>
                                    <div class="form-group">
                                        <input type="number" placeholder="Your phone number" class="form-control"
                                            ngModel name="number" #number="ngModel" id="number">
                                    </div>

                                    <div class="form-group">
                                        <select name="" id="" class="text-muted form-control select_list"
                                            (change)="selectCourseId($event.target.value)">
                                            <option value="undefined" selected>Select Program</option>
                                            <option value="1">Advanced Data Science & AI</option>
                                            <option value="2">Mastering DSA & System Design</option>
                                            <option value="3">Mastering DSA & System Design with Full Stack
                                                Specialization</option>
                                            <option value="4">Complete Placement Package for College Students
                                            </option>
                                            <option value="5">Full Stack Web Development - MERN</option>
                                            <option value="6">Tech Interview Preparation & Mock Interviews</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <input type="text" placeholder="Your linkedln profile Url (optional)"
                                            class="form-control" ngModel name="linkDln" #linkDln="ngModel" id="linkDln">
                                    </div>
                                </form>
                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <button class="btn" [disabled]="isButtonDisable === true" (click)="requestCallBack(contactForm)"><span>Apply
                                        Now</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>