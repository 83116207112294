<div class="container">
    <br>
    <br>
    <div class="FAQ-Header">
        <h1>ASK YOUR QUESTIONS</h1>
        <p>Frequently Asked Questions</p>
    </div>

    <div class="module-name-card">
        <div *ngFor="let item of FNQArr;let i = index;">
            <button class="accordion" (click)="toggleAccordian($event, i,item)">
                   {{item.qName}}</button>

            <div class="panel">
                <div class="module-description-main-div">
                    <div class="about-module">
                        <p>{{item.qAns}}</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>