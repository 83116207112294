import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-tech-interview-prepration-mock',
  templateUrl: './tech-interview-prepration-mock.component.html',
  styleUrls: ['./tech-interview-prepration-mock.component.scss']
})
export class TechInterviewPreprationMockComponent implements OnInit {
  course_details = environment.course_date_tech_interview_mock;
  moduleQuestion: any[] = [];
  course_class_mode_text = [
    'Live Interactive Classes',
    'Personalized Mentorship',
    'Unlimited Mock Interviews',
    'Lifetime Content Access',
    'Guaranteed Job Referrals',
    'Unlimited Doubt Support'
  ]
  currentTextIndex: number = 0;
  classFeature: string = this.course_class_mode_text[this.currentTextIndex];
  contactForm: FormGroup;
  enquiry: Enquiry = new Enquiry();
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, private route: ActivatedRoute,
    private notifyService: NotificationService,
      private fb: FormBuilder, private modalService: ModalService
    , private metaService: Meta, private titleService: Title) { }

   ngOnInit(): void {
    this.updateMetaTags('Tech Interview Preparation - Mock Interview', 'Ace your technical interview through practice and personalized feedback. Design your learning path with experienced industry mentors from top product based companies');
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
    setInterval(() => {
      this.currentTextIndex = (this.currentTextIndex + 1) % this.course_class_mode_text.length;
      this.classFeature = this.course_class_mode_text[this.currentTextIndex];
    }, 3000);
  }
  moduleArray = [
    {
      id: 1,
      active: true,
      courseTopic: ' DSA and Problem Solving [10 Interviews]',

      childProperties: [
        {
          id: 1,
          module_description: 'This module consists of interviews based on problem solving using different Data Structures and Algorithms.',
          moduleContant: [
            'Easy and Medium level from Array, LinkedList, Tree, String etc (4 Interviews)',
            'Medium and Hard level from Graph, Trie, Heap, Dynamic programming, BackTracking etc (6 Interviews)',
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 2,
      active: false,
      courseTopic: 'System design [5 Interviews)]',

      childProperties: [
        {
          id: 1,
          module_description: 'This module consists of interviews based on System Design concepts including High level design and Low level design. You will be asked to design robust and scalable systems.',
          moduleContant: [
            'Low level design interviews (2 Interviews)',
            'High level design interviews (3 Interviews)',

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 3,
      active: false,
      courseTopic: 'Behavioral Interview [2 Interviews]',
      childProperties: [
        {
          id: 1,
          module_description: `This module consists of interviews based on behavioral questions which most of the tech companies ask these days like Google, Amazon.`,
          moduleContant: [
            // 'Introduction to Node.js and Express',
            // 'Building RESTful APIs with Express',
            // 'Managing data with MongoDB and Mongoose',
            // 'Authentication and authorization with Passport.js',
            // 'Integrating React and Express',
            // 'Building a full-stack MERN application'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 4,
      active: false,
      courseTopic: 'Combined Interviews [8 Interviews]',
      childProperties: [
        {
          id: 1,
          module_description: `This module will have interviews from all the topics including Problem Solving, System Design and Behavioral. Interviewer can ask you question randomly from the above 3 modules.`,
          moduleContant: [
            // 'Introduction to Node.js and Express',
            // 'Building RESTful APIs with Express',
            // 'Managing data with MongoDB and Mongoose',
            // 'Authentication and authorization with Passport.js',
            // 'Integrating React and Express',
            // 'Building a full-stack MERN application'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
  ]
  getModuleQuestion(event, index, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }
  toggleAccordian(event, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }

    const panel = element.nextElementSibling;
    if (panel.style.maxHeight) {
      panel.style.maxHeight = null;
    } else {
      panel.style.maxHeight = panel.scrollHeight + "px";
    }
  }

  toggleCourseTitle(selectedData, index) {
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
  }
  requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = 6;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Tech Interview Prepration - Course Page' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {
      },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    this.toClearFormValue(this.contactForm.value);
  }

  toClearFormValue(formValue) {
    formValue.name = '';
  }

  downloadBroucher() {
    this.modalService.openModal();
  }
  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }
}
