import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-google-review',
  templateUrl: './google-review.component.html',
  styleUrls: ['./google-review.component.scss']
})
export class GoogleReviewComponent implements OnInit {
  carouselOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    nav: false,
    center: false,
    autoplayTimeout: 5000, /* change to 5000 for normal autoplayTimeout. I set 1s only speed up this exemple */
    autoplaySpeed: 1000,
    navSpeed: 1000,
    autoplay: true,
    items: 2.0,
    // navText: [
    // 	"<i class='bx bx-left-arrow-alt'></i>",
    // 	"<i class='bx bx-right-arrow-alt'></i>"
    // ],
    responsive: {
      0: {
        items: 1
      },
      576: {
        items: 1
      },
      768: {
        items: 2
      },
      1200: {
        items: 3
      }
    }
  };

  googleReviewArr = [
    {
      id: 1,
      starRating: 5,
      name: 'Mukul Rawat',
      reviewData: `'I enrolled in AlgoTutor Academy's program, and I'm extremely satisfied with 
      the results. The instructors go above and beyond to ensure that every student receives 
      personalized attention and guidance. The learning materials and 
      regular practice sessions have significantly improved my algorithmic thinking.'`
    },
    {
      id: 2,
      starRating: 5,
      name: 'Riya Pandit',
      reviewData: `'AlgoTutor Academy has been an incredible learning experience for me. 
      The instructors are knowledgeable, patient, and truly dedicated to helping their students 
      succeed. The curriculum is well-structured, and the interactive teaching methods make 
      complex algorithms easy to understand.'`
    },
    {
      id: 3,
      starRating: 5,
      name: 'Vimal Mandal',
      reviewData: `'AlgoTutor Academy is a fantastic place to learn algorithms! The instructors 
      are knowledgeable and passionate about the subject, and they make complex concepts easy to 
      understand. The interactive approach to teaching ensures that you grasp the material 
      effectively. Highly recommended its my suggestion is best'`
    },
    {
      id: 4,
      starRating: 5,
      name: 'Pravin Kumar',
      reviewData: `'I'm extremely satisfied with my experience at AlgoTutor Academy. The instructors 
      are highly knowledgeable, and their teaching methods are engaging and effective. The academy 
      also provides ample opportunities for hands-on practice and collaboration with fellow students
      , making the learning process enjoyable and interactive.'`
    }, {
      id: 5,
      starRating: 5,
      name: 'Pritam Singh',
      reviewData: `'AlgoTutor Academy has an excellent curriculum that covers a wide range of 
      algorithms and data structures. The lessons are well-explained, and the examples provided 
      are practical and relevant. I appreciate the emphasis on problem-solving strategies, which 
      helped me improve my algorithmic thinking skills.'`
    }, {
      id: 6,
      starRating: 5,
      name: 'GX Sahil',
      reviewData: `'First and foremost, I appreciate the comprehensive approach that AlgoTutor Academy
       takes towards teaching algorithms and data structures. The curriculum is well-designed and 
       provides a solid foundation for students to build upon as they progress through the program.'`
    }, {
      id: 7,
      starRating: 4,
      name: 'Shivam Bisht',
      reviewData: `'Overall, I would highly recommend AlgoTutor Academy to anyone looking to improve
       their knowledge of algorithms and data structures. The academy offers an excellent learning 
       experience, and the skills acquired from their courses will undoubtedly be valuable in any 
       software development role.      '`
    }, {
      id: 8,
      starRating: 4,
      name: 'Priya Mishra',
      reviewData: `'The courses offered by AlgoTutor Academy are comprehensive and cover a wide range
       of programming languages such as Data Structures & System Design and more. The academy also
        provides personalized coaching to ensure that students receive the necessary attention to 
        help them master their programming skills.'`
    }
  ]

  constructor() { }

  ngOnInit(): void {
  }


}
