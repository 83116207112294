import { Injectable } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RestrictRightClickService {

  constructor() { }

  onRightClick(event: MouseEvent): void {
    if (environment.isInspectElementPermission == true) {
      event.preventDefault();
      // this.notification.showWarning("You are not authorize");
    }

  }

  blockF12(): void {
    if (environment.isInspectElementPermission == true) {
      fromEvent(document, 'keydown')
        .pipe(filter((event: KeyboardEvent) => event.key === 'F12'))
        .subscribe((event: KeyboardEvent) => {
          event.preventDefault();
          // this.notification.showWarning("You are not authorize");
        });
    }

  }
}
