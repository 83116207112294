<div class="container">
    <div class="carrier-counsellig-tabs">
        <div class="row">
            <div class="col-lg-8">
                <div class="query-tabs">

                    <h2>Still not sure if AlgoTutor is the right fit for your career?</h2>
                    <p>You’re working hard to take your career to the next level, and you’ve made it far! Get a glimpse
                        of what it's like to learn at AlgoTutor</p>
                    <button class="btn" data-toggle="modal" data-target="#talkToAdviserModal"><span>Talk to our academic
                            advisors</span></button>
                </div>
            </div>
            <div class="col-lg-4 image-area">
                <img src="../../assets/img/mexican-woman-smiling-holding-laptop-pointing-gray-background.png" alt="">
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="talkToAdviserModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="close-btn">
                <button type="button" class="close close-modal-button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h1>Start Better Learning Future From Here</h1>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span>
                                    The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-content">
                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>
                                    <div class="form-group">
                                        <div class="form-group">
                                            <input required type="text" class="form-control"
                                                placeholder="LinkedIn Profile Url (optional)"
                                                formControlName="linkleurl">
                                        </div>
                                    </div>
                                </form>

                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <button class="btn" [disabled]="isButtonDisable === true"
                                    (click)="requestCallBack()"><span>Get in
                                        Touch</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>