
export const environment = {
  production: false,
  //apiUrl: 'http://localhost:37894/api'
  apiUrl: 'https://api.algotutor.org/api',
  //apiUrl: 'https://1tbbljjy6j.execute-api.us-east-2.amazonaws.com/Prod/api',

  getAllCompilerLanguage: 'https://judge0-ce.p.rapidapi.com',
  getTokenForCode: 'https://judge0-ce.p.rapidapi.com/submissions?base64_encoded=true&fields=*',
  finalSubmitForCompile: 'https://judge0-ce.p.rapidapi.com/submissions',
  course_date_mastring_dsa_systen_design: {
    day: '11',
    daysuffix: 'th',
    month: 'Jan',
    batch: 'Morning',
    time: '10:00 AM - 12:00 PM',
    batch_size: '40',
    beforeOffer: '58,500',
    afterOffer: '40,050',
    emi: '9750'
  },
  course_date_mastring_dsa_systen_design_fullstack: {
    day: '11',
    daysuffix: 'th',
    month: 'Jan',
    batch: 'Morning',
    time: '10:00 AM - 12:00 PM',
    batch_size: '40',
    beforeOffer: '68,500',
    afterOffer: '49,050',
    emi: '7611'
  },
  course_date_advanced_data_science_and_AI: {
    day: '11',
    daysuffix: 'th',
      month: 'Jan',
    batch: 'Morning',
    time: '10:00 AM - 12:00 PM',
    batch_size: '40',
    beforeOffer: '58,500',
    afterOffer: '40,050',
    emi: '9750'
  },
  course_date_complate_pacement_college_student: {
    day: '11',
    daysuffix: 'th',
      month: 'Jan',
    batch: 'Morning',
    time: '10:00 AM - 12:00 PM',
    batch_size: '40',
    price: '20,000',
    emi: '3,933'
  },
  course_date_fullstack_web_development_mern: {
    day: '11',
    daysuffix: 'th',
      month: 'Jan',
    batch: 'Morning',
    time: '10:00 AM - 12:00 PM',
    batch_size: '40',
    price: '15,000', 
    emi: '2,500'
  },
  course_date_tech_interview_mock: {
    price: '20,000',
    emi: '3,933'
  },

  notificationCardValue: {
    courseName: 'Mastering DSA & System Design',
    courseTime: '10 AM to 12 PM',
      day: '11th Jan'
  },

  footerCourseList: [
    {
      courseName: 'Data Science & Generative AI',
      city: 'Bangalore'
    },
    {
      courseName: 'DSA & System Design Course',
      city: 'Bangalore'
    },
    {
      courseName: 'DSA & System Design with Full Stack Specialization',
      city: 'Bangalore'
    },
    {
      courseName: 'Data Structure & Algorithms Course',
      city: 'Bangalore'
    },
    {
    courseName: 'Data Structure Course',
    city: 'Bangalore'
    },
    {
        courseName: 'DSA Course',
        city: 'Bangalore'
    },
    {
      courseName: 'System Design Course',
      city: 'Bangalore'
      },
      {
          courseName: 'Interview Preparation',
          city: 'Bangalore'
      },
      {
          courseName: 'MAANG Interview Preparation',
          city: 'Bangalore'
      },
      {
          courseName: 'Mock Interviews',
          city: 'Bangalore'
      },
      {
          courseName: 'Full Stack Developer Course',
          city: 'Bangalore'
      },
      {
          courseName: 'DSA and System Design Course',
          city: 'Bangalore'
      },
      {
          courseName: 'Data Science Course',
          city: 'Bangalore'
      },
      {
          courseName: 'Mock Interviews',
          city: 'Bangalore'
      },
      {
          courseName: 'DSA and System Design Course',
          city: 'Bangalore'
      }
  ],
  course_date_month: 'Jan',
  EncryptKey: 1203199320052021,
  EncryptIV: 1203199320052021,
  encryption: false,
  isInspectElementPermission: true
};
