<app-header-style-one></app-header-style-one>
<div class="courses-main-div">
    <div class="container">
        <div class="courses-content-main-div">
            <div class="course-about">
                <div class="course-title">
                    <h1>Mastering DSA & System Design</h1>
                </div>

                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Solid understanding of data structures and system design</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Connect with mentor and plan your dream company preparation</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Solve 400+ problems through assignments prepared by top working professionals</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Get referrals and switch to your dream company</p>
                    </div>
                </div>
                <div class="information-card">
                    <div class="achived-out-card-image-one">
                        <img src="../../assets/img/icons/div.achieved-outcomes__card-image.png" alt="">
                    </div>
                    <div class="achived-out-card-text-one">
                        <span>147 %</span>
                        <p>Average Salary Hike</p>
                    </div>

                    <div class="info-card-content">
                        <div class="achived-out-card-image-two">
                            <i class="fa-solid fa-graduation-cap"></i>
                        </div>
                        <div class="achived-out-card-text-two">
                            <span>1000+</span>
                            <p>Careers Transformed</p>
                        </div>
                    </div>

                    <div class="achived-out-card-image-four">
                        <img src="../../assets/img/icons/user-withdashboard.png" alt="">
                    </div>
                    <div class="achived-out-card-text-four">
                        <span>Live</span>
                        <p>Interactive Sessions</p>
                    </div>
                </div>
                <div class="course-class-mode">
                    <label for="" class="text-change-animation">{{classFeature}}</label>
                </div>
                <div class="button-area">
                    <button type="button" class="btn course-banner-button" data-toggle="modal"
                        data-target="#ApplyNowModall">
                        <span> Apply Now</span></button>
                    <!-- <a href="../../../assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf"
                        target="_blank"><button type="button" class="btn"><svg xmlns="http://www.w3.org/2000/svg"
                                width="15" height="15" viewBox="0 0 15 15" fill="none">
                                <path
                                    d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                    stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg><span> Download Brochure</span></button>&nbsp;</a> -->
                    <button (click)="downloadBroucher()" type="button" class="btn"><svg
                            xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path
                                d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg><span> Download Brochure</span></button>

                </div>
            </div>

            <div class="banner-img">
                <div class="first-banner">
                    <div class="card top-card-info">
                        <i class="fa-solid fa-check tick-mark"></i>
                        <div class="content">
                            <span>Live interactive classes from industry experts</span>
                        </div>
                    </div>
                    <img src="../../../assets/img/courses/courses_custom/banner1.jpg" alt="img">
                    <div class="card  bottom-card-info">
                        <i class="fa-solid fa-check tick-mark tick-mark2"></i>
                        <div class="content">
                            <span>Prepare for interview with personalized mentorship</span>
                        </div>
                    </div>
                </div>
                <div class="second-banner">
                    <img src="../../../assets/img/courses/courses_custom/banner2.jpg" alt="img">
                    <img src="../../../assets/img/courses/courses_custom/banner3.jpg" alt="img">
                </div>
            </div>

        </div>
    </div>
</div>

<app-how-does-algo-work></app-how-does-algo-work>
<div class="testimonials-area pt-5">
    <div class="container">
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<div class="course-topic-description container">
    <div class="road-map-text">
        <label for="">A Roadmap For Sucess</label>
        <p>Our Comprehensive curriculum, Designed for professionals</p>
    </div>
    <div class="course-description">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <label for="">Course Description</label>
                <p class="module-description">Master the concepts of Data Structures and Algorithms (DSA) along with
                    System
                    Design in this structured course. Learn the core concepts, practice problem-solving, and design
                    scalable systems, guided by industry experts. Elevate your skills and ace technical interviews
                    with confidence.
                </p>

                <div class="module-name-card">
                    <ng-container *ngFor="let item of moduleArray;let i = index;">
                        <div class="course-topic-title" (click)="toggleCourseTitle(item,i)"
                            [ngStyle]="item.active == true? {'border-bottom':'none','background-color':'var(--mainColor)','color':'#fff','border-bottom-right-radius':'0px','border-bottom-left-radius':'0px'}:{}">
                            <div class="row">
                                <div class="col-12 col-md-10 col-lg-10 col-xl-10">
                                    <span>Module {{i+1}} -
                                        {{item.courseTopic}} [{{item.timeTaking}}]</span>
                                </div>
                                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                                    <i [ngStyle]="item.active == false? {'color': '#00000072'}:{}"
                                        *ngIf="item.active == false" class="fa-solid fa-plus"></i>
                                    <i [ngStyle]="item.active == true? {'color': '#fff'}:{}" *ngIf="item.active == true"
                                        class="fa-solid fa-minus"></i>
                                    <img *ngIf="(item.id == 3 || item.id == 4) && (item.active == false)"
                                        src="../../../assets/img/courses/icon/case-study.png" alt="">
                                    <img class="case-studies-white"
                                        *ngIf="(item.id == 3 || item.id == 4) && (item.active == true)"
                                        src="../../../assets/img/courses/icon/white-case-study.png" alt="">
                                    <img class="project-icon-img" *ngIf="item.id == 5  && item.active == false"
                                        src="../../../assets/img/courses/icon/project-icon.png" alt="">
                                    <img class="project-icon-img" *ngIf="item.id == 5 && item.active == true"
                                        src="../../../assets/img/courses/icon/project-icon-in-white.png" alt="">
                                </div>
                            </div>


                        </div>
                        <ng-container *ngIf="item.active == true">
                            <div class="course-details" *ngFor="let child of item.childProperties">
                                <div class="module-description-main-div">
                                    <div class="about-module">
                                        <p>{{child.module_description}}</p>
                                    </div>
                                    <div class="module-key-hold">
                                        <ul *ngFor="let key of child.moduleContant">
                                            <i class="fa-solid fa-circle-check"></i>{{key}}
                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="item.id == 3">
                                    <div class="mentor-content">
                                        <label>Case Studies</label>
                                    </div>

                                    <div class="feedback-slides">
                                        <owl-carousel-o [options]="feedbackSlides">
                                            <ng-template carouselSlide *ngFor="let data of courseCASEstudyHLD">
                                                <div class="review-card">
                                                    <div class="mentor-right-content">
                                                        <label>{{data.title}}</label>
                                                        <p>{{data.text}}</p>
                                                        <div class="mentor-img">
                                                            <img [src]="data.Img" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                </div>
                                <div *ngIf="item.id == 4">
                                    <div class="mentor-content">
                                        <label>Case Studies</label>
                                    </div>

                                    <div class="feedback-slides">
                                        <owl-carousel-o [options]="feedbackSlides">
                                            <ng-template carouselSlide *ngFor="let data of courseCASEstudyLLD">
                                                <div class="review-card">
                                                    <div class="mentor-right-content">
                                                        <label>{{data.title}}</label>
                                                        <p>{{data.text}}</p>
                                                        <div class="mentor-img">
                                                            <img [src]="data.Img" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                    </ng-container>

                </div>
                <div class="download-broucher-div">
                    <!-- <a href="../../../assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf"
                        target="_blank"><button type="button" class="btn"><svg xmlns="http://www.w3.org/2000/svg"
                                width="15" height="15" viewBox="0 0 15 15" fill="none">
                                <path
                                    d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                    stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg><span> Download Brochure</span></button>&nbsp;</a> -->
                    <button (click)="downloadBroucher()" type="button" class="btn"><svg
                            xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path
                                d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg><span> Download Brochure</span></button>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                <app-apply-for-counselling></app-apply-for-counselling>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="courses-highlights_courses_fee_div">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="course-highlight">
                    <div class="course-highlight-content">
                        <h6>Course Highlight</h6>
                        <li><img src="../../../assets/img/courses/icon/telivision.png" alt="logo">80+ Live sessions
                        </li>
                        <li><img src="../../../assets/img/courses/icon/industry-project.png" alt="logo">Industry
                            Projects</li>
                        <li><img src="../../../assets/img/courses/icon/clock.png" alt="logo">Life time accessibility
                        </li>
                        <li><img src="../../../assets/img/courses/icon/laptop.png" alt="logo">Live project experience
                        </li>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div class="course-fee">
                    <div class="courses-fee-content">
                        <strong>Program Fee</strong>
                        <p>We are driven by the idea of program affordability. So, we give you several financial options
                            to manage
                            and budget the expenses of your course.</p>

                        <div class="fee_payment desktop">
                            <div class="payment" style="margin-left: 0px !important;">
                                <div class="fee">
                                    <b>₹ {{course_details.beforeOffer}} <span>(including taxes)</span></b>
                                    <!-- <b><del class="custom-del">₹ {{course_details.beforeOffer}}</del> ₹ {{course_details.afterOffer}}</b> -->
                                    <!-- <div>(+ 18% GST)</div> -->
                                </div>
                                <a target="_blank" href="https://pages.razorpay.com/algotutor-dsa-system-design"><span
                                        class="enroll-btn">Enroll
                                        Now</span></a>
                                <div class="rezor-pay-image"><span>Powered by</span><img
                                        src="../../../assets/img/razorpay-logo-removebg-preview.png" alt=""></div>
                                <p style="color: #2C2C2C;font-size: 16px;">EMI starting from: ₹
                                    {{course_details.emi}}/month</p>
                            </div>
                            <!-- <img class="festival_offer_img"
                                src="../../../assets/img/courses/icon/Blue Yellow Elegant Illustrative Dussehra Sale Announcement Flyer.png"
                                alt=""> -->
                        </div>
                        <div class="fee_payment mobile">
                            <img class="festival_offer_img"
                                src="../../../assets/img/courses/icon/Blue Yellow Elegant Illustrative Dussehra Sale Announcement Flyer.png"
                                alt="">
                            <div class="payment payment-mobile" style="margin-left: 0px !important;">
                                <div class="fee">
                                    <b>₹ {{course_details.beforeOffer}} <span>(including taxes)</span></b>
                                    <!-- <b><del class="custom-del">₹ {{course_details.beforeOffer}}</del> ₹ {{course_details.afterOffer}}</b>
                                    <div>(+ 18% GST)</div> -->
                                </div>
                                <a target="_blank" href="https://pages.razorpay.com/algotutor-dsa-system-design"><span
                                        class="enroll-btn">Enroll
                                        Now</span></a>
                                <div class="rezor-pay-image"><span>Powered by</span><img
                                        src="../../../assets/img/razorpay-logo-removebg-preview.png" alt=""></div>
                                <p style="color: #2C2C2C;font-size: 16px;">EMI starting from: ₹
                                    {{course_details.emi}}/month</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="next-cohort-details">
        <div class="next-cohort-details-title">
            <h2>Next Batch Details</h2>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1"></div>

            <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">

                <div class="next-cohort-details-content">

                    <div class="row enroll-now">
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div>
                                <label for="">{{course_details.day}} <span
                                        class="th-class">{{course_details.daysuffix}}</span> </label>
                                <label class="month-name">{{course_details.month}}</label>
                            </div>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <strong>Weekend Batch [Sat - Sun]</strong>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <button class="btn commButtonCss" data-toggle="modal"
                                data-target="#ApplyNowModall"><span>Apply
                                    Now</span></button>
                        </div>
                    </div>
                    <div class="border-bottomm">
                        <div class="half-circle"></div>
                        <div class="half-circle right-circle"></div>
                    </div>
                    <div class="row batch-details">
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Batch</label>
                            <p>{{course_details.batch}}</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Time</label>
                            <p>{{course_details.time}}</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Batch Size</label>
                            <p>{{course_details.batch_size}} (max)</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Venue</label>
                            <p>Online</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1"></div>
        </div>

    </div>
</div>
<div class="testimonials-areaq">
    <div style="margin-top: -50px;" class="container">
        <app-our-mentor></app-our-mentor>
    </div>
</div>
<div style="padding-bottom: 30px; padding-top: 20px;">
    <app-faq></app-faq>
</div>
<div class="modal fade" id="ApplyNowModall" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="close close-modal-button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h2 style="color:white">Start Better Learning Future From Here</h2>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-content">
                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="email" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                    </div>
                                </form>
                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <button class="btn" [disabled]="isButtonDisable === true"
                                    (click)="requestCallBack()"><span>Apply
                                        Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
