import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-broucher-popup',
  templateUrl: './download-broucher-popup.component.html',
  styleUrls: ['./download-broucher-popup.component.scss']
})
export class DownloadBroucherPopupComponent implements OnInit {
  popupForm: FormGroup;
  submitted: boolean = false;
  nextBatchTime: string = '';
  nextBatchSuffix: string = '';
  nextBatchDay: string = '';
  selectedCandidateProfile: string;
  candidateProfileErrorMessqage: string = '';
  pageURL: string;
  courseId: number;
  utmSource: string;
  constructor(public activeModal: NgbActiveModal, private route: ActivatedRoute,
    private fb: FormBuilder, private router: Router,
    private notifyService: NotificationService,
    private commonservice: CommonServiesService) {
    this.pageURL = this.router.url;

    if (this.pageURL === '/advanced-data-science-and-generative-AI') {
      this.courseId = 1;
      this.nextBatchSuffix = environment.course_date_advanced_data_science_and_AI.daysuffix;
      this.nextBatchDay = environment.course_date_advanced_data_science_and_AI.day;
      this.nextBatchTime = environment.course_date_advanced_data_science_and_AI.month
    } else if (this.pageURL === '/mastering-dsa-system-design') {
      this.courseId = 2;
      this.nextBatchSuffix = environment.course_date_advanced_data_science_and_AI.daysuffix;
      this.nextBatchDay = environment.course_date_mastring_dsa_systen_design.day
      this.nextBatchTime = environment.course_date_mastring_dsa_systen_design.month
    } else if (this.pageURL === '/mastering-dsa-system-design-with-fullstack') {
      this.courseId = 3;
      this.nextBatchSuffix = environment.course_date_mastring_dsa_systen_design_fullstack.daysuffix;
      this.nextBatchDay = environment.course_date_mastring_dsa_systen_design_fullstack.day;
      this.nextBatchTime = environment.course_date_mastring_dsa_systen_design_fullstack.month;
    } else if (this.pageURL === '/fullStack-web-development-mern') {
      this.courseId = 5;
      this.nextBatchSuffix = environment.course_date_fullstack_web_development_mern.daysuffix;
      this.nextBatchDay = environment.course_date_fullstack_web_development_mern.day;
      this.nextBatchTime = environment.course_date_fullstack_web_development_mern.month;
    } else if (this.pageURL === '/complete-placement-package-college-students') {
      this.courseId = 4;
      this.nextBatchSuffix = environment.course_date_complate_pacement_college_student.daysuffix;
      this.nextBatchDay = environment.course_date_complate_pacement_college_student.day;
      this.nextBatchTime = environment.course_date_complate_pacement_college_student.month;
    } else {
      this.nextBatchTime = '';
      this.courseId = 6;
    }
  }


  ngOnInit(): void {
    this.popupForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: ['', [
        Validators.required,
        Validators.pattern("^[0-9]{10}$")
      ]],
      experience: ['', Validators.required],
    });

    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
  }

  get registerFormControl() {
    return this.popupForm.controls;
  }

  onRadioButtonChange(value: string) {
    this.selectedCandidateProfile = value;
    this.candidateProfileErrorMessqage = ''
  }

  sendRequest() {
    this.submitted = true;
    if (this.selectedCandidateProfile === '' || this.selectedCandidateProfile === undefined) {
      this.candidateProfileErrorMessqage = 'Please select any one profile';
      return;
    } else {
      this.candidateProfileErrorMessqage = ''
    }
    if (this.popupForm.valid) {
      const json = {
        "enquiryId": 0,
        "name": this.popupForm.value.name,
        "email": this.popupForm.value.email,
        "mobile": this.popupForm.value.number,
        "enquiryCategory": 'Download Brochure',
        "courseId": this.courseId,
        "linkedInProfile": "",
        "statusId": 1,
        "isReferal": 0,
        "isPotential": 0,
        "enquirySourceId": 1,
        "referalUserId": 0,
        "remarks": "",
        "isActive": 1,
        "ipAddress": "",
        "registeredOn": new Date().toISOString(),
        "lastModifiedOn": new Date().toISOString(),
        "lastModifiedBy": 0,
        "assignedTo": "",
        "errorIfAny": "",
        "sourceGuid": "",
        "yearofExp": this.popupForm.value.experience,
        "candidateProfile": this.selectedCandidateProfile
      }
      let link = document.createElement("a");
      const url = this.pageURL.split('/')[0];
      link.download = url;
      if (this.pageURL === '/advanced-data-science-and-generative-AI') {
        link.href = "assets/img/courses/brochure/Mastering Advance Data Science & Generative AI.pdf";
      } else if (this.pageURL === '/mastering-dsa-system-design') {
        link.href = "assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf";
      } else if (this.pageURL === '/mastering-dsa-system-design-with-fullstack') {
        link.href = "assets/img/courses/brochure/Mastering DSA & System Design - Full Stack- brochure.pdf";
      } else if (this.pageURL === '/fullStack-web-development-mern') {
        link.href = "assets/img/courses/brochure/MERN development Brochure.pdf";
      } else if (this.pageURL === '/complete-placement-package-college-students') {
        link.href = "assets/course-brouchre/Complete Placement Package for Students.pdf";
      } else if (this.pageURL === 'tech-interview-preparation') {
        link.href = "assets/img/courses/brochure/Mock intervierw brochure.pdf";
      } else {
        const url = sessionStorage.getItem("DwnBroucher");
        link.href = url;
      }
      link.click();
      this.activeModal.dismiss('Cross click');

      this.commonservice.getCommonEnqueryForm(json).subscribe(
        res => { },
        err => {
          console.log(err);
          // this.isButtonDisable = false;
          this.notifyService.showError("Something wrong! Please try after sometime.");
        }
      );
    }

  }

}
