import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { RestrictRightClickService } from './services/restrict-right-click.service';
import { Title, Meta } from '@angular/platform-browser';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    title(title: any) {
        throw new Error('Method not implemented.');
    }
    location: any;
    routerSubscription: any;

    constructor(private router: Router, private handleRightAndf12Service: RestrictRightClickService,
    private titleService: Title, private metaService: Meta) {
    }

    ngOnInit() {
        this.recallJsFuntions();
        this.handleRightAndf12Service.blockF12();
        this.updateMetaTags('AlgoTutor Academy: Land at your Dream Tech Company', 'AlgoTutor is an Upskilling Platform that assists students and working professionals in upskilling or reforming their careers..');

    }
    updateMetaTags(title: string, description: string) {
        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: 'description', content: description });
    }
    onRightClick(event) {
        this.handleRightAndf12Service.onRightClick(event);
    }
    recallJsFuntions() {
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                if (this.router.url.startsWith('/user-dashboard')) {
                    this.location = 'user-dashboard'
                } else {
                    this.location = this.router.url;
                }

                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}
