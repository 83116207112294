import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { StickyNavModule } from 'ng2-sticky-nav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderStyleOneComponent } from './components/common/header-style-one/header-style-one.component';
import { BecomeInstructorPartnerComponent } from './components/common/become-instructor-partner/become-instructor-partner.component';
import { HomePageFiveComponent } from './components/pages/home-page-five/home-page-five.component';
import { HomefiveMainBannerComponent } from './components/pages/home-page-five/homefive-main-banner/homefive-main-banner.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { FeedbackStyleTwoComponent } from './components/common/feedback-style-two/feedback-style-two.component';
import { CareerCounsellingComponent } from './career-counselling/career-counselling.component';
import { ContactMentorComponent } from './components/pages/contact-mentor/contact-mentor.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NotificationService } from './services/notification.service';
import { OurMentorComponent } from './components/pages/our-mentor/our-mentor.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { GoogleReviewComponent } from './components/pages/google-review/google-review.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxStarRatingModule } from 'ngx-star-rating';
import { AllCoursesComponent } from './courses/all-courses/all-courses.component';
import { AdvancedDataScienceAiComponent } from './courses/advanced-data-science-ai/advanced-data-science-ai.component';
import { ApplyForCounsellingComponent } from './courses/apply-for-counselling/apply-for-counselling.component';
import { MastringDsaSystemDesignComponent } from './courses/mastring-dsa-system-design/mastring-dsa-system-design.component';
import { MastringDsaSystemDesignWithFullStackComponent } from './courses/mastring-dsa-system-design-with-full-stack/mastring-dsa-system-design-with-full-stack.component';
import { CompletePlacementPakageForCollegeComponent } from './courses/complete-placement-pakage-for-college/complete-placement-pakage-for-college.component';
import { FullStackWebDevelopmenMernComponent } from './courses/full-stack-web-developmen-mern/full-stack-web-developmen-mern.component';
import { TechInterviewPreprationMockComponent } from './courses/tech-interview-prepration-mock/tech-interview-prepration-mock.component';
import { NotesComponent } from './components/pages/notes/notes.component';
import { CampusProgramComponent } from './components/pages/campus-program/campus-program.component';
import { FeaturesBannerTilesComponent } from './components/common/features-banner-tiles/features-banner-tiles.component';
import { HireFromUsComponent } from './components/pages/hire-from-us/hire-from-us.component';
import { HowDoesAlgoWorkComponent } from './how-does-algo-work/how-does-algo-work.component';
import { EventsPageComponent } from './components/pages/events-page/events-page.component';
import { EncryptDecryptAuthInterceptor } from './services/encrypt-decrypt-auth-interceptor.service';
import { RestrictRightClickService } from './services/restrict-right-click.service';
import { InstructorProfileComponent } from './components/pages/instructor-profile/instructor-profile.component';
import { MastringDsaAndSystemDesignComponent } from './courses/mastring-dsa-and-system-design/mastring-dsa-and-system-design.component';
import { LightgalleryModule } from 'lightgallery/angular';
import { CollegeAmbassadorProgramComponent } from './components/pages/college-ambassador-program/college-ambassador-program.component';
import { DownloadBroucherPopupComponent } from './components/common/download-broucher-popup/download-broucher-popup.component';
import { AlumniCallPopupComponent } from './components/common/alumni-call-popup/alumni-call-popup.component';
@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderStyleOneComponent,
    BecomeInstructorPartnerComponent,
    HomePageFiveComponent,
    HomefiveMainBannerComponent,
    ContactPageComponent,
    FaqComponent,
    CareerCounsellingComponent,
    FeedbackStyleTwoComponent,
    ContactMentorComponent,
    OurMentorComponent,
    GoogleReviewComponent,
    AllCoursesComponent, 
    AdvancedDataScienceAiComponent, 
    ApplyForCounsellingComponent, 
    MastringDsaSystemDesignComponent, 
    MastringDsaSystemDesignWithFullStackComponent, 
    CompletePlacementPakageForCollegeComponent, 
    FullStackWebDevelopmenMernComponent, 
    TechInterviewPreprationMockComponent, 
    NotesComponent, CampusProgramComponent, FeaturesBannerTilesComponent, HireFromUsComponent, HowDoesAlgoWorkComponent, EventsPageComponent, InstructorProfileComponent, MastringDsaAndSystemDesignComponent, CollegeAmbassadorProgramComponent, DownloadBroucherPopupComponent, AlumniCallPopupComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxScrollTopModule,
    StickyNavModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    NgbModule, 
    NgxStarRatingModule,
    LightgalleryModule
  ],
  providers: [ToastrService, NotificationService,RestrictRightClickService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptDecryptAuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
