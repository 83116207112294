<app-header-style-one></app-header-style-one>

<div class="courses-main-div">
    <div class="container">
        <div class="courses-content-main-div">

            <div class="course-about">
                <div class="course-title">
                    <h1>Mastering Advance Data Science
                        & Generative AI </h1>
                </div>
                <div class="banner-sup-text">
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Solid understanding over fundamentals of Data Science and AI</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Connect with top professionals from Industry to understand real-life use case</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Solve business case-studies and build real-life projects</p>
                    </div>
                    <div style="display: flex;">
                        <i class="fa-solid fa-circle-check"></i>
                        <p>Get referrals and switch to top data-driven companies</p>
                    </div>
                </div>
                <div class="information-card">
                    <div class="info-card-content">
                        <div class="achived-out-card-image-one">
                            <img src="../../assets/img/icons/div.achieved-outcomes__card-image.png" alt="">
                        </div>
                        <div class="achived-out-card-text-one">
                            <span>147 %</span>
                            <p>Average Salary Hike</p>
                        </div>

                    </div>
                    <div class="info-card-content">
                        <div class="achived-out-card-image-two">
                            <i class="fa-solid fa-graduation-cap"></i>
                        </div>
                        <div class="achived-out-card-text-two">
                            <span>1000+</span>
                            <p>Careers Transformed</p>
                        </div>
                    </div>
                    <div class="info-card-content">
                        <div class="achived-out-card-image-four">
                            <img src="../../assets/img/icons/user-withdashboard.png" alt="">
                        </div>
                        <div class="achived-out-card-text-four">
                            <span>Live</span>
                            <p>Interactive Sessions</p>
                        </div>
                    </div>
                </div>

                <div class="course-class-mode">
                    <label for="" class="text-change-animation">{{classFeature}}</label>
                </div>
                <div class="button-area">
                    <button type="button" class="btn course-banner-button" data-toggle="modal"
                        data-target="#ApplyNowModalForAdvanceDataScienceAndAI">
                        <span> Apply Now</span></button>
                    <!-- <a href="../../../assets/img/courses/brochure/Mastering Advance Data Science & Generative AI.pdf"
                        target="_blank">
                        <button type="button" class="btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                fill="none">
                                <path
                                    d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                    stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                    stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                    stroke-linejoin="round" />
                            </svg>
                            <span> Download Brochure</span>
                        </button>

                    </a> -->


                    <button type="button" class="btn" (click)="downloadBroucher()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path
                                d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                        <span> Download Brochure</span>
                    </button>



                </div>

            </div>

            <div class="banner-img">
                <div class="first-banner">
                    <div class="card top-card-info">
                        <i class="fa-solid fa-check tick-mark"></i>
                        <div class="content">
                            <span>Live interactive classes from industry experts</span>
                        </div>
                    </div>
                    <img src="../../../assets/img/courses/courses_custom/3515462.jpg" alt="img">
                    <div class="card  bottom-card-info">
                        <i class="fa-solid fa-check tick-mark tick-mark2"></i>
                        <div class="content">
                            <span>Prepare for interview with personalized mentorship</span>
                        </div>
                    </div>
                </div>
                <div class="second-banner">
                    <img src="../../../assets/img/courses/courses_custom/teaching-class.jpg" alt="img">
                    <img src="../../../assets/img/courses/courses_custom/mock-banner1.jpg" alt="img">
                </div>
            </div>

        </div>
    </div>
</div>
<app-how-does-algo-work></app-how-does-algo-work>
<div class="testimonials-area pt-5">
    <div class="container">
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>
<!--<div class="container">
    <div class="who-is-program-for-main-area">
        <div class="tittle">
            Who is this program for
        </div>
        <div class="all-card">
            <div class="edu-card  hover-common-card">
                <div class="edu-title">
                    <i class="fa-solid fa-building-columns"></i> Education
                </div>
                <div class="card">
                    <div class="card-body">
                        Bachelor's degree with consistent good academic
                    </div>
                </div>
            </div>

            <div class="work-exp  hover-common-card" >
                <div class="edu-title">
                    <i class="fa-solid fa-bag-shopping"></i> Work Exp.
                </div>
                <div class="card ">
                    <div class="card-body">
                        Minimum 6 Months of IT/Non-IT work experience
                    </div>
                </div>
            </div>
            <div class="career-stage  hover-common-card">
                <div class="edu-title">
                    <i class="fa-solid fa-user-graduate"></i> Career Stage
                </div>
                <div class="card">
                    <div class="card-body">
                        Early to mid-career professionals interested in data science
                    </div>
                </div>
            </div>
            <div class="aspiration  hover-common-card">
                <div class="edu-title">
                    <i class="fa-solid fa-arrow-up"></i> Aspirations
                </div>
                <div class="card">
                    <div class="card-body">
                        Developing skills in data science for future opportunities
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>-->

<div class="course-topic-description container">
    <div class="road-map-text">
        <label for="">A Roadmap For Sucess</label>
        <p>Our Comprehensive curriculum, Designed for professionals</p>
    </div>
    <div class="course-description">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9">
                <label for="">Course Description</label>
                <p class="course_desc_main">Discover the fusion of Data Science and Generative AI in this transformative
                    course. Develop crucial skills for data-driven decision-making and delve into the dynamic world of
                    Generative Artificial Intelligence. Seamlessly integrating theory and hands-on applications, this
                    program ensures a holistic grasp of data science principles and generative models.</p>
                <div class="module-name-card">
                    <ng-container *ngFor="let item of moduleArray;let i = index;">
                        <div class="course-topic-title" (click)="toggleCourseTitle(item,i)"
                            [ngStyle]="item.active == true? {'border-bottom':'none','background-color':'var(--mainColor)','color':'#fff','border-bottom-right-radius':'0px','border-bottom-left-radius':'0px'}:{}">
                            <div class="row">
                                <div class="col-12 col-md-10 col-lg-10 col-xl-10">
                                    <span class="topicname">Module {{i+1}} -
                                        {{item.courseTopic}} <span
                                            *ngIf="item.timeTaking">[{{item.timeTaking}}]</span></span>
                                </div>
                                <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                                    <i [ngStyle]="item.active == false? {'color': '#00000072'}:{}"
                                        *ngIf="item.active == false" class="fa-solid fa-plus"></i>
                                    <i [ngStyle]="item.active == true? {'color': '#fff'}:{}" *ngIf="item.active == true"
                                        class="fa-solid fa-minus"></i>
                                    <!-- <img *ngIf="(item.id == 3 || item.id == 4) && (item.active == false)"
                                        src="../../../assets/img/courses/icon/case-study.png" alt="">
                                    <img class="case-studies-white"
                                        *ngIf="(item.id == 3 || item.id == 4) && (item.active == true)"
                                        src="../../../assets/img/courses/icon/white-case-study.png" alt="">
                                    <img class="project-icon-img" *ngIf="item.id == 5  && item.active == false"
                                        src="../../../assets/img/courses/icon/project-icon.png" alt="">
                                    <img class="project-icon-img" *ngIf="item.id == 5 && item.active == true"
                                        src="../../../assets/img/courses/icon/project-icon-in-white.png" alt=""> -->
                                </div>
                            </div>


                        </div>
                        <ng-container *ngIf="item.active == true">
                            <div class="course-details" *ngFor="let child of item.childProperties">
                                <div class="module-description-main-div">
                                    <div class="about-module">
                                        <p>{{child.module_description}}</p>
                                    </div>
                                    <div class="module-key-hold">
                                        <ul *ngFor="let key of child.moduleContant">
                                            <i class="fa-solid fa-circle-check"></i>{{key}}
                                        </ul>
                                    </div>
                                    <div class="project module-key-hold">
                                        <i class="fa-solid fa-circle-right" *ngIf=" child.project.length"></i><span
                                            *ngIf=" child.project.length" style="font-size: 20px; font-weight: 500;">
                                            Project
                                        </span>
                                        <ul *ngFor="let project of child.project" style=" margin-top: 10px; ">
                                            <li *ngIf="project?.title != ''"
                                                style="list-style: none;font-size: 20px; font-weight: 500;"><i
                                                    class="fa-solid fa-arrow-right"></i> {{project?.title}}</li>
                                            <ul *ngFor="let projectChild of project.pType"
                                                style="list-style: none; margin-top: 10px;"> <i
                                                    class="fa-solid fa-circle-check"></i>{{projectChild}}</ul>
                                        </ul>
                                    </div>
                                </div>
                                <!-- <div *ngIf="item.id == 3">
                                    <div class="mentor-content">
                                        <label>Case Studies</label>
                                    </div>

                                    <div class="feedback-slides">
                                        <owl-carousel-o [options]="feedbackSlides">
                                            <ng-template carouselSlide *ngFor="let data of courseCASEstudyHLD">
                                                <div class="review-card">
                                                    <div class="mentor-right-content">
                                                        <label>{{data.title}}</label>
                                                        <p>{{data.text}}</p>
                                                        <div class="mentor-img">
                                                            <img [src]="data.Img" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                </div> -->
                                <!-- <div *ngIf="item.id == 4">
                                    <div class="mentor-content">
                                        <label>Case Studies</label>
                                    </div>

                                    <div class="feedback-slides">
                                        <owl-carousel-o [options]="feedbackSlides">
                                            <ng-template carouselSlide *ngFor="let data of courseCASEstudyLLD">
                                                <div class="review-card">
                                                    <div class="mentor-right-content">
                                                        <label>{{data.title}}</label>
                                                        <p>{{data.text}}</p>
                                                        <div class="mentor-img">
                                                            <img [src]="data.Img" alt="">
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </owl-carousel-o>
                                    </div>
                                </div> -->
                            </div>
                        </ng-container>

                    </ng-container>

                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                <app-apply-for-counselling></app-apply-for-counselling>
            </div>
        </div>
    </div>
    <div class="download-broucher-div">
        <!-- <a href="../../../assets/img/courses/brochure/Mastering Advance Data Science & Generative AI.pdf"
            target="_blank">
            <button type="button" class="btn"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                    viewBox="0 0 15 15" fill="none">
                    <path
                        d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                        stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                    <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                        stroke-linejoin="round" />
                </svg><span> Download Brochure</span></button>
        </a> -->
        <button (click)="downloadBroucher()" type="button" class="btn"><svg xmlns="http://www.w3.org/2000/svg"
                width="15" height="15" viewBox="0 0 15 15" fill="none">
                <path
                    d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                    stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
                <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round" />
            </svg><span> Download Brochure</span></button>
    </div>
</div>
<!--<div class="container">
    <div class="domain-specilization-main-area">
        <div class="domian-card-area">
            <div class="domain-specialization-padding-area">
                <div class="domain-specialization-left-content">
                    <div class="domin-title">
                        Domain Specialization
                    </div>
                    <div class="domain-paragraph">
                        You can master multiple domains with our hands on Capstone projects and transition your career
                        into these domains.
                    </div>
                  
                    <div class="domain-category-type-card-main">
                        <div class="tagline">
                            Capstone projects from following domains
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-3 col-xl-3 col-xxl-3">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon">
                                            <i class="fa-solid fa-building-columns"></i>
                                        </div>
                                        <div class="text">
                                            BFSI
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-xl-3 col-xxl-3">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon">
                                            <i class="fa-solid fa-arrow-trend-up"></i>
                                        </div>
                                        <div class="text">
                                            Fintech
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 col-xl-3 col-xxl-3">

                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon">
                                            <i class="fa-solid fa-house-chimney-medical"></i>
                                        </div>
                                        <div class="text">
                                            HealthCare
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            <div class="col-12 col-lg-3 col-xl-3 col-xxl-3">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="icon">
                                            <i class="fa-solid fa-truck"></i>
                                        </div>
                                        <div class="text">
                                            SupplyChain
                                        </div>
                                    </div>
                                </div>
                            </div>-->
<!-- <div class="col-12 col-lg-8 col-xl-8 col-xxl-8">

                                <div class="button">
                                    <button class="btn commButtonCss"><span><i class="fa-solid fa-arrow-down"></i>Download Domain
                                            Brochures</span></button>
                                </div>


                            </div> -->
<!--</div>



                    </div>
                </div>
                <div class="domain-specialization-right-content">
                    <img src="../../../assets/img/courses/icon/domainSlider.avif" alt="img">
                </div>
            </div>
        </div>
    </div>
</div>-->
<div class="container">
    <div class="courses-highlights_courses_fee_div">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="course-highlight">
                    <div class="course-highlight-content">
                        <h6>Course Highlight</h6>
                        <li><img src="../../../assets/img/courses/icon/telivision.png" alt="logo">100+ Live sessions
                        </li>
                        <li><img src="../../../assets/img/courses/icon/industry-project.png" alt="logo">10+ Industry
                            Projects</li>
                        <li><img src="../../../assets/img/courses/icon/clock.png" alt="logo">Life time accessibility
                        </li>
                        <li><img src="../../../assets/img/courses/icon/laptop.png" alt="logo">Live project experience
                        </li>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div class="course-fee">
                    <div class="courses-fee-content">
                        <strong>Program Fee</strong>
                        <p>We are driven by the idea of program affordability. So, we give you several financial options
                            to manage
                            and budget the expenses of your course.</p>

                        <div class="fee_payment desktop">
                            <div class="payment" style="margin-left: 0px !important;">
                                <div class="fee">
                                    <b>₹ {{course_details.beforeOffer}} <span>(including taxes)</span></b>
                                    <!-- <b><del class="custom-del">₹ {{course_details.beforeOffer}}</del> ₹ {{course_details.afterOffer}}</b> -->
                                    <!-- <div>(+ 18% GST)</div> -->
                                </div>
                                <a target="_blank" href="https://pages.razorpay.com/pl_NZtYeGVVAo9wUW/view"><span
                                        class="enroll-btn">Enroll
                                        Now</span></a>
                                <div class="rezor-pay-image"><span>Powered by</span><img
                                        src="../../../assets/img/razorpay-logo-removebg-preview.png" alt=""></div>
                                <p style="color: #2C2C2C;font-size: 16px;">EMI starting from: ₹
                                    {{course_details.emi}}/month</p>
                            </div>
                            <!-- <img class="festival_offer_img"
                                src="../../../assets/img/courses/icon/Blue Yellow Elegant Illustrative Dussehra Sale Announcement Flyer.png"
                                alt=""> -->
                        </div>
                        <div class="fee_payment mobile">
                            <img class="festival_offer_img"
                                src="../../../assets/img/courses/icon/Blue Yellow Elegant Illustrative Dussehra Sale Announcement Flyer.png"
                                alt="">
                            <div class="payment payment-mobile" style="margin-left: 0px !important;">
                                <div class="fee">
                                    <b>₹ {{course_details.beforeOffer}} <span>(including taxes)</span></b>
                                    <!-- <b><del class="custom-del">₹ {{course_details.beforeOffer}}</del> ₹ {{course_details.afterOffer}}</b>
                                    <div>(+ 18% GST)</div> -->
                                </div>
                                <a target="_blank" href="https://pages.razorpay.com/pl_NZtYeGVVAo9wUW/view"><span
                                        class="enroll-btn">Enroll
                                        Now</span></a>
                                <div class="rezor-pay-image"><span>Powered by</span><img
                                        src="../../../assets/img/razorpay-logo-removebg-preview.png" alt=""></div>
                                <p style="color: #2C2C2C;font-size: 16px;">EMI starting from: ₹
                                    {{course_details.emi}}/month</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="next-cohort-details">
        <div class="next-cohort-details-title">
            <h2>Next Batch Details</h2>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1"></div>

            <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">

                <div class="next-cohort-details-content">

                    <div class="row enroll-now">
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div>
                                <label for="">{{course_details.day}} <span
                                        class="th-class">{{course_details.daysuffix}}</span> </label>
                                <label class="month-name">{{course_details.month}}</label>
                            </div>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <strong>Weekend Batch [Sat - Sun]</strong>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <button class="btn commButtonCss" data-toggle="modal"
                                data-target="#ApplyNowModall"><span>Apply
                                    Now</span></button>
                        </div>
                    </div>
                    <div class="border-bottomm">
                        <div class="half-circle"></div>
                        <div class="half-circle right-circle"></div>
                    </div>
                    <div class="row batch-details">
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Batch</label>
                            <p>{{course_details.batch}}</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Time</label>
                            <p>{{course_details.time}}</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Batch Size</label>
                            <p>{{course_details.batch_size}} (max)</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Venue</label>
                            <p>Online</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1"></div>
        </div>

    </div>
</div>
<div class="testimonials-areaq">
    <div style="margin-top: -50px;" class="container">
        <app-our-mentor></app-our-mentor>
    </div>
    <!-- <div class="our-mentor-btn">
        <button type="button" class="btn commButtonCss"><span>More Mentor Details</span></button>
    </div> -->
</div>

<div style="padding-bottom: 30px; padding-top: 20px;">
    <app-faq></app-faq>
</div>

<div class="modal fade" id="ApplyNowModalForAdvanceDataScienceAndAI" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-centered" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="close close-modal-button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h2 style="color:white">Start Better Learning Future From Here</h2>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-content">
                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="email" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>

                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                    </div>
                                </form>
                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <button class="btn" [disabled]="isButtonDisable === true"
                                    (click)="requestCallBackEnquiry()"><span>Apply
                                        Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
