import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { NotificationService } from 'src/app/services/notification.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact-mentor',
  templateUrl: './contact-mentor.component.html',
  styleUrls: ['./contact-mentor.component.scss']
})
export class ContactMentorComponent implements OnInit {
  enquiry: Enquiry = new Enquiry();
  enqueryText:string;
  timer: number = 0;
  isButtonDisable: boolean = false;
    constructor(private commonservice: CommonServiesService, private notifyService: NotificationService, private router: Router,
        private metaService: Meta, private titleService: Title) { }

  ngOnInit(): void {
    this.updateMetaTags('Become a Mentor - Help others to Grow', 'Share your knowledge with others and help them to achieve their dream company. Apply for AlgoTutor mentorship program.');
  }
  updateMetaTags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({ name: 'description', content: description });
  }
  submit(form) {

    if (form.name == undefined || form.name == null || form.name == '') {
      this.notifyService.showError("Please give your name");
      return;
    } else if (form.email == undefined || form.email == null || form.email == '') {
      this.notifyService.showError("Please give your email!");
      return;
    } else if (form.number == undefined || form.number == null || form.number == '') {
      this.notifyService.showError("Please give your number!");
      return;
    }
    else if (this.enqueryText == undefined || this.enqueryText == null || this.enqueryText == '') {
      this.notifyService.showError("Please Select Your Type!");
      return;
    }
    else if (form.message == undefined || form.message == null || form.message == '') {
      this.notifyService.showError("Please give your message!");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = form.name;
    this.enquiry.Email = form.email;
    this.enquiry.Mobile = String(form.number);
    this.enquiry.CourseId = 0;
    this.enquiry.LinkedInProfile = this.enqueryText + form.message;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 2;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;  
    this.timer = 10;  
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {},
      err => {
        this.isButtonDisable = false;
        console.log(err);
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    this.router.navigate(['']);

  }
  selectYourType(value){
    if(value == 'mentor'){
      this.enqueryText = 'Mentor Enquiry - '
      this.enquiry.EnquiryCategory = 'Mentor';
    }else if(value == 'instructor'){
      this.enqueryText = 'Instructor Enquiry - '
      this.enquiry.EnquiryCategory = 'Instructor';
    }else{
      this.enqueryText = '';
      this.enquiry.EnquiryCategory = 'Instructor/Mentor';
    }
  }

}
