<div class="container">
    <div class="row">
        <div class="col-lg-6">
            <div class="mentor-content">
                <label for="">Contribute to the Mission</label>
                <h1>Become a Mentor / Instructor</h1>
                <p>Join us in this mission of enabling access to Tech Education, and creating
                    incredible learning experiences. For it is not just about getting a tech job,
                    but fundamentally shifting the way people see themselves and their
                    capabilities.
                    Are you great at tech. and want to teach with us?</p>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="feedback-slides">
                <owl-carousel-o [options]="feedbackSlides">

                    <ng-template carouselSlide *ngFor="let data of reviewArr">

                        <div class="review-card">
                            <div class="mentor-right-content">
                                <img class="hover-img-none" src="../../../../assets/img/double-quote.png" alt="">
                                <img class="hover-img" src="../../../../assets/img/double-quote-white.png" alt="">
                                <p>{{data.reviewedText}}</p>
                                <div class="mentor-img">
                                    <img [src]="data.userImg" alt="">
                                    <label for="">{{data.userName}}</label>

                                </div>
                                <span class="star-icon"><ngb-rating [max]="5" [(rate)]="data.UserRating"
                                        [readonly]="true"></ngb-rating></span>
                                <div class="lindln-icon">
                                    <a href="{{data.linkDin}}" target="_blank">
                                        <img class="hover-img-none" src="../../../../assets/img/lindinLogo.png" alt="">
                                        <img class="hover-img" src="../../../../assets/img/ldn-white.png" alt="">
                                        <span>View Profile</span>
                                    </a>

                                </div>
                            </div>

                        </div>

                    </ng-template>
                </owl-carousel-o>
            </div>

        </div>
    </div>
</div>
<!-- Modal -->
<div class="modal fade" id="ApplyNowModalll" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h2>Empower others with your knowledge and skills!</h2>
                                <p></p>
                            </div>

                        </div>
                        <div class="col-lg-6">
                            <button type="button" class="close close-modal-button" data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="form-content">

                                <h4>Apply For Mentor / Instructor</h4>
                                <p>Fill the below Details to get started</p>
                                <form action="">
                                    <div class="form-group">
                                        <input type="text" placeholder="Your name" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" placeholder="Your email address" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <input type="number" placeholder="Your phone number" class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <input type="text" placeholder="Your linkedln profile Url (optional)"
                                            class="form-control">
                                    </div>
                                    <div class="form-group">
                                        <select name="" id="" class="form-control" style="color: rgba(0, 0, 0, 0.623);">
                                            <option value="" selected style="display: none;">Select Role</option>
                                            <option value="">Mentor</option>
                                            <option value="">Instructor</option>
                                        </select>
                                    </div>
                                </form>

                                <button class="btn"><span>Apply now</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>