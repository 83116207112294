import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Enquiry } from 'src/app/models/enquiry.model';
import { CommonServiesService } from 'src/app/services/common-servies.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-complete-placement-pakage-for-college',
  templateUrl: './complete-placement-pakage-for-college.component.html',
  styleUrls: ['./complete-placement-pakage-for-college.component.scss']
})
export class CompletePlacementPakageForCollegeComponent implements OnInit {
  course_details = environment.course_date_complate_pacement_college_student;
  course_date_month = environment.course_date_month;
  moduleQuestion: any[] = [];
  course_class_mode_text = [
    'Personalized Mentorship',
    'Scheduled Mock Interviews',
    'Feedback Sessions',
    'Company Specefic Preparation',
    'Guaranteed Job Referrals',
    'Unlimited Doubt Support'
  ]
  currentTextIndex: number = 0;
  classFeature: string = this.course_class_mode_text[this.currentTextIndex];
  enquiry: Enquiry = new Enquiry();
  contactForm: FormGroup;
  utmSource: string;
  timer: number = 0;
  isButtonDisable: boolean = false;
  constructor(private commonservice: CommonServiesService, 
    private fb: FormBuilder, private route: ActivatedRoute,
    private notifyService: NotificationService,
    private modalService: ModalService) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: [''],
      email: ['', [
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$")]],
      number: [''],
      linkleurl: ['']
    });
    // Accessing route parameters
    this.route.queryParams.subscribe(params => {
      this.utmSource = params['utmsource'];
    });
    setInterval(() => {
      this.currentTextIndex = (this.currentTextIndex + 1) % this.course_class_mode_text.length;
      this.classFeature = this.course_class_mode_text[this.currentTextIndex];
    }, 3000);
  }
  moduleArray = [
    {
      id: 1,
      active: true,
      courseTopic: 'Foundation Of Programming',
      timeTaking: '2 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Build a solid knowledge base by mastering the fundamentals of programming to ensure you have a strong coding base for technical challenges.',
          moduleContant: [
            'Programming basics',
            'Conditional statements, loops, functions',
            'Maths used in programming',
            'Recursion simplified',
            'Bit manipulation',
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 2,
      active: false,
      courseTopic: 'Data Structures and Algorithms',
      timeTaking: '12 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Dive deep into the world of data structures and algorithms, sharpening your problem-solving skills to tackle complex coding tasks with confidence.',
          moduleContant: [
            'Algorithm time and space complexity analysis',
            'Array and it’s techniques (2 pointer, Negation method etc)',
            'Searching and Sorting',
            'Hashing techniques',
            'Linkedlist',
            'Stack and Queue',
            'Introduction to Tree data-structure',
            'Different types of tree data-structures (Binary tree, BST, N-array tree)',
            'Red-black tree, Avl tree, Segment tree',
            'Graph and graph traversal algorithms',
            'Heap',
            'Trie, TST',
            'Matrix related problems',
            'Greedy programming',
            'Backtracking',
            'Dynamic-programming',

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 3,
      active: false,
      courseTopic: 'Computer Fundamentals',
      timeTaking: '4 week',
      childProperties: [
        {
          id: 1,
          module_description: 'Our Computer Fundamentals module is your gateway to mastering the essentials of computing, with in-depth coverage of Operating Systems, Networks, and Database Management Systems. Learn to navigate the digital landscape with confidence.',
          moduleContant: [
            'Operating system concepts',
            'DBMS',
            'Computer networks'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        }
      ]
    },
    {
      id: 4,
      active: false,
      courseTopic: 'Full Stack Specialization - MERN Stack ',
      timeTaking: '4 week',
      childProperties: [
        {
          id: 1,
          module_description: `In this comprehensive module, you'll dive into the world of MERN (MongoDB, Express.js, React, and Node.js), gaining the skills needed to develop robust and scalable web solutions for today's demanding digital landscape.`,
          moduleContant: [
            'HTML',
            'CSS',
            'Javascript',
            'React JS',
            'Node JS',
            'Building serve with Express JS',
            'MongoDB'
          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
    {
      id: 5,
      active: false,
      courseTopic: 'Additional benefits',
      timeTaking: '4 week',
      childProperties: [
        {
          id: 1,
          module_description: `What additional benefits we are offering with this course.`,
          moduleContant: [
            'Objective test preparation on all the important topics (DS, OS, DBMS, Networking, Aptitude)',
            'Mock Interviews',
            'Projects guidance',
            'Interview Strategy and techniques to answer behavioral questions',
            'Resume building and LinkedIn profile Masterclass',

          ],
          outComes: 'After this module you will be understanding the fundamentals of programming and basics of Data Structures and Time Complexity.'
        },

      ]
    },
  ]

  toggleCourseTitle(selectedData, index) {
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }
  }

  toggleAccordian(event, selectedData) {
    const element = event.target;
    element.classList.toggle("active");
    for (let i = 0; i < this.moduleArray.length; i++) {
      if (this.moduleArray[i].id == selectedData.id) {
        this.moduleArray[i].active = !this.moduleArray[i].active;
      } else {
        this.moduleArray[i].active = false;
      }
    }

    const panel = element.nextElementSibling;
    if (panel != null) {
      if (panel?.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    }
  }
  requestCallBack() {
    if (this.contactForm.value.name === undefined || this.contactForm.value.name === null || this.contactForm.value.name === '') {
      this.notifyService.showError("Name is required");
      return;
    } else if (this.contactForm.value.email === undefined || this.contactForm.value.email === null || this.contactForm.value.email === '') {
      this.notifyService.showError("Email is required");
      return;
    } else if (this.contactForm.controls.email.status == 'INVALID') {
      this.notifyService.showError("Please provide valid email address");
      return;
    } else if (this.contactForm.value.number === undefined || this.contactForm.value.number === null || this.contactForm.value.number === '') {
      this.notifyService.showError("Number is required");
      return;
    }
    this.isButtonDisable = true;
    this.enquiry.EnquiryId = 0;
    this.enquiry.Name = this.contactForm.value.name;
    this.enquiry.Email = this.contactForm.value.email;
    this.enquiry.Mobile = this.contactForm.value.number;
    this.enquiry.CourseId = 4;
    this.enquiry.LinkedInProfile = this.contactForm.value.linkleurl;
    this.enquiry.StatusId = 1;
    this.enquiry.IsReferal = 0;
    this.enquiry.EnquirySourceId = 1;
    this.enquiry.ReferalUserId = 0;
    this.enquiry.Remarks = '';
    this.enquiry.IsActive = 1;
    this.enquiry.EnquiryCategory = this.utmSource === undefined ? 'Complete Placement Package For College Student - Course Page' : this.utmSource;
    this.timer = 10;
    const timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.isButtonDisable = false;
        clearInterval(timerInterval); // Stop the timer when it reaches 0
      }
    }, 1000);
    this.commonservice.getCommonEnqueryForm(this.enquiry).subscribe(
      res => {
      },
      err => {
        console.log(err);
        this.isButtonDisable = false;
        this.notifyService.showError("Something wrong! Please try after sometime.");
      }
    );
    this.notifyService.showSuccess("Enquiry registered successfully! Someone from AlgoTutor will contact you soon. Thanks!");
    //this.toClearFormValue(this.contactForm.value);
  }

  toClearFormValue(formValue) {
    formValue.name = '';
  }
  downloadBroucher() {
    this.modalService.openModal();
  }
}
