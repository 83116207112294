import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EncryptDecryptService } from './encrypt-decrypt-service.service';

@Injectable()
export class EncryptDecryptAuthInterceptor implements HttpInterceptor {
  constructor(private encryptDecryptService: EncryptDecryptService) { }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.method == "POST") {
      if ((req.body || req.body.length > 0) && environment.encryption) {
        const cloneReq = req.clone({
          body: this.encryptDecryptService.encryptUsingAES256(req.body)
        });
        return next.handle(cloneReq);
      }
      let data = req.body as FormData;
      return next.handle(req);
    }
    return next.handle(req);
  }
}