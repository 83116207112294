<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12 col-sm-12">
                <div class="single-footer-widget">

                    <div class="footer-logo">
                        <a href=""><img src="../../../../assets/img/black-logo 2.svg" alt=""></a>
                    </div>
                    <div class="about-algo">
                        <p>AlgoTutor is an Edtech company that assists students in upskilling
                             or reforming their careers. We train you to work for top technology
                              companies and leading startups.</p>
                    </div>

                    <ul class="social-link">
                        <li><a href="https://www.facebook.com/algotutor" class="d-block" target="_blank"><i
                                    class='bx bxl-facebook'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/algotutor" class="d-block" target="_blank"><i
                                    class='bx bxl-linkedin'></i></a></li>

                        <li><a href="https://www.instagram.com/algotutor/" class="d-block" target="_blank"><i class="fa-brands fa-instagram"></i></a></li>
                        <li><a href="https://twitter.com/AlgoTutor" class="d-block" target="_blank"><i
                                    class='bx bxl-twitter'></i></a></li>

                    </ul>

                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12">
                <div class="single-footer-widget">
                    <h3 class="Explore">Explore</h3>
                    <ul class="useful-link">


                        <li class="nav-item"><i class="fa-solid fa-angles-right"></i><a routerLink="/notes"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Notes</a></li>
                        <li class="nav-item"><i class="fa-solid fa-angles-right"></i><a routerLink="/our-event"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Event</a>
                        </li>
                        <li class="nav-item"><i class="fa-solid fa-angles-right"></i><a routerLink="/mentor-instructor"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Become a Mentor</a>
                        </li>
                        <li class="nav-item"><i class="fa-solid fa-angles-right"></i><a routerLink="/contact-us"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact us</a></li>
                        <li class="nav-item"><i class="fa-solid fa-angles-right"></i><a routerLink="/hire-from-us"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Hire From Us</a>
                        </li>
                        <li class="nav-item"><i class="fa-solid fa-angles-right"></i><a routerLink="/campus-program"
                                class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Campus Program</a></li>
                    </ul>

                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12">
                <div class="single-footer-widget">
                    <h3 class="useful-link-header">Useful Link</h3>
                    <ul class="support-link">
                        <li><i class="fa-solid fa-angles-right"></i><a
                                href="../../../../assets/policy_docs/Privacy_policy.pdf" target="_blank">Privacy
                                Policy</a></li>
                        <!-- <li><i class="fa-solid fa-angles-right"></i><a routerLink="/">FAQ's</a></li>
                        <li><i class="fa-solid fa-angles-right"></i><a routerLink="/">Support</a></li> -->
                        <li><i class="fa-solid fa-angles-right"></i><a
                                href="../../../../assets//policy_docs/Terms_of_use.pdf" target="_blank">Terms of Use</a>
                        </li>
                        <li><i class="fa-solid fa-angles-right"></i><a
                                href="../../../../assets/policy_docs/Refund_policy.pdf" target="_blank">Refund
                                Policy</a>
                        </li>
                        <!-- <li><i class="fa-solid fa-angles-right"></i><a routerLink="/">Placement Policy</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-12">
                <div class="single-footer-widget mb-30">
                    <h3 class="contact-info-header">Contact Info</h3>
                    <ul class="contact-us-link">
                        <p style="color: #fff;">AlgoTutor Educational Academy LLP</p> 
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">Shilpitha Splendour, Chinappa
                                Layout, Mahadevapura, Bengaluru, 560048</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+91-7260058093">+91-7260058093</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:info@algotutor.io">info&#64;algotutor.io</a>
                        </li>
                        <!-- <li>
                            <i class='bx bx-map'></i>
                            <a href="#">2C-001 Shilpitha Splendour, Chinappa Layout, Mahadevpura, Bengaluru, 560048</a>
                        </li> -->
                    </ul>
                    <!-- <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="Copyright-info">
            <p style="text-align: center"><a href="https://algotutor.io/" target="_blank">Copyright 2023 AlgoTutor |
                    Developed By AlgoTutor. All Rights Reserved</a></p>
        </div>
        <div class="courses-list">
            <label *ngFor="let data of footerCourseList; let i = index">
                {{data.courseName}}
                <span *ngIf="i !== footerCourseList.length - 1"></span>
            </label>
        </div>
        
    </div>
   


</footer>
<!-- <div class="footer-bottom-area"></div> -->
<ngx-scrolltop></ngx-scrolltop>
