import { Component, OnInit } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

@Component({
    selector: 'app-all-courses',
    templateUrl: './all-courses.component.html',
    styleUrls: ['./all-courses.component.scss']
})
export class AllCoursesComponent implements OnInit {

    courseCategoryTabs = [
        {
            id: 1,
            active: true,
            icon: '<i class="fa-solid fa-graduation-cap"></i>',
            couseTabsName: 'All Courses'
        },
        {
            id: 2,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Data Science'
        },
        {
            id: 3,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Software Development'
        },
        {
            id: 4,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Web Development'
        },
        {
            id: 5,
            active: false,
            icon: '<i class="fa-solid fa-microchip"></i>',
            couseTabsName: 'Interview Preparation'
        }

    ]

    allCourseArr = [
        {
            courseImage: 'assets/img/courses/dsa_and_system_design-min.jpg',
            courseFor: 'Professional',
            courseName: 'Mastering DSA & System Design',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Proficiency in System Design (LLD + HLD)',
                'Ability to convert algorithms to working code',
                'Case studies with implementation'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/mastering-dsa-system-design',
            pdfDwn: '/assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/dsa_and_system_design_fullstack-min.jpg',
            courseFor: 'Professional',
            courseName: 'Mastering DSA & System Design with Full Stack Specialization',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Proficiency in System Design (LLD + HLD)',
                'Ability to convert algorithms to working code',
                ' Web development using MERN Stack'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/mastering-dsa-system-design-with-fullstack',
            pdfDwn: '/assets/img/courses/brochure/Mastering DSA & System Design - Full Stack- brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/data-science-min.jpg',
            courseName: 'Advanced Data Science and  Generative AI Course',
            whatYouWillLearn: ['Solid understanding of fundamentals of AI & ML',
                'Statistical thinking and descriptive analysis',
                'Building your own GPT/LLM model',
                'Business case studies and real-life projects using Generative AI',
                'Advanced concepts of Excel, Tableau & Power BI'
            ],
            courseFor: 'Professional',
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/advanced-data-science-and-generative-AI',
            pdfDwn: '/assets/img/courses/brochure/Mastering Advance Data Science & Generative AI.pdf'
        },
        {
            courseImage: 'assets/img/courses/college_student_program-min.jpg',
            courseFor: 'Student',
            courseName: 'Complete Placement Package for College Students',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Computer fundaments with projects',
                'Ability to convert algorithms to working code',
                'Web development using MERN Stack  with projects'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/complete-placement-package-college-students',
            pdfDwn: '/assets/img/courses/brochure/DSA with Full Stack - package for students.pdf'
        },
        {
            courseImage: 'assets/img/courses/mern_stack-min.jpg',
            courseFor: 'Professional',
            courseName: 'Full Stack Web Development - MERN',
            whatYouWillLearn: ['Basics of web development using HTML, CSS and Javascript',
                'Building web applications using React, Next.js and MongoDB',
                'Backend development using next.js and node',
                'Fundamentals of building API and microservices',
                'Live project development'
            ],
            nextBatchDayMonth: '4 Months',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/fullStack-web-development-mern',
            pdfDwn: '/assets/img/courses/brochure/MERN development Brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/mock_interview-min.jpg',
            courseFor: 'Professional',
            courseName: 'Tech Interview Preparation - Mock Interviews',
            whatYouWillLearn: ['1:1 Mentorship with Mentors from MAANG',
                'Weekly sessions on interview preparation',
                'Mock interview with feedback sessions and guidance',
                'Session on resume review, Naukri and LinkedIn profiles',
                'Referral in your dream company'
            ],
            // nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            // time: '10 AM',
            navigationUrl: '/tech-interview-preparation',
            pdfDwn: '/assets/img/courses/brochure/Mock intervierw brochure.pdf'
        }
    ]
    allCourse = [
        {
            courseImage: 'assets/img/courses/dsa_and_system_design-min.jpg',
            courseFor: 'Professional',
            courseName: 'Mastering DSA & System Design',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Proficiency in System Design (LLD + HLD)', 'Ability to convert algorithms to working code',
                'Case studies with implementation'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/mastering-dsa-system-design',
            pdfDwn: '/assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/dsa_and_system_design_fullstack-min.jpg',
            courseFor: 'Professional',
            courseName: 'Mastering DSA & System Design with Full Stack Specialization',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Proficiency in System Design (LLD + HLD)',
                'Ability to convert algorithms to working code',
                ' Web development using MERN Stack'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/mastering-dsa-system-design-with-fullstack',
            pdfDwn: '/assets/img/courses/brochure/Mastering DSA & System Design - Full Stack- brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/data-science-min.jpg',
            courseName: 'Advanced Data Science and Generative AI Course',
            courseFor: 'Professional',
            whatYouWillLearn: ['Solid understanding of fundamentals of AI & ML',
                'Statistical thinking and descriptive analysis',
                'Building your own GPT/LLM model',
                'Business case studies and real-life projects using Generative AI',
                'Advanced concepts of Excel, Tableau & Power BI'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/advanced-data-science-and-generative-AI',
            pdfDwn: '/assets/img/courses/brochure/Mastering Advance Data Science & Generative AI.pdf'
        },
        {
            courseImage: 'assets/img/courses/college_student_program-min.jpg',
            courseFor: 'Student',
            courseName: 'Complete Placement Package for College Students',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Computer fundaments with projects',
                'Ability to convert algorithms to working code',
                'Web development using MERN Stack  with projects'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/complete-placement-package-college-students',
            pdfDwn: '/assets/img/courses/brochure/DSA with Full Stack - package for students.pdf'
        },
        {
            courseImage: 'assets/img/courses/mern_stack-min.jpg',
            courseFor: 'Professional',
            courseName: 'Full Stack Web Development - MERN',
            whatYouWillLearn: ['Basics of web development using HTML, CSS and Javascript',
                'Building web applications using React, Next.js and MongoDB',
                'Backend development using next.js and node',
                'Fundamentals of building API and microservices',
                'Live project development'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/fullStack-web-development-mern',
            pdfDwn: '/assets/img/courses/brochure/MERN development Brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/mock_interview-min.jpg',
            courseFor: 'Professional',
            courseName: 'Tech Interview Preparation - Mock Interviews',
            whatYouWillLearn: ['1:1 Mentorship with Mentors from MAANG',
                'Weekly sessions on interview preparation',
                'Mock interview with feedback sessions and guidance',
                'Session on resume review, Naukri and LinkedIn profiles',
                'Referral in your dream company'
            ],
            // nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            navigationUrl: '/tech-interview-preparation',
            // time: '10 AM',
            pdfDwn: '/assets/img/courses/brochure/Mock intervierw brochure.pdf'
        }
    ]
    softwareDevelopment = [
        {
            courseImage: 'assets/img/courses/dsa_and_system_design-min.jpg',
            courseFor: 'Professional',
            courseName: 'Mastering DSA & System Design',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Proficiency in System Design (LLD + HLD)',
                'Ability to convert algorithms to working code',
                'Case studies with implementation'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/mastering-dsa-system-design',
            pdfDwn: '/assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf'
        },
        {
            courseImage: 'assets/img/courses/dsa_and_system_design_fullstack-min.jpg',
            courseFor: 'Professional',
            courseName: 'Mastering DSA & System Design with Full Stack Specialization',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Proficiency in System Design (LLD + HLD)',
                ' Web development using MERN Stack',
                'Ability to convert algorithms to working code',
                'Case studies with implementation'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/mastering-dsa-system-design-with-fullstack',
            pdfDwn: '/assets/img/courses/brochure/Mastering DSA & System Design - Full Stack- brochure.pdf'
        },

        {
            courseImage: 'assets/img/courses/college_student_program-min.jpg',
            courseFor: 'Student',
            courseName: 'Complete Placement Package for College Students',
            whatYouWillLearn: ['Complete understanding of basic to advance problem solving',
                'Ability to approach a problem with multiple solution',
                'Computer fundaments with projects',
                'Ability to convert algorithms to working code',
                'Web development using MERN Stack with projects'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/complete-placement-package-college-students',
            pdfDwn: '/assets/img/courses/brochure/DSA with Full Stack - package for students.pdf'
        }

    ]

    dataScienceArr = [
        {
            courseImage: 'assets/img/courses/data-science-min.jpg',
            courseName: 'Advanced Data Science and Generative AI Course',
            whatYouWillLearn: ['Solid understanding of fundamentals of AI & ML',
                'Statistical thinking and descriptive analysis',
                'Building your own GPT/LLM model',
                'Business case studies and real-life projects using Generative AI',
                'Advanced concepts of Excel, Tableau & Power BI'
            ],
            courseFor: 'Professional',
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/advanced-data-science-and-generative-AI',
            pdfDwn: '/assets/img/courses/brochure/Mastering Advance Data Science & Generative AI.pdf'
        }
    ];

    webDevArr = [
        {
            courseImage: 'assets/img/courses/mern_stack-min.jpg',
            courseFor: 'Professional',
            courseName: 'Full Stack Web Development - MERN',
            whatYouWillLearn: ['Basics of web development using HTML, CSS and Javascript',
                'Building web applications using React, Next.js and MongoDB',
                'Backend development using next.js and node',
                'Fundamentals of building API and microservices',
                'Live project development'
            ],
            nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            time: '10 AM',
            navigationUrl: '/advanced-data-science-and-generative-AI',
            pdfDwn: '/assets/img/courses/brochure/MERN development Brochure.pdf'
        }
    ];

    techInterviewPrepArr = [
        {
            courseImage: 'assets/img/courses/mock_interview-min.jpg',
            courseFor: 'Professional',
            courseName: 'Tech Interview Preparation - Mock Interviews',
            whatYouWillLearn: ['1:1 Mentorship with Mentors from MAANG',
                'Weekly sessions on interview preparation',
                'Mock interview with feedback sessions and guidance',
                'Session on resume review, Naukri and LinkedIn profiles',
                'Referral in your dream company'
            ],
            // nextBatchDayMonth: '8th Jun',
            courseProject: '15+ Industry Projects',
            courseFee: 'EMI @INR 5801/month',
            // time: '10 AM',
            navigationUrl: '/tech-interview-preparation',
            pdfDwn: '/assets/img/courses/brochure/Mock intervierw brochure.pdf'
        }
    ];
    constructor(private modalService: ModalService) {

    }

    ngOnInit(): void {

    }
    getCourseByCategory(id: number) {
        this.courseCategoryTabs.forEach((data) => {
            if (data.id == id) {
                data.active = true;
            } else {
                data.active = false;
            }
        })
        switch (id) {
            case 1:
                this.allCourseArr = this.allCourse;
                break;
            case 2:
                this.allCourseArr = this.dataScienceArr;
                break;
            case 3:

                this.allCourseArr = this.softwareDevelopment;
                break;
            case 4:

                this.allCourseArr = this.webDevArr;
                break;
            case 5:

                this.allCourseArr = this.techInterviewPrepArr;
                break;
        }

    }

    downloadBroucher(broucher: string) {
        sessionStorage.setItem("DwnBroucher", broucher);
        this.modalService.openModal();
    }
}
