<header class="header-area p-relative">
    <div class="navbar-area navbar-style-three card" stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/black-logo.png" alt="logo"></a>

                <!-- <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <button class="btn  apply-now-btn" data-toggle="modal" data-target="#ApplyNowModal">Apply
                            Now</button>
                    </ul>
                </div> -->
            </nav>
        </div>
    </div>
</header>

<div class="hero-banner">
    <div class="container">
        <div class="row">
            <div class="col-lg-7 col-md-12 col-sm-12 col-xl-8">
                <div class="hero-banner-content">
                    <div class="course-about">
                        <div class="course-title">
                            <h2 class="sc-iqcoie hSwVOk" style="color: black;">Transform <span
                                    style="background-image:linear-gradient(to right, red , blue);  -webkit-text-fill-color: transparent; background-clip: text;">Learning</span>
                                into <span
                                    style="background-image: linear-gradient(to right, red , blue); -webkit-text-fill-color: transparent; background-clip: text;">Success</span>
                            </h2>
                            <h3><span style="color: rgb(0, 154, 246);"></span> Land at your <span
                                    style="background-image: linear-gradient(to right, red , blue); -webkit-text-fill-color: transparent; background-clip: text;">Dream
                                    Tech </span> company</h3>

                        </div>

                        <div class="image-banner-area">
                            <div class="home-banner-image">
                                <!-- <img class="hover-common-card" src="../../../assets/img/courses/BoysBanner.png" alt=""> -->
                                <img src="../../../assets/img/courses/BoysBanner.png" alt="banner-image">
                            </div>
                        </div>

                        <div class="about-achivement-tags">
                            <div class="information-card">
                                <div class="achived-out-card-text-one">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-arrow-trend-up"></i>
                                    </div>
                                    <span>147 %</span>
                                    <p>Avg. CTC Hike</p>
                                </div>

                                <div class="achived-out-card-text-two">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-graduation-cap"></i>
                                    </div>
                                    <span>1000+</span>
                                    <p>Careers Transformed</p>
                                </div>

                                <div class="achived-out-card-text-three">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-users"></i>
                                    </div>
                                    <span>5000 +</span>
                                    <p>Learners Community</p>
                                </div>
                            </div>
                        </div>
                        <div class="about-achivement-tags-desktop">
                            <div class="information-card">
                                <div class="achived-out-card-text-one">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-arrow-trend-up"></i>
                                    </div>
                                    <span>147 %</span>
                                    <p>Avg. CTC Hike</p>
                                </div>

                                <div class="achived-out-card-text-two">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-graduation-cap"></i>
                                    </div>
                                    <span>1000+</span>
                                    <p>Careers Transformed</p>
                                </div>

                                <div class="achived-out-card-text-three">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-users"></i>
                                    </div>
                                    <span>5000 +</span>
                                    <p>Learners Community</p>
                                </div>

                                <div class="achived-out-card-text-four">
                                    <div class="average-ctc-hike-icon">
                                        <i class="fa-solid fa-chalkboard-user"></i>
                                    </div>
                                    <span>50 +</span>
                                    <p>Experienced Mentors</p>
                                </div>

                            </div>
                        </div>
                        <!-- <div class="course-class-mode">
                            <label for="" class="text-change-animation">{{classFeature}}</label>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12 col-sm-12 col-xl-4">
                <div class="contact-over-image-form">
                    <h4>Request for <span class="common-header-color">Free!</span> Career Counselling</h4>
                    <p>Someone from our <span class="common-header-color">Mentors</span> will connect with you shortly.
                    </p>
                    <form [formGroup]="contactForm" class="contact-form">
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required minlength="3" type="text" class="form-control" placeholder="Name"
                                        formControlName="name">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required type="text" class="form-control" placeholder="Email"
                                        formControlName="email">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required type="text" class="form-control" placeholder="Phone"
                                        formControlName="number">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group courseSelectList">
                                    <select name="" id="" class="form-control"
                                        (change)="selectCourseId($event.target.value)">
                                        <option value="undefined" selected>Select Program</option>
                                        <option value="1">Advanced Data Science & AI</option>
                                        <option value="2">Mastering DSA & System Design</option>
                                        <option value="3" selected>Mastering DSA & System Design with Full Stack
                                            Specialization
                                        </option>
                                        <option value="4">Complete Placement Package for College Students</option>
                                        <option value="5">Full Stack Web Development - MERN</option>
                                        <option value="6">Tech Interview Preparation & Mock Interviews</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group linkDln">
                                    <input required type="text" class="form-control"
                                        placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="try-again-message" *ngIf="timer !== 0">
                                    Please wait for <span>{{timer}}</span> sec to make another request!
                                </div>
                                <div class="enquery-form-button">
                                    <button class="btn button animate-gradient" [disabled]="isButtonDisable === true"
                                        (click)="requestCallBack()">Request
                                        Callback</button>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container">
    <div class="row video-container">
        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
            <div class="motivation-course-banner-image">
                <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                    <a data-lg-size="1280-720" data-src="https://youtu.be/26qvsYbgMZU" lg-video="video/mp4"
                        data-sub-html="<p>Algotutor Video</p>">
                        <img src="assets/img/courses/courses_custom/motivation-course-banner.jpg" alt="image">
                        <a href="https://youtu.be/26qvsYbgMZU" class="video-btn popup-youtube">
                            <i class="bx bx-play"></i>
                        </a>
                    </a>

                </lightgallery>

            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8">

            <div class="course-topic-description">
                <div class="container">
                    <div class="road-map-text">
                        <label>A Roadmap For Sucess</label>
                        <p>Our Comprehensive curriculum, Designed for professionals</p>
                    </div>
                    <div class="course-description">
                        <div class="module-name-card">
                            <ng-container *ngFor="let item of moduleArray;let i = index;">
                                <div class="course-topic-title" (click)="toggleCourseTitle(item,i)"
                                    [ngStyle]="item.active == true? {'border-bottom':'none','background-color':'var(--mainColor)','color':'#fff','border-bottom-right-radius':'0px','border-bottom-left-radius':'0px'}:{}">
                                    <div class="row">
                                        <div class="col-12 col-md-10 col-lg-10 col-xl-10">
                                            <span>Module {{i+1}} -
                                                {{item.courseTopic}} [{{item.timeTaking}}]</span>
                                        </div>
                                        <div class="col-12 col-md-2 col-lg-2 col-xl-2">
                                            <i [ngStyle]="item.active == false? {'color': '#00000072'}:{}"
                                                *ngIf="item.active == false" class="fa-solid fa-plus"></i>
                                            <i [ngStyle]="item.active == true? {'color': '#fff'}:{}"
                                                *ngIf="item.active == true" class="fa-solid fa-minus"></i>
                                            <img *ngIf="(item.id == 3 || item.id == 4) && (item.active == false)"
                                                src="../../../assets/img/courses/icon/case-study.png" alt="">
                                            <img class="case-studies-white"
                                                *ngIf="(item.id == 3 || item.id == 4) && (item.active == true)"
                                                src="../../../assets/img/courses/icon/white-case-study.png" alt="">
                                            <img class="project-icon-img" *ngIf="item.id == 5  && item.active == false"
                                                src="../../../assets/img/courses/icon/project-icon.png" alt="">
                                            <img class="project-icon-img" *ngIf="item.id == 5 && item.active == true"
                                                src="../../../assets/img/courses/icon/project-icon-in-white.png" alt="">
                                        </div>
                                    </div>


                                </div>
                                <ng-container *ngIf="item.active == true">
                                    <div class="course-details" *ngFor="let child of item.childProperties">
                                        <div class="module-description-main-div">
                                            <div class="about-module">
                                                <p>{{child.module_description}}</p>
                                            </div>
                                            <div class="module-key-hold">
                                                <ul *ngFor="let key of child.moduleContant">
                                                    <i class="fa-solid fa-circle-check"></i>{{key}}
                                                </ul>
                                            </div>
                                        </div>
                                        <div *ngIf="item.id == 3">
                                            <div class="mentor-content">
                                                <label>Case Studies</label>
                                            </div>

                                            <div class="feedback-slides">
                                                <owl-carousel-o [options]="feedbackSlides">
                                                    <ng-template carouselSlide *ngFor="let data of courseCASEstudyHLD">
                                                        <div class="review-card">
                                                            <div class="mentor-right-content">
                                                                <label>{{data.title}}</label>
                                                                <p>{{data.text}}</p>
                                                                <div class="mentor-img">
                                                                    <img [src]="data.Img" alt="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>
                                            </div>
                                        </div>
                                        <div *ngIf="item.id == 4">
                                            <div class="mentor-content">
                                                <label>Case Studies</label>
                                            </div>

                                            <div class="feedback-slides">
                                                <owl-carousel-o [options]="feedbackSlides">
                                                    <ng-template carouselSlide *ngFor="let data of courseCASEstudyLLD">
                                                        <div class="review-card">
                                                            <div class="mentor-right-content">
                                                                <label>{{data.title}}</label>
                                                                <p>{{data.text}}</p>
                                                                <div class="mentor-img">
                                                                    <img [src]="data.Img" alt="">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </owl-carousel-o>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                            </ng-container>


                            <div class="download-broucher-div">
                                <!-- <a href="../../../assets/img/courses/brochure/Mastering DSA & System Design Brochure.pdf"
                                    target="_blank"><button type="button" class="btn"><svg
                                            xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                            viewBox="0 0 15 15" fill="none">
                                            <path
                                                d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                                stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                                stroke-linejoin="round" />
                                            <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5"
                                                stroke-linecap="round" stroke-linejoin="round" />
                                        </svg><span> Download Brochure</span></button>&nbsp;</a> -->
                                <button (click)="downloadBroucher()" type="button" class="btn"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15"
                                        fill="none">
                                        <path
                                            d="M13.125 9.375V11.875C13.125 12.2065 12.9933 12.5245 12.7589 12.7589C12.5245 12.9933 12.2065 13.125 11.875 13.125H3.125C2.79348 13.125 2.47554 12.9933 2.24112 12.7589C2.0067 12.5245 1.875 12.2065 1.875 11.875V9.375"
                                            stroke="#000" stroke-width="1.5" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path d="M4.375 6.25L7.5 9.375L10.625 6.25" stroke="#000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M7.5 9.375V1.875" stroke="#000" stroke-width="1.5"
                                            stroke-linecap="round" stroke-linejoin="round" />
                                    </svg><span> Download Brochure</span></button>
                            </div>


                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="how-dose-algo-work">
    <app-how-does-algo-work></app-how-does-algo-work>
</div>


<div class="testimonials-area pt-5">
    <div class="container">
        <app-feedback-style-two></app-feedback-style-two>
    </div>
</div>

<!-- <div class="container">
    <div class="courses-highlights_courses_fee_div">
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                <div class="course-highlight">
                    <div class="course-highlight-content">
                        <h6>Course Highlight</h6>
                        <li><img src="../../../assets/img/courses/icon/telivision.png" alt="logo">80+ Live sessions
                        </li>
                        <li><img src="../../../assets/img/courses/icon/industry-project.png" alt="logo">Industry
                            Projects</li>
                        <li><img src="../../../assets/img/courses/icon/clock.png" alt="logo">Life time accessibility
                        </li>
                        <li><img src="../../../assets/img/courses/icon/laptop.png" alt="logo">Live project experience
                        </li>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-8 col-xl-8 col-xxl-8">
                <div class="course-fee">
                    <div class="courses-fee-content">
                        <strong>Program Fee</strong>
                        <p>We are driven by the idea of program affordability. So, we give you several financial options
                            to manage
                            and budget the expenses of your course.</p>

                        <div class="fee_payment desktop">
                            <div class="payment" style="margin-left: 0px !important;">
                                <div class="fee">
                                    <b>₹ {{course_details.beforeOffer}} <span>(including taxes)</span></b>
                                     <b><del class="custom-del">₹ {{course_details.beforeOffer}}</del> ₹ {{course_details.afterOffer}}</b> 
                                     <div>(+ 18% GST)</div>
                                </div>
                                <a target="_blank" href="https://pages.razorpay.com/algotutor-dsa-system-design"><span
                                        class="enroll-btn">Enroll
                                        Now</span></a>
                                <div class="rezor-pay-image"><span>Powered by</span><img
                                        src="../../../assets/img/razorpay-logo-removebg-preview.png" alt=""></div>
                                <p style="color: #2C2C2C;font-size: 16px;">EMI starting from: ₹
                                    {{course_details.emi}}/month</p>
                            </div>
                             <img class="festival_offer_img"
                                src="../../../assets/img/courses/icon/Blue Yellow Elegant Illustrative Dussehra Sale Announcement Flyer.png"
                                alt="">
                        </div>
                        <div class="fee_payment mobile">
                            <img class="festival_offer_img"
                                src="../../../assets/img/courses/icon/Blue Yellow Elegant Illustrative Dussehra Sale Announcement Flyer.png"
                                alt="">
                            <div class="payment payment-mobile" style="margin-left: 0px !important;">
                                <div class="fee">
                                    <b>₹ {{course_details.beforeOffer}} <span>(including taxes)</span></b>
                                     <b><del class="custom-del">₹ {{course_details.beforeOffer}}</del> ₹ {{course_details.afterOffer}}</b>
                                    <div>(+ 18% GST)</div> 
                                </div>
                                <a target="_blank" href="https://pages.razorpay.com/algotutor-dsa-system-design"><span
                                        class="enroll-btn">Enroll
                                        Now</span></a>
                                <div class="rezor-pay-image"><span>Powered by</span><img
                                        src="../../../assets/img/razorpay-logo-removebg-preview.png" alt=""></div>
                                <p style="color: #2C2C2C;font-size: 16px;">EMI starting from: ₹
                                    {{course_details.emi}}/month</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="next-cohort-details">
        <div class="next-cohort-details-title">
            <h2>Next Batch Details</h2>
        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1"></div>

            <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10">

                <div class="next-cohort-details-content">

                    <div class="row enroll-now">
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <div>
                                <label for="">{{course_details.day}} <span class="th-class">th</span> </label>
                                <label class="month-name">{{course_details.month}}</label>
                            </div>

                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6">
                            <strong>Weekend Batch [Sat - Sun]</strong>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3">
                            <button class="btn commButtonCss" data-toggle="modal"
                                data-target="#ApplyNowModall"><span>Apply
                                    Now</span></button>
                        </div>
                    </div>
                    <div class="border-bottomm">
                        <div class="half-circle"></div>
                        <div class="half-circle right-circle"></div>
                    </div>
                    <div class="row batch-details">
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Batch</label>
                            <p>{{course_details.batch}}</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Time</label>
                            <p>{{course_details.time}}</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Batch Size</label>
                            <p>{{course_details.batch_size}} (max)</p>
                        </div>
                        <div class="col-6 col-lg-3 col-xl-3 col-xxl-3">
                            <label for="">Venue</label>
                            <p>Online</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-1 col-xl-1 col-xxl-1"></div>
        </div>

    </div>
</div> -->
<div class="testimonials-areaq">
    <div style="margin-top: -50px;" class="container">
        <app-our-mentor></app-our-mentor>
    </div>
</div>
<div class="mt-5">
    <app-google-review></app-google-review>
</div>

<div style="padding-bottom: 30px; padding-top: 20px;">
    <app-faq></app-faq>
</div>
<div class="modal fade" id="ApplyNowModall" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">

            <div class="modal-body">
                <button type="button" class="close close-modal-button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h1>Start Better Learning Future From Here</h1>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-content">
                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="email" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                    </div>
                                </form>
                                <button class="btn" (click)="requestCallBack()"><span>Apply
                                        Now</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<!-- Modal -->
<div class="modal fade" id="ApplyNowModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <div class="model-body-content">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="img-area">
                                <h2 style="color:white">Start Better Learning Future From Here</h2>
                                <p>Empower yourself with the knowledge and skills gained through <span
                                        style="color: var(--mainColor);">AlgoTutor!</span> The
                                    key to your future!</p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <button type="button" class="close close-modal-button " data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div class="form-content">

                                <h3>Apply For Counselling</h3>
                                <p>Fill the below Details to get started</p>
                                <form [formGroup]="contactForm">
                                    <div class="form-group">
                                        <input required minlength="3" type="text" class="form-control"
                                            placeholder="Name" formControlName="name">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Email"
                                            formControlName="email">
                                    </div>
                                    <div class="form-group">
                                        <input required type="text" class="form-control" placeholder="Phone"
                                            formControlName="number">
                                    </div>
                                    <div class="form-group">
                                        <select name="" id="" class="text-muted form-control select_list"
                                            (change)="selectCourseId($event.target.value)">
                                            <option value="undefined" selected>Select Program</option>
                                            <option value="1">
                                                Mastering Advance Data Science
                                                & Generative AI
                                            </option>
                                            <option value="2">Mastering DSA & System Design</option>
                                            <option value="3">Mastering DSA & System Design with Full Stack
                                                Specialization</option>
                                            <option value="4">Complete Placement Package for College Students</option>
                                            <option value="5">Full Stack Web Development - MERN</option>
                                            <option value="6">Tech Interview Preparation & Mock Interviews</option>
                                        </select>
                                    </div>

                                    <div class="form-group">
                                        <input required type="text" class="form-control"
                                            placeholder="LinkedIn Profile Url (optional)" formControlName="linkleurl">
                                    </div>
                                </form>

                                <button class="btn" (click)="requestCallBack()"><span>Apply
                                        Now</span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
